import { Box, FormControlLabel, Stack, Switch, TextField } from '@mui/material'
import { InitializationWrapper } from '@planning/components'
import { useWrappedLocalPagination } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { BookingsTable } from './Components/organisms/BookingsTable'
import { BookingsViewStore } from './Stores/BookingsViewStore'

export const BookingsPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const bookingsViewStore = useMemo(() => new BookingsViewStore(), [])
  const [showCompleted, setShowCompleted] = useState(false)

  useEffect(() => {
    bookingsViewStore.fetch()
  }, [bookingsViewStore])

  const filteredData = useMemo(() => {
    if (showCompleted) {
      return bookingsViewStore.filteredData.filter(item => item.isCompleted)
    }
    return bookingsViewStore.filteredData.filter(item => !item.isCompleted)
  }, [bookingsViewStore.filteredData, showCompleted])

  const bookingsListViewStore = useWrappedLocalPagination(
    () => filteredData,
    () => bookingsViewStore.fetch(),
  )

  return (
    <InitializationWrapper isInitializing={!bookingsViewStore.firstLoad}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <Header
          title={t('Bookings', 'Bookings')}
          rightRenderOption={
            <Stack flexDirection='row' alignItems='center' gap={2}>
              <Box width='300px'>
                <TextField
                  label={t(
                    'searchBookingNumber',
                    'Search for booking no., vessel name, or trip ID',
                  )}
                  sx={{ width: '100%', marginTop: 0 }}
                  data-cy='booking-page-search-field'
                  value={bookingsViewStore.filter}
                  onChange={event => {
                    bookingsViewStore.setFilter(event.currentTarget.value)
                  }}
                />
              </Box>
            </Stack>
          }
        />

        <Box sx={{ padding: theme.customSpacing.l }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1rem',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={showCompleted}
                  onChange={event => setShowCompleted(event.target.checked)}
                  color='primary'
                  data-cy='toggle-completed-switch'
                />
              }
              label={t('showCompletedBookings', 'Show completed bookings')}
            />
          </Box>
          <BookingsTable store={bookingsListViewStore} />
        </Box>
      </Box>
    </InitializationWrapper>
  )
})
