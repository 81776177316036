import { observer } from 'mobx-react-lite'
import { FieldStore } from './FieldStore'
import { useRegisterSubFormStore } from './hooks'
import { IFormStore } from './interfaces'

interface IProps<T, U> {
  formStore: IFormStore<T>
  name: keyof T
  children: (store: FieldStore<U>) => JSX.Element
  initialValue: U
  validate?: (value: U) => string | undefined
}

export const FormField = observer(
  <T, U>({ formStore, name, children, initialValue, validate }: IProps<T, U>) => {
    const fieldStore = useRegisterSubFormStore(
      formStore,
      name,
      new FieldStore<U>(initialValue, validate),
    )
    return children(fieldStore)
  },
)
