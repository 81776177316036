import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material'
import { CustomsResponseDto, CustomsStatus, UpdateCustomsStatusCommand } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'

import { CustomsSubForm } from '@planning/components/organisms/CustomsSubForm'
import { ResolveIssuesFormData } from '@planning/pages/Issues/Components/templates/ResolveIssueDialog'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { OrderListViewStore } from '../../stores/OrderListViewStore'

interface Props {
  store: OrderListViewStore
}

export const AddCustomersReferencesDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()
  const customsFormStore = useFormStore<{ customs: CustomsResponseDto[] }>()

  const title = t('addCustomersReferecens', 'Add customers references')

  const { handleSubmit } = useForm<ResolveIssuesFormData>()

  const handleDialogClose = () => {
    store.closeCustomsDialog()
  }

  const onSubmit = async () => {
    if (!customsFormStore.validate()) {
      return
    }

    const cmd: UpdateCustomsStatusCommand = {
      orderIds: [...store.selectedOrderIds.keys()],
      customs: customsFormStore.data.customs,
    }

    try {
      await orderService.upsertCustomsReferences(cmd)

      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))
      handleDialogClose()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <Dialog fullWidth open={store.isCustomsDialogOpen} onClose={handleDialogClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <CustomsSubForm
            name='customs'
            formStore={customsFormStore}
            initialValue={[{ reference: '', status: CustomsStatus.Pending }]}
            showHeader
          />
        </DialogContent>

        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button type='submit' variant='contained' color='primary'>
              {t('save', 'Save')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
})
