import { Tooltip, Typography } from '@mui/material'
import { ContainerResponseDto } from '@planning/app/api'
import { useUnitTypeIcon, useUnitTypeLabel } from '@planning/components/UnitType'
import { useTranslate } from '@tolgee/react'
import { SummaryCard } from './Organisms/SummaryCard'

interface IProps {
  noContainerNumberText?: string
  container?: ContainerResponseDto
}

export const ContainerSummary = ({ container, noContainerNumberText }: IProps) => {
  const { t } = useTranslate()
  const unitTypeIcon = useUnitTypeIcon(container?.unitType)
  const unitTypeLabel = useUnitTypeLabel(container?.unitType)

  if (!container) return <></>

  const attributes = [
    { key: 'isocode', label: t('isoCode', 'ISO Code'), value: container.isoCode },
    { key: 'type', label: t('type', 'Type'), value: container.type },
    { key: 'length', label: t('length', 'Length'), value: `${container.length}ft` },
    { key: 'height', label: t('height', 'Height'), value: container.height },
  ]

  return (
    <SummaryCard
      title={
        <>
          <Tooltip title={unitTypeLabel} placement='bottom'>
            {unitTypeIcon}
          </Tooltip>
          <Typography variant='h4'>
            {container.number ?? noContainerNumberText ?? t('toBeAssigned', 'To be assigned')}
          </Typography>
        </>
      }
      attributes={attributes}
    />
  )
}
