import { TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FormField, IFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'

interface IProps<T, K extends keyof T> {
  name: K
  formStore: IFormStore<T>
  initialValue?: string
}

export const NotesField = observer(
  <T, K extends keyof T>({ name, formStore, initialValue }: IProps<T, K>) => {
    const { t } = useTranslate()

    return (
      <FormField formStore={formStore} name={name} initialValue={initialValue}>
        {store => (
          <TextField
            {...store.props}
            helperText={store.error}
            label={t('notes', 'Notes')}
            multiline
            rows={3}
            variant='outlined'
            fullWidth
          />
        )}
      </FormField>
    )
  },
)
