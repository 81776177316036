import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import { OperationType } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import CraneOperatorItemViewContainer from '@operations/features/craneOperator/containers/CraneOperatorItemViewContainer'
import ItemViewLegendHeaderContainer from '@operations/features/craneOperator/containers/ItemViewLegendHeaderContainer'
import { useMinimalsTheme } from '@tom-ui/ui'

import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'

const CraneOperatorItemView: FC = withAuthenticationRequired(() => {
  const theme = useMinimalsTheme()
  const { craneOperatorUIStore } = useOperationsStore()
  const { vesselVisitId, equipmentId, bay, operationType } = useParams()

  const { t } = useTranslate()

  useEffect(() => {
    const loadLandingPageData = async () => {
      await craneOperatorUIStore.loadVesselGeometry()
    }

    //only load if list view did not load before
    if (
      craneOperatorUIStore.selectedVesselVisitId !== Number(vesselVisitId) ||
      craneOperatorUIStore.selectedEquipmentId !== Number(equipmentId)
    ) {
      craneOperatorUIStore.selectedVesselVisitId = Number(vesselVisitId)
      craneOperatorUIStore.selectedEquipmentId = Number(equipmentId)
      loadLandingPageData()
    }

    craneOperatorUIStore.setItemViewOperationType(operationType as OperationType)
    craneOperatorUIStore.setItemViewCurrentBayNumber(Number(bay))
  }, [bay, craneOperatorUIStore, equipmentId, operationType, vesselVisitId])

  return (
    <>
      <Helmet>
        <title>{t('sts', 'STS')}</title>
      </Helmet>
      <ItemViewLegendHeaderContainer uiStore={craneOperatorUIStore} />
      <Box padding={theme.spacing(2)}>
        <CraneOperatorItemViewContainer uiStore={craneOperatorUIStore} />
      </Box>
    </>
  )
})

export default CraneOperatorItemView
