import { Box, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CancellableTimeoutAlert } from '@planning/pages/GateClerk/Components/CancellableTimeoutAlert'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { InspectRailcarContainerCard } from './InspectRailcarContainerCard'
import { InspectRailcarEmptyMessage } from './InspectRailcarEmptyMessage'

interface IProps {
  railcarOrders: IOrderItem[]
  onReportShortlandedIssue?: (order: IOrderItem) => void
}

interface IPropsList extends IProps {
  store: RailTallyViewStore
}

const InspectRailcarContainersList: FC<IPropsList> = observer(
  ({ railcarOrders, store, onReportShortlandedIssue }) => {
    // [Review] TODO: don't use 'any' type - this might cause future issues. Should be moved to store and tested
    const mergeOrders = (railcarOrders: any[], upsertedOrders: any[]) => {
      const ordersMap = new Map<string, any>()

      railcarOrders.forEach(order => {
        const containerNumber = order.container?.data.number
        if (containerNumber) {
          ordersMap.set(containerNumber, { railcarOrder: order })
        }
      })

      upsertedOrders.forEach(upsertedOrder => {
        const containerNumber = upsertedOrder.containerNumber
        if (containerNumber) {
          const existing = ordersMap.get(containerNumber)
          ordersMap.set(containerNumber, {
            railcarOrder: existing?.railcarOrder,
            upsertedOrder: { ...existing?.railcarOrder, ...upsertedOrder },
          })
        }
      })

      return Array.from(ordersMap.values())
    }

    const mergedOrders = mergeOrders(railcarOrders, store.upsertedOrders)

    return (
      <Grid container spacing='0.5rem' p='1rem'>
        {mergedOrders.map(item => {
          const { railcarOrder, upsertedOrder } = item
          const containerNumber =
            upsertedOrder?.containerNumber || railcarOrder?.container?.data.number

          return (
            <InspectRailcarContainerCard
              key={containerNumber}
              containerNumber={containerNumber}
              isoCode={upsertedOrder?.containerIsoCode || railcarOrder?.container?.data.isoCode}
              type={upsertedOrder?.containerType || railcarOrder?.container?.data.type}
              length={upsertedOrder?.containerLength || railcarOrder?.container?.data.length}
              height={upsertedOrder?.containerHeight || railcarOrder?.container?.data.height}
              isEmpty={upsertedOrder?.isEmpty ?? railcarOrder?.container?.data.isEmpty}
              vgm={upsertedOrder?.vgm || railcarOrder?.vgm}
              grossWeight={
                upsertedOrder?.containerMaxGross || railcarOrder?.container?.data.maxGross
              }
              containerTare={upsertedOrder?.containerTare || railcarOrder?.data.containerTare}
              imoClasses={upsertedOrder?.imoClasses || railcarOrder?.data.imoClasses}
              isDamaged={upsertedOrder?.hasDamage || railcarOrder?.data.isDamaged}
              railcarOrder={railcarOrder}
              upsertedOrder={upsertedOrder}
              onReportShortlandedIssue={onReportShortlandedIssue}
            />
          )
        })}
      </Grid>
    )
  },
)

export const InspectRailcarContainers: FC<IProps> = observer(
  ({ railcarOrders, onReportShortlandedIssue }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { tallymanSelectVesselAndOperationViewStore } = usePlanningStore()
    const store = tallymanSelectVesselAndOperationViewStore.railTallyStore

    if (!railcarOrders.length && !store.upsertedOrders.length) {
      return <InspectRailcarEmptyMessage store={store} />
    }

    return (
      <>
        <InspectRailcarContainersList
          railcarOrders={railcarOrders}
          store={store}
          onReportShortlandedIssue={onReportShortlandedIssue}
        />
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: theme.spacing(2),
          }}
        >
          <CancellableTimeoutAlert
            testId='check-success-alert'
            store={store}
            successText={t(
              'OrderHasBeenSuccessfullyUpdated',
              'Order has been successfully updated',
            )}
            cancelledText={t('OrderUpdateHasBeenCancelled', 'Order update has been cancelled')}
            errorText={t('failedToUpdateOrder', 'Failed to update order')}
            cancellable={store.cancellable}
          />
        </Box>
      </>
    )
  },
)
