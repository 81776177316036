import {
  CoolingOrderDto,
  JobDto,
  WorkInstructionEquipmentDto,
  WorkInstructionJobDto,
  YardBlockEquipmentAssignmentDto,
} from '@operations/app/api'
import { CarrierVisitWorkInstructionUpsertedPayload } from '@operations/app/api/signalRDtos/carrierVisitWorkInstructionUpsertedPayload'
import { EquipmentLastPositionOnYardPayload } from '@operations/app/api/signalRDtos/EquipmentLastPositionOnYardPayload'
import { JobUpsertedUpdatedEventPayload } from '@operations/app/api/signalRDtos/JobUpsertedEventPayload'
import { WagonNumberDto } from '@operations/app/api/signalRDtos/wagonNumberDto'
import { WorkInstructionDeletedEventPayload } from '@operations/app/api/signalRDtos/workInstructionDeletedEventPayload'
import { IEvent } from '@operations/messages/messageBus'
import { EquipmentYardBlock, JobStore } from '@operations/stores/JobStore'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { runInAction } from 'mobx'
import { EquipmentOperatorNotificationUIStore } from '../equipment-operator-notification.ui-store'
import { EquipmentOperatorStepperUIStore } from '../equipment-operator-stepper.ui-store'
import { EquipmentOperatorUIStore } from '../equipment-operator.ui-store'
import { JobSignalRStrategy } from './JobSirgnalStrategy.interface'

export class JobHandler implements JobSignalRStrategy {
  constructor(
    private jobStore: JobStore,
    private appStore: AppStore,
    private equipmentOperatorNotificationUIStore: EquipmentOperatorNotificationUIStore,
    private equipmentOperatorUIStore: EquipmentOperatorUIStore,
    private equipmentOperatorStepperUIStore: EquipmentOperatorStepperUIStore,
  ) {}

  updateCarrierVisitWorkInstruction = (res: IEvent<CarrierVisitWorkInstructionUpsertedPayload>) => {
    if (!res.payload?.carrierVisitId && res.payload?.workInstructionIds) {
      this.jobStore.deleteJobByWorkInstructionIds(res.payload.workInstructionIds)
    }
  }

  refreshVesselVisit = async () => {
    const currentJobWorkInstructionList = this.equipmentOperatorUIStore.jobs.map(x => ({
      workInstructionId: x.workInstructionId,
      isJobActive: !x.isPlanned,
    }))

    await this.refreshJobs()

    const newAndActivatedJobs = this.equipmentOperatorUIStore.jobs.filter(x => {
      const currentJobWorkInstruction = currentJobWorkInstructionList.find(
        w => w.workInstructionId === x.workInstructionId,
      )

      return (!currentJobWorkInstruction || !currentJobWorkInstruction.isJobActive) && !x.isPlanned
    })

    if (newAndActivatedJobs.length) {
      const job = newAndActivatedJobs.length === 1 ? newAndActivatedJobs[0] : undefined
      this.addNotification(
        newAndActivatedJobs.map(x => x.workInstructionId),
        job,
      )
    }

    const deletedJobWorkInstructionIds = currentJobWorkInstructionList.filter(
      x =>
        !this.equipmentOperatorUIStore.jobs
          .map(j => j.workInstructionId)
          .includes(x.workInstructionId),
    )

    if (deletedJobWorkInstructionIds.length) {
      this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId(
        currentJobWorkInstructionList.map(x => x.workInstructionId),
      )
    }
  }

  deleteWorkInstructions = (res: IEvent<WorkInstructionDeletedEventPayload>) => {
    const jobWorkInstructionIds = this.jobStore.jobAndYardBlocksDto.jobs
      .filter(x => res.payload?.ids.includes(x.workInstructionId))
      .map(x => x.workInstructionId)

    if (jobWorkInstructionIds.length) {
      this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId(
        jobWorkInstructionIds,
      )
      this.jobStore.deleteJobByWorkInstructionIds(jobWorkInstructionIds)
    }

    if (
      jobWorkInstructionIds?.find(
        x => x === this.equipmentOperatorStepperUIStore.selectedJob?.workInstructionId,
      )
    ) {
      this.equipmentOperatorStepperUIStore.closeStepper()
    }
  }

  refreshJobs = async () => {
    await this.appStore.triggerReloadBySignalR(this.equipmentOperatorUIStore.loadJobs, '/Operator')
  }

  upsertJob = (res: IEvent<JobUpsertedUpdatedEventPayload>) => {
    if (!this.equipmentOperatorUIStore.selectedEquipmentId) return

    const jobDetailsForEquipment = res.payload?.jobDetailsByEquipment.find(
      x => x.id === this.equipmentOperatorUIStore.selectedEquipmentId,
    )

    if (res.payload?.job && jobDetailsForEquipment) {
      const jobDtoForEquipment: JobDto = {
        ...res.payload.job,
        to: jobDetailsForEquipment?.to,
        from: jobDetailsForEquipment?.from,
        isPlanned: jobDetailsForEquipment.isPlanned,
        warningReason: jobDetailsForEquipment.warningReason,
        linkedOutboundWorkInstruction: jobDetailsForEquipment.linkedOutboundWorkInstruction,
      }

      const jobExistsWithoutUpdate = this.jobStore.jobAndYardBlocksDto.jobs.some(
        x =>
          x.workInstructionId === jobDtoForEquipment.workInstructionId &&
          x.warningReason === jobDtoForEquipment.warningReason,
      )

      if (!jobDtoForEquipment.isPlanned && !jobExistsWithoutUpdate) {
        this.addNotification([res.payload.job.workInstructionId], jobDtoForEquipment)
      }

      this.equipmentOperatorUIStore.showJob(res.payload.job.workInstructionId)

      this.jobStore.updateJobByWorkInstructionId(
        jobDtoForEquipment,
        res.payload.job.workInstructionId,
      )
    } else {
      const job = this.jobStore.jobAndYardBlocksDto.jobs.find(
        x => x.workInstructionId === res.payload?.job.workInstructionId,
      )

      if (job) {
        this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId([
          job.workInstructionId,
        ])
        this.jobStore.deleteJobByWorkInstructionId(job.workInstructionId)
      }
    }

    this.updateYardBlockAssignments(
      res.payload?.yardBlockEquipmentAssignments ?? [],
      !!jobDetailsForEquipment,
    )
  }

  updateWagonWeight = async (res: IEvent<WagonNumberDto>) => {
    if (!this.equipmentOperatorUIStore.selectedEquipmentId || !res.payload) return

    if (!this.equipmentOperatorUIStore.railcarTrackPositions[res.payload.carrierVisitId]) return

    const wagonsByCarrier = [
      ...this.equipmentOperatorUIStore.railcarTrackPositions[res.payload.carrierVisitId],
    ]
    const wagonIndex = wagonsByCarrier.findIndex(x => x.id === res.payload?.railcarTrackPositionId)

    if (wagonIndex >= 0) {
      const wagons = { ...this.equipmentOperatorUIStore.railcarTrackPositions }
      wagonsByCarrier[wagonIndex].totalGrossWeight = res.payload.totalGrossWeight
      wagons[res.payload.carrierVisitId] = wagonsByCarrier

      runInAction(() => {
        this.equipmentOperatorUIStore.railcarTrackPositions = wagons
      })
    }
  }

  finishWorkInstruction = (res: IEvent<number>) => {
    if (!this.equipmentOperatorUIStore.selectedEquipmentId || !res.payload) return

    this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId([
      res.payload,
    ])
    this.jobStore.deleteJobByWorkInstructionId(res.payload)
  }

  upsertWorkInstructionJobs = (res: IEvent<WorkInstructionJobDto[]>) => {
    return
  }

  updateEquipmentPositionOnYard = (res: IEvent<EquipmentLastPositionOnYardPayload>) => {
    const equipmentLastPosition = res.payload

    if (!equipmentLastPosition) return

    const yardBlockAssignments = _(
      this.jobStore.jobAndYardBlocksDto.yardBlockEquipmentAssignments,
    ).cloneDeep()

    const previousBlockIndex = yardBlockAssignments.findIndex(
      x =>
        x.yardBlockName !== equipmentLastPosition.yardBlockName &&
        x.equipments.some(eq => !eq.isPlanned && eq.id === equipmentLastPosition.equipmentId),
    )
    if (previousBlockIndex >= 0) {
      yardBlockAssignments[previousBlockIndex].equipments = yardBlockAssignments[
        previousBlockIndex
      ].equipments.filter(x => x.isPlanned || x.id !== equipmentLastPosition.equipmentId)
    }

    const latestBlockIndex = yardBlockAssignments.findIndex(
      x =>
        x.yardBlockName === equipmentLastPosition.yardBlockName &&
        !x.equipments.some(eq => !eq.isPlanned && eq.id === equipmentLastPosition.equipmentId),
    )

    if (latestBlockIndex >= 0) {
      yardBlockAssignments[latestBlockIndex].equipments = [
        ...yardBlockAssignments[latestBlockIndex].equipments,
        {
          isPlanned: false,
          id: equipmentLastPosition.equipmentId,
          name: equipmentLastPosition.equipmentName,
          equipmentType: equipmentLastPosition.equipmentType,
        },
      ]
    }

    this.jobStore.updateYardBlockAssignments(yardBlockAssignments)
  }

  updateContainerPlugInfo = (res: IEvent<CoolingOrderDto>) => {
    if (!this.equipmentOperatorUIStore.selectedEquipmentId) return

    const jobs = this.jobStore.jobAndYardBlocksDto.jobs.filter(
      x => x.cargoUnit?.id === res.payload?.cargoUnitId,
    )

    if (!jobs) return

    jobs.forEach(x => {
      if (x.cargoUnit) {
        const newContainer = {
          ...x.cargoUnit,
          isPluggedIn: res.payload?.isPluggedIn,
        }
        const newJob = { ...x, container: newContainer }

        this.jobStore.updateJobByWorkInstructionId(newJob, x.workInstructionId)
      }
    })
  }

  private addNotification(jobWorkInstructionIds: number[], job?: JobDto) {
    this.equipmentOperatorNotificationUIStore.addNotification({
      jobWorkInstructionIds: jobWorkInstructionIds,
      containerNumber: job?.cargoUnit?.displayName ?? undefined,
      operationType: job?.operationType,
      destination: job?.to.locationName,
      serviceType: job?.serviceOrder?.type,
      isOptional: job?.isOptional,
      carrierVisit: job?.carrierVisit?.carrierName,
      carrierType: job?.carrierVisit?.type,
      hasMultipleJobs: jobWorkInstructionIds.length > 1,
    })
  }

  private updateYardBlockAssignments(
    newYardBlocksAssignments: YardBlockEquipmentAssignmentDto[],
    isEquipmentWithinTheUpdateList: boolean,
  ) {
    let yardBlockAssignments = _(
      this.jobStore.jobAndYardBlocksDto.yardBlockEquipmentAssignments,
    ).cloneDeep()

    const latestActivePositionForEquipments: EquipmentYardBlock[] = []
    newYardBlocksAssignments.forEach(yarBlockAssignment =>
      yarBlockAssignment.equipments
        .filter(x => !x.isPlanned)
        .forEach(eq =>
          latestActivePositionForEquipments.push({
            equipment: eq,
            yardBlockId: yarBlockAssignment.yardBlockId,
          }),
        ),
    )

    //Remove active equipment positions if the equipment has moved
    latestActivePositionForEquipments.forEach(latestEquipmentPosition => {
      const query = (eq: WorkInstructionEquipmentDto) =>
        !eq.isPlanned && eq.id === latestEquipmentPosition.equipment.id
      const yardBlockIndex = yardBlockAssignments.findIndex(x => x.equipments.some(query))

      const yardBlock = yardBlockAssignments[yardBlockIndex]

      if (yardBlock && yardBlock.yardBlockId !== latestEquipmentPosition.yardBlockId) {
        yardBlockAssignments[yardBlockIndex] = {
          ...yardBlock,
          equipments: yardBlock.equipments.filter(
            eq => eq.isPlanned || eq.id !== latestEquipmentPosition.equipment.id,
          ),
        }
      }
    })

    newYardBlocksAssignments.forEach(newYardBlockAssignment => {
      const yardBlockIndex = yardBlockAssignments.findIndex(
        x => x.yardBlockId === newYardBlockAssignment.yardBlockId,
      )
      //Update the yard block assignment
      if (yardBlockIndex >= 0) {
        newYardBlockAssignment.equipments.forEach(currentEquipment => {
          const equipmentIndex = yardBlockAssignments[yardBlockIndex].equipments.findIndex(
            eq => eq.id === currentEquipment.id && eq.isPlanned === currentEquipment.isPlanned,
          )
          if (equipmentIndex >= 0) {
            yardBlockAssignments[yardBlockIndex].equipments[equipmentIndex] = {
              ...currentEquipment,
            }
          } else {
            yardBlockAssignments[yardBlockIndex].equipments = [
              ...yardBlockAssignments[yardBlockIndex].equipments,
              currentEquipment,
            ]
          }
        })
      }
      //Add new yard block assignment for specific equipments
      else if (isEquipmentWithinTheUpdateList) {
        yardBlockAssignments = [...yardBlockAssignments, newYardBlockAssignment]
      }
    })

    this.jobStore.updateYardBlockAssignments(yardBlockAssignments)
  }
}
