import retry from 'retry'

interface IRetryOptions<E> {
  retryCondition?: (error: E, currentAttemptNumber: number) => boolean
  retryOptions?: retry.OperationOptions
}

const defaultOptions = {
  retries: 2,
  factor: 2,
  minTimeout: 400,
  maxTimeout: 4000,
  randomize: true,
}

export const callWithRetry = <T, E>(call: () => Promise<T>, options?: IRetryOptions<E>) =>
  new Promise<T>((resolve, reject) => {
    const operation = retry.operation(options?.retryOptions || defaultOptions)
    operation.attempt(async currentAttemptNumber => {
      try {
        const result = await call()
        resolve(result)
      } catch (e: Error | any) {
        if (!options?.retryCondition || options.retryCondition(e as E, currentAttemptNumber)) {
          if (!operation.retry(e)) {
            reject(e)
          }
        } else {
          reject(e)
        }
      }
    })
  })
