import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { RailVisitResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { RailcarAutocomplete } from '@planning/components'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import IconTruck from '@planning/components/icon/IconTruck'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import RailTrackAutocomplete from '@planning/pages/Order/components/RailTrackAutocomplete'
import { useTranslate } from '@tolgee/react'
import { IFormStore, useRegisterSubFormStore } from '@tom-ui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import {
  ChangeTransportSubFormStore,
  ChangeTransportSubFormType,
} from './ChangeTransportSubFormStore'

export const RegisteredChangeTransportSubForm = observer(
  <T,>({
    formStore,
    name,
    initialValues,
  }: {
    formStore: IFormStore<T>
    name: keyof T
    initialValues?: Partial<ChangeTransportSubFormType>
  }) => {
    const store = useRegisterSubFormStore(
      formStore,
      name,
      new ChangeTransportSubFormStore(initialValues),
    )

    return <ChangeTransportSubForm store={store} />
  },
)

export const ChangeTransportSubForm = observer(
  ({ store }: { store: ChangeTransportSubFormStore }) => {
    const { t } = useTranslate()
    const { railcarTrackPositionItemStore, railTrackItemStore, tenantStore } = usePlanningStore()

    useEffect(() => {
      railTrackItemStore.fetch()
    }, [railTrackItemStore])

    const fetchRailcarTrackPositions = (visit?: RailVisitResponseDto) => {
      if (visit?.railcarTrackPositionIds?.length) {
        railcarTrackPositionItemStore.fetchByIds(visit.railcarTrackPositionIds)
      }
    }

    const getRailTracksByVisit = (visitId?: number) => {
      const railTracks = railcarTrackPositionItemStore.getRailTracks()
      if (visitId) {
        const railTrackIds = store.selectedRailVisit?.assignedRailTrackIds || []
        return railTracks.filter(x => railTrackIds.includes(x.id))
      }
      return railTracks
    }

    const railTracks = computed(() => getRailTracksByVisit(store.carrierVisitId.value)).get()

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth error={store.carrierType.isTouched && !!store.carrierType.error}>
            <InputLabel id='carrier-select'>{t('carrier', 'Carrier')}</InputLabel>
            <Select
              labelId='carrier-select'
              label={t('carrier', 'Carrier')}
              value={store.carrierType.value}
              onChange={e => store.setCarrierType(e.target.value as string)}
            >
              <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
              <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
              <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
            </Select>
            {store.carrierType.isTouched && !!store.carrierType.error && (
              <Typography color='error'>{t('fieldIsRequired', 'Field is required')}</Typography>
            )}
          </FormControl>
        </Grid>

        {store.carrierType.value === 'Train' && (
          <>
            <Grid item xs={3}>
              <RailVisitAutoComplete
                selectedRailVisit={store.selectedRailVisit}
                error={store.carrierVisitId.isTouched && !!store.carrierVisitId.error}
                handleOnChange={visit => {
                  store.setSelectedRailVisit(visit)
                  fetchRailcarTrackPositions(visit)
                  store.carrierVisitId.setValue(visit?.id ?? 0)
                }}
                cargoType='Container'
              />
            </Grid>

            <Grid item xs={3}>
              <RailTrackAutocomplete
                label='Rail Track'
                hasError={store.railTrackId.isTouched && !!store.railTrackId.error}
                value={store.railTrackId.value}
                options={railTracks}
                onChange={id => store.railTrackId.setValue(id ?? '')}
              />
            </Grid>

            <Grid item xs={3}>
              <RailcarAutocomplete
                hasError={store.railcarId.isTouched && !!store.railcarId.error}
                label={'Railcar no.'}
                value={store.railcarId.value}
                onChange={id => store.railcarId.setValue(id ?? 0)}
              />
            </Grid>
          </>
        )}

        {store.carrierType.value === 'Vessel' && (
          <Grid item xs={5}>
            <VesselVisitAutoComplete
              selectedVesselVisit={store.selectedVesselVisit}
              error={store.carrierVisitId.isTouched && !!store.carrierVisitId.error}
              handleOnChange={visit => {
                store.setSelectedVesselVisit(visit)
                store.carrierVisitId.setValue(visit?.id ?? 0)
              }}
              cargoType='Container'
            />
          </Grid>
        )}

        {store.carrierType.value === 'Truck' && (
          <>
            <Grid item xs={5}>
              <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                <IconTruck zoom={150} />
                <Typography variant='subtitle1' ml='.5rem'>
                  {t('truck', 'Truck')}
                </Typography>
              </Box>
            </Grid>

            {!tenantStore.skipReleaseOrder ? (
              <Grid item xs={5}>
                <Box sx={{ mt: '1rem' }}>
                  <Typography variant='subtitle2' mb={'.75rem'}>
                    {`${t('containerIsReleasedForPickUp', 'Container is released for pick-up')}?`}
                  </Typography>
                  <Chip
                    label={t('yes', 'Yes')}
                    color='primary'
                    sx={{ padding: '0 1rem', mr: '.75rem' }}
                    variant={!store.showDatetimeForPickUp ? 'outlined' : undefined}
                    onClick={() => {
                      store.setShowDatetimeForPickUp(true)
                      store.releaseExpires.setValue(dayjs().toString())
                    }}
                  />
                  <Chip
                    label={t('no', 'No')}
                    color='primary'
                    sx={{ padding: '0 1rem' }}
                    variant={store.showDatetimeForPickUp ? 'outlined' : undefined}
                    onClick={() => {
                      store.setShowDatetimeForPickUp(false)
                      store.releaseExpires.setValue('')
                    }}
                  />
                </Box>

                {store.showDatetimeForPickUp && (
                  <Box sx={{ mb: '1rem', mt: '1rem' }}>
                    <CustomDateTimePicker
                      label={t('releaseExpiresAfter', 'Release expires after')}
                      value={store.releaseExpires.value.toString()}
                      onChange={(value: Dayjs | null) => {
                        store.releaseExpires.setValue((value ?? '').toString())
                      }}
                      popperPlacement={'top-end'}
                    />
                  </Box>
                )}
              </Grid>
            ) : (
              ''
            )}
          </>
        )}
      </Grid>
    )
  },
)
