import { usePlanningStore } from '@planning/AppProvider'
import { validateContainerNumber } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { Loader } from '@tom-ui/ui'
import { useAsyncFetch, useHistory } from '@tom-ui/utils'
import { FC, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { IControlledIsoCodeMappingInputProps } from '../Order/stores/IsoCodeMappingInputStore'
import {
  ContainerMasterDataForm,
  ContainerMasterDataFormData,
} from './components/Organisms/ContainerMasterDataForm'
import { ContainerMasterDataFormPageHeader } from './components/Organisms/ContainerMasterDataFormPageHeader'
import { ContainerMasterDataFormViewStore } from './stores/ContainerMasterDataFormViewStore'

interface Props {
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}

export const ContainerMasterDataFormPage: FC<Props> = ({ renderIsoCodeMappingInput }) => {
  const { t } = useTranslate()
  const params = useParams()
  const history = useHistory()
  const { appViewStore, containerItemStore, isoCodeMappingInputStore } = usePlanningStore()

  isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

  const form = useForm<ContainerMasterDataFormData>({
    defaultValues: {
      containerNumber: '',
      isoCode: '',
      tare: '',
      maxGross: '',
      operator: '',
    },
  })

  const containerMasterDataFormViewStore = useMemo(
    () => new ContainerMasterDataFormViewStore(containerItemStore),
    [containerItemStore],
  )

  const id = params.id ? parseInt(params.id) : undefined
  containerMasterDataFormViewStore.setContainerId(id)

  const { loading } = useAsyncFetch(async () => {
    if (id) await containerItemStore.fetchById(id)
  }, [containerItemStore, id])

  useEffect(() => {
    const data = containerMasterDataFormViewStore.container?.data
    form.setValue('containerNumber', data?.number ?? '')
    form.setValue('isoCode', data?.isoCode ?? '')
    form.setValue('tare', data?.tare ?? '')
    form.setValue('maxGross', data?.maxGross ?? '')
    form.setValue('operator', data?.operator ?? '')
  }, [containerMasterDataFormViewStore.container?.data, form])

  const onSubmit = async (data: ContainerMasterDataFormData) => {
    if (containerMasterDataFormViewStore.showContainerExistingWarning) return
    if (
      !containerMasterDataFormViewStore.boxtechContainerData &&
      !containerMasterDataFormViewStore.boxtechError
    )
      return

    const containerNumber = data.containerNumber

    if (!validateContainerNumber(containerNumber)) {
      form.setError('containerNumber', {
        type: 'manual',
        message: t(
          'invalidContainerNumber',
          'Invalid container number. Must follow format ABCU1234567.',
        ),
      })
      return
    }

    try {
      await containerMasterDataFormViewStore.submit(data)

      const message = containerMasterDataFormViewStore.isEditMode
        ? t('containerUpdated', '{container} updated', { container: data.containerNumber })
        : t('containerCreated', '{container} created', { container: data.containerNumber })

      appViewStore.setShowAlert('success', message)

      history.goBackHistoryOrDefault('/registers/container-master-data')
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSaveData', 'Failed to save data'))
    }
  }

  return (
    <>
      <Loader show={loading || containerMasterDataFormViewStore.isLoading} />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ContainerMasterDataFormPageHeader viewStore={containerMasterDataFormViewStore} />
          <ContainerMasterDataForm viewStore={containerMasterDataFormViewStore} />
        </form>
      </FormProvider>
    </>
  )
}
