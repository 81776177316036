import { CarrierVisitDirection, CustomsResponseDto } from '@planning/app/api'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { sortOrdersByContainerNumber } from '@planning/utils/order-utils'
import { action, computed, makeObservable, observable } from 'mobx'

// [BookingsPageCustomsAssignment] TODO: UT
export class OrderListViewStore {
  filter?: string
  bookingNumber?: string
  visitId?: number
  selectedOrderIds = new Set<number>()
  assignedCustoms: CustomsResponseDto[] = []
  isCustomsDialogOpen = false

  constructor(private orderItemStore: OrderItemStore) {
    makeObservable(this, {
      filter: observable,
      bookingNumber: observable,
      visitId: observable,
      selectedOrderIds: observable,
      assignedCustoms: observable,
      isCustomsDialogOpen: observable,

      orders: computed,
      filteredOrders: computed,
      isEmpty: computed,
      selectAllState: computed,

      setVisitId: action,
      setBookingNumber: action,
      fetch: action,
      setFilter: action,
      selectOrder: action,
      unselectOrder: action,
      openCustomsDialog: action,
      closeCustomsDialog: action,
      setAssignedCustoms: action,
      upsertAssignedCustoms: action,
      setSelectAll: action,
    })
  }

  get filteredOrders() {
    const filteredOrders = this.orders.filter(
      o =>
        !this.filter ||
        o.containerNumber?.includes(this.filter) ||
        o.referenceNumber?.includes(this.filter),
    )

    const sortedOrders = sortOrdersByContainerNumber(filteredOrders)

    return sortedOrders
  }

  get orders() {
    if (!this.bookingNumber || !this.visitId) return []

    const orderItems = this.orderItemStore.ordersByReferenceNumber[this.bookingNumber]

    if (!orderItems) return []

    return orderItems.filter(o => o.data.carrierVisitId === this.visitId).map(o => o.data)
  }

  get isEmpty() {
    return !this.orders.length
  }

  get selectAllState(): boolean | 'indeterminate' {
    if (this.selectedOrderIds.size === this.filteredOrders.length) return true
    if (this.selectedOrderIds.size) return 'indeterminate'

    return false
  }

  get isNoResults() {
    return !this.filteredOrders.length
  }

  setBookingNumber(bookingNumber: string) {
    this.bookingNumber = bookingNumber
  }

  setVisitId(visitId: number) {
    this.visitId = visitId
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  selectOrder(orderId: number) {
    this.selectedOrderIds.add(orderId)
  }

  unselectOrder(orderId: number) {
    this.selectedOrderIds.delete(orderId)
  }

  // TODO: remove if not needed
  upsertAssignedCustoms(customs: CustomsResponseDto) {
    const index = this.assignedCustoms.findIndex(c => c.reference === customs.reference)

    if (index === -1) {
      this.assignedCustoms.push(customs)
    } else {
      this.assignedCustoms[index] = customs
    }
  }

  setSelectAll = (value: boolean) => {
    if (value) {
      this.selectedOrderIds = new Set(this.filteredOrders.map(o => o.id))
    } else {
      this.selectedOrderIds.clear()
    }
  }

  setAssignedCustoms(customs: CustomsResponseDto[]) {
    this.assignedCustoms = customs
  }

  openCustomsDialog() {
    this.isCustomsDialogOpen = true
  }

  closeCustomsDialog() {
    this.isCustomsDialogOpen = false
  }

  fetch = async (bookingNumber: string, visitId: number) => {
    this.setBookingNumber(bookingNumber)
    this.setVisitId(visitId)

    await this.orderItemStore.fetchByReferenceNumber(bookingNumber, CarrierVisitDirection.Outbound)
  }
}
