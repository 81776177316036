import { Alert, Container, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BackButton, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { JobList } from '../JobList/JobList'

interface Props {
  handleSearch: (newValue: string) => void
  handleReturn: () => void
}
export const JobSearchContent = observer(({ handleSearch, handleReturn }: Props) => {
  const { t } = useTranslate()

  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  return (
    <>
      <Header
        leftRenderOption={<BackButton tooltip={t('return', 'Return')} onGoBack={handleReturn} />}
        searchInputType='text'
        onSearchFieldChange={handleSearch}
        enableSearchField={!equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds}
        searchInputLabel={t('searchFor', 'Search for {label}', {
          label: t(
            'aContainerOrRefNumberOrPodOrCarrierIdentifier',
            'a container number / ref. number / POD / Carrier identifier',
          ),
        })}
      />
      <Container sx={{ paddingY: theme => theme.customSpacing.m }}>
        {!equipmentOperatorSearchUIStore.isSearching && (
          <Alert severity='info'>
            <Typography variant='subtitle2'>
              {t(
                'hintTryTheLastFourDigitsOfTheContainerNumber',
                'Hint, try the last 4 digits of the container number',
              )}
            </Typography>
          </Alert>
        )}

        {equipmentOperatorSearchUIStore.isSearching &&
          equipmentOperatorUIStore.searchedAndFilteredJobs.flatMap(x => x.jobs).length === 0 && (
            <Alert severity='warning'>
              <Typography variant='subtitle2'>
                {t('noMatchingResults', 'No matching results')}
              </Typography>
            </Alert>
          )}

        <JobList
          jobGroups={equipmentOperatorUIStore.searchedAndFilteredJobs}
          highlightText={equipmentOperatorSearchUIStore.searchText}
        />
      </Container>
    </>
  )
})
