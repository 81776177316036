import { HoldResponseDto, HoldStatus, HoldType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { NonPrimaryButton } from '@planning/components/atoms/NonPrimaryButton'
import { ItemCard } from '@planning/components/molecules/ItemCard'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IssuesPageLocationState } from '@planning/pages/Issues'
import { useTranslate } from '@tolgee/react'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  hold: HoldResponseDto
}

export const IssueHold: FC<Props> = ({ hold }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { drawerStore } = usePlanningStore()

  const title = useMemo(() => {
    if (hold.type === HoldType.CustomsPending)
      return t('customsClearancePending', 'Customs clearance pending')

    if (hold.type === HoldType.CustomsRefused)
      return t('refusedClearanceByCustoms', 'Refused clearance by customs')

    if (hold.type === HoldType.CustomsInspectionRequired)
      return t('inspectionRequestedByCustoms', 'Inspection requested by customs')

    return t('unknown', 'Unknown')
  }, [hold.type, t])

  const body = useMemo(() => {
    if (hold.type === HoldType.CustomsPending)
      return t(
        'oneOrMoreCustomsReferencesHasNotBeenClearedByCustoms',
        '1 or more customs references has not been cleared by customs',
      )

    if (hold.type === HoldType.CustomsRefused)
      return t(
        'customsHaveRefusedClearanceForThisContainer',
        'Customs have refused clearance for this container',
      )

    if (hold.type === HoldType.CustomsInspectionRequired)
      return t(
        'customsRequiresInspectionOfTheContainerBeforeFurtherHandling',
        'Customs requires inspection of the container before further handling',
      )

    return ''
  }, [hold.type, t])

  const actions =
    hold.issueId && hold.status === HoldStatus.Active
      ? [
          <NonPrimaryButton
            key={'resolveIssue'}
            text={t('resolveIssue', 'Resolve issue')}
            onClick={() => {
              drawerStore.close()
              navigate(`${PATH_PLANNING.issues}`, {
                state: {
                  id: hold.issueId,
                  domain: 'Planning',
                } as IssuesPageLocationState,
              })
            }}
          />,
        ]
      : []

  return <ItemCard title={title} body={body} actions={actions} />
}
