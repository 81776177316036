import { EquipmentPlanningSummary, useOperationsStore } from '@tom-ui/operations'
import { RailVisitDetailsPage } from '@tom-ui/planning'
import {
  GeneralCargoDamage,
  generalCargoDamageService,
  OutboundRequestValidationControl,
} from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { IssueStatus } from 'modules/operations/src/app/api'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { OrderDamageDto } from '../GeneralCargoOrders/DamageOrderDto'
import { renderIsoCodeMappingInput } from '../IsoCodeMapping/IsoCodeMappingWrapper'

export const RailVisitDetails = observer(() => {
  const { id } = useParams()

  const railVisitId = Number(id)

  const { equipmentsVesselVisitUIStore, issueStore } = useOperationsStore()
  useEffect(() => {
    if (railVisitId) equipmentsVesselVisitUIStore.getPlannedEquipments(railVisitId)
  }, [equipmentsVesselVisitUIStore, railVisitId])

  const renderDamage = (orderId: number) => <GeneralCargoDamage orderId={orderId} />

  const validateOutboundRequest = (params: OutboundOrderValidationDto): React.ReactElement => (
    <OutboundRequestValidationControl
      isOutbound={params.isOutbound}
      customerId={params.customerId}
      commodityId={params.commodityId}
      lotNumber={params.lotNumber}
      packageId={params.packageId}
      quantity={params.quantity}
      unitIds={params.unitIds}
      imoClasses={params.imoClasses}
    />
  )

  const handleGetOrdersDamageAmount = useCallback(async (orderIds: number[]) => {
    const damages = await generalCargoDamageService.getOrderDamageAmount(orderIds)

    return damages.map(
      x =>
        ({
          orderId: x.orderId,
          amount: x.totalQuantity,
        }) as OrderDamageDto,
    )
  }, [])

  return (
    <RailVisitDetailsPage
      renderDamage={renderDamage}
      renderIsoCodeMappingInput={renderIsoCodeMappingInput}
      getOrderDamagesAmountFunc={handleGetOrdersDamageAmount}
      validateOutboundRequest={validateOutboundRequest}
      equipmentPlanningSummary={
        <EquipmentPlanningSummary navigateToUrl={`/EquipmentPlanningRail/${railVisitId}`} />
      }
      unresolvedOperationsIssueCount={
        issueStore.items.filter(
          i => i.carrierVisitIds.includes(railVisitId) && i.issueStatus === IssueStatus.Unresolved,
        ).length
      }
    />
  )
})
