import { Button, Tooltip } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { ContainerDamageRecordStep } from '@planning/components'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { IconButton, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { IInspectContainerFormData } from '../InspectContainer/InspectContainerForm'
import { CreateRailcarContainer } from './CreateRailcarContainer'

interface IProps {
  store: RailTallyViewStore
  orders: IOrderItem[]
  isIcon?: boolean
}
export const CreateRailcarOrderButton = observer(({ store, orders, isIcon }: IProps) => {
  const { t } = useTranslate()
  const navigationStore = useNavigationStore()

  const handleReportDamage = (
    orderData: IInspectContainerFormData,
    onConfirm: (orderData: IInspectContainerFormData) => void,
  ) => {
    navigationStore.push(
      <ContainerDamageRecordStep
        orderData={orderData}
        containerNumber={orderData?.containerNumber}
        onSubmit={(data, damages) => {
          data.damagesReported = damages
          navigationStore.pop()

          onConfirm(data)
        }}
      />,
    )
  }

  const handleClick = () => {
    navigationStore.push(
      <CreateRailcarContainer
        orders={orders}
        onSubmit={data => {
          const handleSubmit = (orderData: IInspectContainerFormData) => {
            store.upsertOrders([orderData])
            store.setCurrentStep(1)
            navigationStore.pop()
          }

          if (data.hasDamage) {
            handleReportDamage(data, handleSubmit)
          } else {
            handleSubmit(data)
          }
        }}
        // [Review] TODO: Why always inbound?
        direction={CarrierVisitDirection.Inbound}
        submitButtonText={t('done', 'Done')}
      />,
    )
  }

  return isIcon ? (
    <Tooltip title={t('addContainer', 'Add container')}>
      <IconButton onClick={handleClick}>
        <PlusIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Button color='secondary' onClick={handleClick} startIcon={<PlusIcon />}>
      {t('addContainer', 'Add container')}
    </Button>
  )
})
