import { Grid, TextField } from '@mui/material'
import { PinInfo } from '@planning/rt-stores/railTrack/RailcarTrackPositionItem'

import {
  AssignRailcarTrackPositionCommand,
  CheckType,
  RailcarTrackPositionResponseDto,
  RailTrackResponseDto,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import RailTrackControlledAutocomplete from '@planning/pages/Order/components/RailTrackControlledAutocomplete'
import { railVisitService } from '@planning/services'
import { useNavigationStore } from '@planning/stores'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import {
  notAllowPlusMinusPointSign,
  notAllowPlusMinusSign,
  onlyAllowPositiveInt,
} from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IInspectRailcarFormData } from '../../InspectRailcar'
import { InspectRailcarPins } from './InspectRailcarPins'

interface IProps {
  railcarTrackPosition: RailcarTrackPositionResponseDto
  operationType?: CheckType
  pins: PinInfo[]
}

export const InspectRailcarForm: FC<IProps> = observer(
  ({ railcarTrackPosition, operationType, pins }) => {
    const { t } = useTranslate()
    const { railVisitItemStore, railcarTrackPositionItemStore, appViewStore } = usePlanningStore()
    const navigationStore = useNavigationStore()

    const {
      id: railcarTrackPositionId,
      railVisitId,
      railTrackId,
      railcarName,
    } = railcarTrackPosition

    const {
      control,
      setValue,
      register,
      formState: { errors },
    } = useFormContext<IInspectRailcarFormData>()

    const getRailTracksByVisit = useCallback(
      (visitId: number) => {
        const railTracks = railcarTrackPositionItemStore.getRailTracks()

        const assignedRailTrackIds =
          railVisitItemStore.getById(visitId)?.data.assignedRailTrackIds || []

        return railTracks.filter(x => assignedRailTrackIds.includes(x.id))
      },
      [railVisitItemStore, railcarTrackPositionItemStore],
    )

    const railTracks = computed(() => getRailTracksByVisit(railVisitId)).get()

    const onChange = async (railTrack: RailTrackResponseDto | null) => {
      if (railTrackId !== railTrack?.id) {
        const confirm = await appViewStore.setOpenConfirmDialog(
          true,
          t(
            'willRecordThisRailcarAgainsTrackForCheckingLate',
            'We will record this railcar against {trackName} for checking later.',
            {
              trackName: railTrack?.name,
            },
          ),
          t('changeTrack', 'Change Track'),
        )

        if (confirm && railTrack?.id) {
          const cmd: AssignRailcarTrackPositionCommand = {
            railVisitId,
            ids: [railcarTrackPositionId],
            newTrackId: railTrack.id,
          }

          appViewStore.showLoader()

          try {
            await railVisitService.assignRailcarTrackPosition(cmd)
            appViewStore.setShowAlert(
              'success',
              t('railcarMovedToTrack', 'Railcar {railcar} was moved to track {track}', {
                railcar: formatRailcarName(railcarName),
                track: railTrack?.name,
              }),
            )
            navigationStore.pop()
          } catch (e) {
            appViewStore.setShowAlert('error', t('failedToMoveRailcar', 'Failed to move railcar'))
          } finally {
            appViewStore.hideLoader()
          }
        } else {
          setValue('railTrackId', railTrackId)
        }
      }
    }

    return (
      <InitializationWrapper isInitializing={appViewStore.isLoading}>
        <form>
          <Grid container spacing='0.5rem' p='1rem'>
            <Grid item xs={12}>
              <RailTrackControlledAutocomplete
                control={control}
                errors={errors}
                setValue={setValue}
                onChangeFn={onChange}
                name='railTrackId'
                label={t('railTrack', 'Rail Track')}
                options={railTracks}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='sequence'
                rules={{ required: true }}
                render={() => (
                  <TextField
                    data-cy='railcar-sequence'
                    fullWidth
                    label={`${t('sequenceNo', 'Sequence no.')}`}
                    required
                    variant='outlined'
                    type='number'
                    {...register('sequence', { valueAsNumber: true, min: 1 })}
                    name='sequence'
                    onKeyDown={notAllowPlusMinusPointSign}
                    onChange={onlyAllowPositiveInt}
                    error={!!errors.sequence}
                    helperText={errors.sequence ? t('fieldIsRequired', 'Field is required.') : ''}
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-cy='railcar-length'
                fullWidth
                label={`${t('length', 'Length')}`}
                variant='outlined'
                type='number'
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      m
                    </CustomInputAdornment>
                  ),
                }}
                inputProps={{
                  min: '0',
                  step: '0.01',
                  pattern: '[0-9]*',
                }}
                {...register('length', { valueAsNumber: true })}
                onKeyDown={notAllowPlusMinusSign}
                name='length'
                sx={{ marginTop: '1rem' }}
              />
            </Grid>

            {operationType === CheckType.LoadPreparation && pins && (
              <Grid item xs={12}>
                <InspectRailcarPins pins={pins} />
              </Grid>
            )}
          </Grid>
        </form>
      </InitializationWrapper>
    )
  },
)
