import { FieldStore } from './FieldStore'
import { ListFieldStore } from './ListFieldStore'
import { SubFormType } from './interfaces'

const validateField = (
  field: FieldStore<any> | ListFieldStore<any> | any,
  onlyTouched: boolean,
): boolean => {
  if (!field) {
    return true
  }
  if (field instanceof FieldStore) {
    if (onlyTouched && !field.isTouched) {
      return true
    }
    if (!onlyTouched) {
      field.touch()
    }
    return field.validate()
  } else if (field instanceof ListFieldStore) {
    const isValid = field.validate()
    return field.value.reduce((isValid, item) => {
      return validateField(item, onlyTouched) && isValid
    }, isValid)
  } else if (typeof field === 'object') {
    // Validate stores that are not FieldStore or ListFieldStore
    if (
      Object.getOwnPropertyDescriptor(field, 'validate') ||
      Object.getOwnPropertyDescriptor(Object.getPrototypeOf(field), 'validate')
    ) {
      return field.validate()
    }
    return validateSubForm(field, onlyTouched)
  }
  return true
}

// Validates a subform and returns a boolean indicating if it is valid, as well as setting validation errors
// If onlyTouched is set to true, it will only validate fields that have been touched
export const validateSubForm = (subForm: SubFormType, onlyTouched = false): boolean => {
  let isValid = true
  for (const key in subForm) {
    const field = subForm[key]
    isValid = validateField(field, onlyTouched) && isValid
  }
  return isValid
}

// Extracts the data from a subform and returns it as a plain object
// If includeAll is set to true, it will include all fields, even if they do not implement the ISubFormStore interface
export const extractSubFormData = (subForm: SubFormType, includeAll = false): SubFormType => {
  const exportedSubForm: SubFormType = {}
  for (const key in subForm) {
    const field = subForm[key]
    if (typeof field === 'object') {
      if (Object.getOwnPropertyDescriptor(Object.getPrototypeOf(field), 'data')) {
        exportedSubForm[key] = field.data
      } else {
        exportedSubForm[key] = extractSubFormData(field)
      }
    } else if (Array.isArray(field)) {
      exportedSubForm[key] = field.map(item => extractSubFormData(item))
    } else if (includeAll) {
      exportedSubForm[key] = field
    }
  }
  return exportedSubForm
}
