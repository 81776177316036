import { action, makeObservable, observable } from 'mobx'
import { ISubFormStore, SubFormType } from './interfaces'
import { extractSubFormData } from './subFormHelper'

export class ListFieldStore<T extends Record<string, any>> implements ISubFormStore<SubFormType[]> {
  value: T[] = []
  error?: string

  constructor(
    data: T[],
    private validator?: (val: T[]) => string | undefined,
  ) {
    this.value = data
    makeObservable(this, {
      value: observable,
      error: observable,
      push: action,
      removeByIndex: action,
      removeByCondition: action,
      validate: action,
    })
  }

  push = (value: T) => {
    this.value.push(value)
  }

  removeByIndex = (index: number) => {
    this.value.splice(index, 1)
  }

  removeByCondition = (condition: (item: T) => boolean) => {
    this.value = this.value.filter(item => !condition(item))
  }

  validate = () => {
    if (this.validator) {
      const error = this.validator(this.value)
      this.error = error
      return error === undefined
    }
    return true
  }

  get data() {
    return this.value.map(item => extractSubFormData(item))
  }
}
