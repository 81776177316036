import { Box, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  CarrierType,
  ContainerDamageResponseDto,
  OrderResponseDto,
  UnitType,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IContainerDamage } from '@planning/components'
import { ControlledContainerSeals } from '@planning/components/ControlledContainerSeals'
import { ControlledDamageReports } from '@planning/components/damage-report/ControlledDamageReports'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import {
  createOogFormDataFromOrderResponse,
  OogFormData,
} from '@planning/components/organisms/OogSubForm/oog-helper'
import { OogSubForm } from '@planning/components/organisms/OogSubForm/OogSubForm'
import { DamageReportBox } from '@planning/pages/TallymanV2/Components/DamageReportBox'
import { validateIsoCode } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions, CustomInputAdornment } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

interface IProps {
  order: OrderResponseDto
  damages?: ContainerDamageResponseDto[]
  displayDamageReport?: boolean
  onSubmit: (data: IInspectContainerFormData) => Promise<void> | void
  onCancel?: () => void
}

// todo: rename? better name?
export interface IInspectContainerFormData extends OrderResponseDto {
  carrierType: CarrierType
  damages: ContainerDamageResponseDto[]
  isEdited?: boolean
  damagesReported?: IContainerDamage[]
  hasDamage?: boolean
  oog?: OogFormData
}

export const InspectContainer: FC<IProps> = observer(
  ({ order, damages, displayDamageReport, onSubmit, onCancel }) => {
    const { t } = useTranslate()
    const { containerItemStore, appViewStore } = usePlanningStore()
    const hasNewDamageRecording = useBooleanFlagValue('damage-recording', false)

    const formStore = useFormStore<IInspectContainerFormData>()

    useEffect(() => {
      if (order.containerId) containerItemStore.fetchById(order.containerId)
    }, [containerItemStore, order.containerId])

    const containerItem = computed(() => containerItemStore.elements[order.containerId ?? 0]).get()

    // TODO: Extract form as component without header
    const { register, control, handleSubmit, getValues, setValue, formState, watch } =
      useForm<IInspectContainerFormData>({
        defaultValues: {
          ...order,
          id: order?.id,
          referenceNumber: order?.referenceNumber,
          direction: order?.direction,
          carrierType: order.carrierVisitType,
          containerId: order.containerId,
          containerNumber: order?.containerNumber,
          unitType: order.unitType,
          containerIsoCode: order?.containerIsoCode,
          containerTare: order?.containerTare,
          containerMaxGross: order?.containerMaxGross,
          isEmpty: order?.isEmpty,
          imoClasses: order?.imoClasses,
          holds: order?.holds,
          damages: damages ?? containerItem?.data.damages ?? [],
          hasDamage: !!(
            (order as IInspectContainerFormData).damagesReported?.length ??
            containerItem?.data.damages.length
          ),
          damagesReported: (order as IInspectContainerFormData).damagesReported ?? [],
          hasSeals: order?.hasSeals ?? false,
          seals: order?.seals ?? [],
          oog: createOogFormDataFromOrderResponse(order),
        },
      })

    useEffect(() => {
      if (containerItem) {
        setValue(
          'hasDamage',
          !!(
            (order as IInspectContainerFormData).damagesReported?.length ??
            containerItem?.data.damages.length
          ),
        )
      }
    }, [containerItem, order, setValue])

    const [defaultValuesData] = useState(getValues())
    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)

    const onFormSubmit = async (data: any) => {
      try {
        data.isEdited = JSON.stringify(defaultValuesData) !== JSON.stringify(data)

        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onEditDamageReport = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    const containerIsoCodeErrorText = (errors: FieldErrors) => {
      const isoCodeError = errors.containerIsoCode
      if (isoCodeError) {
        const errorType = isoCodeError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

        return isoCodeError.message?.toString()
      }
    }

    const unitTypeIsContainer = containerItem?.data.unitType === UnitType.Container

    return (
      <>
        <DialogTitle sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}>
          {order.containerNumber}
        </DialogTitle>

        <DialogContent>
          {order && (
            <form>
              <Grid container>
                {unitTypeIsContainer && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={`${t('iso', 'ISO')}`}
                      required
                      variant='outlined'
                      {...register('containerIsoCode', {
                        required: true,
                        validate: v => !!v && validateIsoCode(v),
                      })}
                      name='containerIsoCode'
                      sx={{ marginTop: '1rem' }}
                      error={!!formState.errors.containerIsoCode}
                      helperText={containerIsoCodeErrorText(formState.errors)}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={`${t('maxGrossWeight', 'Max. gross weight')}`}
                    variant='outlined'
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register('containerMaxGross', { valueAsNumber: true })}
                    name='containerMaxGross'
                    sx={{ marginTop: '1rem' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={`${t('tareWeight', 'Tare weight')}`}
                    variant='outlined'
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register('containerTare', { valueAsNumber: true })}
                    name='containerTare'
                    sx={{ marginTop: '1rem' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ marginTop: '1rem !important' }}>
                    <Controller
                      control={control}
                      name={`imoClasses`}
                      rules={{ required: isDangerous }}
                      render={({ field: { onChange } }) => (
                        <DangerousGoodAutoComplete
                          assignedCodes={getValues(`imoClasses`) ?? []}
                          enableSelect={isDangerous}
                          onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                          onDangerousGoodChange={newValues => {
                            onChange(newValues)
                          }}
                          error={!!formState.errors.imoClasses}
                          helperText={
                            formState.errors.imoClasses
                              ? t('fieldIsRequired', 'Field is required.')
                              : ''
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
                {displayDamageReport && containerItem && (
                  <Grid item xs={12}>
                    <Box sx={{ marginTop: '1rem !important' }}>
                      {hasNewDamageRecording ? (
                        <ControlledDamageReports
                          control={control}
                          formState={formState}
                          containerId={containerItem.id}
                          damages={containerItem.activeDamages}
                          watch={watch}
                        />
                      ) : (
                        <Controller
                          control={control}
                          name={`damages`}
                          render={({ field: { onChange } }) => (
                            <DamageReportBox
                              containerId={0}
                              damages={getValues('damages')}
                              onCreateDamageReport={(_, data) =>
                                onChange([
                                  ...getValues('damages'),
                                  {
                                    ...(data as ContainerDamageResponseDto),
                                    created: new Date().toISOString(),
                                  },
                                ])
                              }
                              onEditDamageReport={onEditDamageReport}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Grid>
                )}

                {!getValues('isEmpty') && (
                  <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                    <Controller
                      control={control}
                      name={'oog'}
                      render={({ field: { onChange, value } }) => (
                        <OogSubForm
                          formStore={formStore}
                          name='oog'
                          initialValue={value}
                          onChange={oog => onChange(oog)}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                  <ControlledContainerSeals
                    name={`seals`}
                    control={control}
                    sealsName={`seals`}
                    hasSealsName={`hasSeals`}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>

        <ConfirmationActions
          primaryActionText={t('save', 'Save')}
          onConfirm={handleSubmit(onFormSubmit)}
          closeLabel={t('cancel', 'Cancel')}
          onClose={() => onCancel && onCancel()}
          hasBorderTop
        />
      </>
    )
  },
)
