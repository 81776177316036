import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  title: string | React.ReactNode
  attributes: ISummaryAttribute[]
  onClickLabel?: string
  onClickFn?: () => void
}

export interface ISummaryAttribute {
  key: string
  label: string
  value?: string | number | null | undefined
}

const SummaryAttribute: FC<ISummaryAttribute> = ({ label, value }) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='caption' color={theme.palette.grey[600]}>
        {label}
      </Typography>
      <Typography variant='subtitle1' color={theme.palette.grey[600]}>
        {value}
      </Typography>
    </Box>
  )
}

export const SummaryCard: FC<IProps> = ({ title, attributes, onClickLabel, onClickFn }) => {
  const theme = useMinimalsTheme()

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.grey[200],
        padding: theme.customSpacing.m,
      }}
    >
      <Grid
        item
        sm={11}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.customSpacing.s,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.customSpacing.xs,
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: theme.customSpacing.xxs,
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.customSpacing.xl,
            }}
          >
            {attributes
              .filter(attribute => attribute.value)
              .map(attribute => (
                <SummaryAttribute {...attribute} key={attribute.key} />
              ))}
          </Box>
        </Box>
      </Grid>
      <Grid
        sm={1}
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'center',
        }}
      >
        {onClickLabel && (
          <Button variant='text' color='inherit' onClick={onClickFn}>
            <Typography variant='subtitle1'>{onClickLabel}</Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
