import { Box, Stack, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { OrderListViewStore } from '../../stores/OrderListViewStore'

export interface IHeaderWithBreadcrumbsProps {
  bookingNumber: string
  store: OrderListViewStore
}

export const HeaderWithBreadcrumbs: FC<IHeaderWithBreadcrumbsProps> = observer(
  ({ bookingNumber, store }) => {
    const { t } = useTranslate()
    const navigate = useNavigate()
    return (
      <>
        <Breadcrumbs
          items={[
            {
              label: t('bookings', 'Bookings'),
              onClick: () => navigate('/bookings'),
            },
            {
              label: `${bookingNumber}`,
            },
          ]}
        />
        <Header
          title={t('containersList', 'Containers List')}
          rightRenderOption={
            <Stack flexDirection='row' alignItems='center' gap={2}>
              <Box width='300px'>
                <TextField
                  label={t('searchByContainerNumber', 'Search by container number')}
                  data-cy='order-list-page-search-field'
                  sx={{ width: '100%', marginTop: 0 }}
                  value={store.filter}
                  onChange={event => {
                    store.setFilter(event.currentTarget.value.toLocaleUpperCase())
                  }}
                />
              </Box>
            </Stack>
          }
        />
      </>
    )
  },
)
