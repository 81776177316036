import { OperationType } from '@operations/app/api'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { LegendHeader } from '../components/Legend/LegendHeader'
import { CraneOperatorUIStore } from '../stores/crane-operator.ui-store'
import {
  hasActiveHolds,
  hasDischarge,
  hasLoading,
  hasTransit,
  isDangerous,
  isEmpty,
  isFlatRack,
  isFull,
  isHighCube,
  isOutOfGauge,
  isReefer,
  isRestow,
} from '../utils'

export interface Props {
  uiStore: CraneOperatorUIStore
}

const ItemViewLegendHeaderContainer = observer(({ uiStore }: Props) => {
  const navigate = useNavigate()

  return (
    <LegendHeader
      legendOptions={{
        hasFull: uiStore.itemBayhasContainer(isFull),
        hasEmpty: uiStore.itemBayhasContainer(isEmpty),
        hasRestow: uiStore.itemBayhasContainer(isRestow),
        hasDangerous: uiStore.itemBayhasContainer(isDangerous),
        hasReefer: uiStore.itemBayhasContainer(isReefer),
        hasOutOfGauge: uiStore.itemBayhasContainer(isOutOfGauge),
        hasHighCube: uiStore.itemBayhasContainer(isHighCube),
        // hasDryContainer: uiStore.itemBayhasContainer(isDryContainer), TODO: use this later
        hasDryContainer: true,
        hasFlatRack: uiStore.itemBayhasContainer(isFlatRack),
        hasOtherCrane: uiStore.itemBayhasContainer(c => uiStore.isOtherCrane(c)),
        hasTransit: uiStore.itemBayContain(hasTransit),
        hasLoading: uiStore.itemBayContain(hasLoading),
        hasDischarge: uiStore.itemBayContain(hasDischarge),
        hasActiveHolds: uiStore.itemBayhasContainer(hasActiveHolds),
      }}
      loadPorts={uiStore.itemViewGeometryLoadPorts}
      bays={uiStore.itemViewBayNumbers}
      currentBay={uiStore.itemViewCurrentBayNumber}
      operationType={uiStore.itemViewOperationType}
      availableOperationTypes={uiStore.itemViewAvailableOperationTypes}
      hasPrevious={!!uiStore.itemViewGetPreviousBay}
      hasNext={!!uiStore.itemViewGetNextBay}
      onNext={() => {
        uiStore.setItemViewCurrentBayNumber(uiStore.itemViewGetNextBay)
      }}
      onPrevious={() => {
        uiStore.setItemViewCurrentBayNumber(uiStore.itemViewGetPreviousBay)
      }}
      onOperationTypeChange={() => {
        uiStore.setItemViewOperationType(
          uiStore.itemViewOperationType === OperationType.Inbound
            ? OperationType.Outbound
            : OperationType.Inbound,
        )
      }}
      onBayChange={(bayNumber: number) => {
        uiStore.setItemViewCurrentBayNumber(bayNumber)
      }}
      onOpenOverview={() => {
        navigate(
          `/operatorViews/Crane/BayView/List/${uiStore.selectedVesselVisitId}/${uiStore.selectedEquipmentId}`,
        )
      }}
    />
  )
})

export default ItemViewLegendHeaderContainer
