import { extractSubFormData, FieldStore, ISubFormStore, validateSubForm } from '@tom-ui/utils'
import { computed, makeObservable } from 'mobx'
import { OogFormData } from './oog-helper'

export class OogSubFormStore implements ISubFormStore<OogFormData> {
  isOog: FieldStore<boolean>
  overLengthRear: FieldStore<number | undefined>
  overLengthFront: FieldStore<number | undefined>
  overWidthLeft: FieldStore<number | undefined>
  overWidthRight: FieldStore<number | undefined>
  overHeight: FieldStore<number | undefined>

  constructor(initialValue?: OogFormData) {
    makeObservable(this, {
      isOogSelected: computed,
    })

    this.isOog = new FieldStore<boolean>(initialValue?.isOog ?? false)
    this.overLengthRear = new FieldStore<number | undefined>(
      initialValue?.overLengthRear ?? undefined,
    )
    this.overLengthFront = new FieldStore<number | undefined>(
      initialValue?.overLengthFront ?? undefined,
    )
    this.overWidthLeft = new FieldStore<number | undefined>(
      initialValue?.overWidthLeft ?? undefined,
    )
    this.overWidthRight = new FieldStore<number | undefined>(
      initialValue?.overWidthRight ?? undefined,
    )
    this.overHeight = new FieldStore<number | undefined>(initialValue?.overHeight ?? undefined)
  }

  validate = () => {
    return validateSubForm(this)
  }

  get isOogSelected() {
    return this.isOog.value
  }

  get data() {
    if (!this.isOogSelected) {
      return {
        isOog: false,
      }
    }

    return extractSubFormData(this)
  }
}
