import {
  ContainerCharacteristics,
  ContainerDamageUpsertDto,
  ContainersApi,
  ContainerType,
  CreateContainerDamageCommand,
  CreateContainerVisitCommand,
  ResolveContainerDamagesCommand,
  UnitType,
  UnresolveContainerDamagesCommand,
  UpdateContainerVisitCommand,
  UploadContainerDamageImagesResponseDto,
  UpsertContainerCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { IContainerDamage } from '@planning/components'

class ContainerService {
  httpClient = createApiClient(ContainersApi)

  async get(page: number, pageSize: number, filter?: string, unitType?: UnitType) {
    const { data } = await this.httpClient.get(page, pageSize, unitType, undefined, filter)

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)

    return data
  }

  async post(cmd: UpsertContainerCommand) {
    const { data: id } = await this.httpClient.post(cmd)

    return id
  }

  async getContainersByPartialNumber(
    searchFilter: string,
    isEmpty?: boolean,
    hasServiceOrders?: boolean,
    canAssignServiceOrders?: boolean,
    type?: ContainerType,
  ) {
    const { data } = await this.httpClient.getContainersByPartialNumber(
      searchFilter,
      isEmpty,
      hasServiceOrders,
      canAssignServiceOrders,
      type,
    )

    return data
  }

  async createDamage(cmd: CreateContainerDamageCommand) {
    await this.httpClient.damage(cmd.containerId, cmd)
  }

  async resolveDamages(cmd: ResolveContainerDamagesCommand) {
    await this.httpClient.resolveDamages(cmd.containerId, cmd)
  }

  async unresolveDamages(cmd: UnresolveContainerDamagesCommand) {
    await this.httpClient.unresolveDamages(cmd.containerId, cmd)
  }

  async createContainerVisit(id: number, cmd: CreateContainerVisitCommand) {
    const { data } = await this.httpClient.createContainerVisit(id, cmd)
    return data
  }

  async updateContainerVisit(cmd: UpdateContainerVisitCommand) {
    await this.httpClient.updateContainerVisit(cmd)
  }

  //TODO [OCTA-948] Replace with linkable orders
  async activeOrders(containerNumbers: string[]) {
    const { data } = await this.httpClient.activeOrders({ numbers: containerNumbers })
    return data
  }

  async uploadDamageImagesForContainer(
    containerNumber: string,
    images: File[],
  ): Promise<UploadContainerDamageImagesResponseDto[]> {
    if (!images.length) {
      return []
    }

    const { data } = await this.httpClient.containerDamageImages(containerNumber, images)

    return data
  }

  async getDamageDtoForUploadedDamages(containerNumber: string, damages: IContainerDamage[]) {
    const requests = damages.map(x =>
      this.uploadDamageImagesForContainer(containerNumber, x.images),
    )

    const imagePaths = await Promise.all(requests)

    return damages.map(
      (x, index) =>
        ({
          description: x.description,
          size: x.size,
          typeIds: x.containerDamageTypes,
          locationId: x.containerDamageLocation,
          imagePaths: imagePaths[index].map(img => img.imagePath) ?? [],
        }) as ContainerDamageUpsertDto,
    )
  }

  async getContainerCharacteristicsByIsoCode(
    isoCode: string,
    onlyStandard: boolean,
  ): Promise<ContainerCharacteristics> {
    const { data } = await this.httpClient.getContainerCharacteristicsByIsoCode(
      isoCode,
      onlyStandard,
    )
    return data
  }

  // return list of invalid iso codes
  async validateIsoCodes(isoCodes: string[]) {
    const { data } = await this.httpClient.validateIsoCodeList(isoCodes)
    return data
  }

  async getAttributes(containerNumber: string) {
    const { data } = await this.httpClient.getContainerAttributes(containerNumber)
    return data
  }
}

const containerService = new ContainerService()

export default containerService
