import { Stack, Typography } from '@mui/material'
import { ContainerPositionType, JobDto } from '@operations/app/api'
import { EmptyIcon, FullIcon, useMinimalsTheme, WeightIcon } from '@tom-ui/ui'
import { getContainerHeight } from '../../utils'

export interface Props {
  job: JobDto
}
export const JobDescriptionMinimalistic = ({ job }: Props) => {
  const theme = useMinimalsTheme()

  if (!job.cargoUnit) return <></>

  const isContainerJob =
    job.origin.type !== ContainerPositionType.TrailerParkingLot &&
    job.destination.type !== ContainerPositionType.TrailerParkingLot

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.l}>
      {isContainerJob && (
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          {job.cargoUnit?.isEmpty ? <EmptyIcon /> : <FullIcon />}
          {!!job.cargoUnit.length && !!job.cargoUnit.height && (
            <Typography variant='body2'>
              {job.cargoUnit.length} {getContainerHeight(job.cargoUnit.height)}
            </Typography>
          )}
        </Stack>
      )}
      {!!job.order?.grossWeight && (
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          <WeightIcon />
          <Typography variant='body2'>{`${job.order.grossWeight}kg`}</Typography>
        </Stack>
      )}
    </Stack>
  )
}
