import { CargoType, CreateRailVisitCommand, UpdateRailVisitCommand } from '@planning/app/api'
import { ITripId } from '@planning/components/visit/VisitTripIdsFields'
import { railVisitService } from '@planning/services'
import dayjs, { Dayjs } from 'dayjs'
import { action, computed, makeObservable, observable } from 'mobx'
import { VisitPlanningStore } from '../visit/VisitPlanningStore'
import { IRailVisitItem } from './RailVisitItem'

export interface IRailVisitPlanningFormData {
  id?: number
  eta?: Dayjs
  name: string
  etd?: Dayjs
  trainOperator?: string
  cargoType: CargoType
  dischargeEstimate: number
  loadEstimate: number
  railcarEstimate?: number
  railTrackIds: string[]
  tripIds: ITripId[]
}

export class RailVisitPlanningStore extends VisitPlanningStore {
  railVisit?: IRailVisitItem

  constructor() {
    super()
    makeObservable(this, {
      railVisit: observable,

      setRailVisit: action,
      createRailVisit: action,
      reset: action,

      railTrackIdsWithOrders: computed,
    })
  }

  createRailVisit = async (data: IRailVisitPlanningFormData) => {
    const createCmd: CreateRailVisitCommand = {
      name: data.name,
      // [Review] TODO: Why are we sending 'dayjs(data.eta) as any'? String is expected here. Do not use 'as any'!
      eta: dayjs(data.eta) as any,
      etd: dayjs(data.etd) as any,
      inboundTripIds: this.getInboundsFromTripIds(data.tripIds),
      outboundTripIds: this.getOutboundsFromTripIds(data.tripIds),
      loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
      dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
      cargoType: data.cargoType,
      schedule: this.scheduleTiming(),
      railTrackIds: data.railTrackIds,
      railcarEstimate: data.railcarEstimate,
      trainOperator: data.trainOperator,
    }

    return await railVisitService.post(createCmd)
  }

  updateRailVisit = async (data: IRailVisitPlanningFormData) => {
    if (!data.id) return

    const updateCmd: UpdateRailVisitCommand = {
      id: data.id,
      name: data.name,
      eta: dayjs(data.eta) as any,
      etd: dayjs(data.etd) as any,
      inboundTripIds: this.getInboundsFromTripIds(data.tripIds),
      outboundTripIds: this.getOutboundsFromTripIds(data.tripIds),
      loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
      dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
      cargoType: data.cargoType,
      railTrackIds: data.railTrackIds,
      railcarEstimate: data.railcarEstimate,
      trainOperator: data.trainOperator,
    }

    return await railVisitService.put(updateCmd)
  }

  setRailVisit = (visit?: IRailVisitItem) => {
    this.railVisit = visit
  }

  reset = () => {
    this.railVisit = undefined

    this.resetVisit()
  }

  get railTrackIdsWithOrders() {
    const trackIdsWithOrders = this.railVisit?.discharge.containerOrders
      .filter(x => x.railcarTrackPosition?.railTrack?.id)
      .map(x => x.railcarTrackPosition!.railTrack!.id)

    this.railVisit?.load.containerOrders.forEach(x => {
      const trackId = x.railcarTrackPosition?.railTrack?.id
      if (trackId && !trackIdsWithOrders?.includes(trackId)) {
        trackIdsWithOrders?.push(trackId)
      }
    })

    return trackIdsWithOrders
  }

  get railTrackWithDuplicateRailcars() {
    const railcarMap = new Map<string, Map<number, Set<string>>>()
    const railcarTracks = this.railVisit?.railcarTrackPositions.map(x => x.data) ?? []

    for (const { railcarId, railTrackId, direction } of railcarTracks) {
      if (!railcarMap.has(direction)) {
        railcarMap.set(direction, new Map<number, Set<string>>())
      }

      const directionMap = railcarMap.get(direction)!

      if (!directionMap.has(railcarId)) {
        directionMap?.set(railcarId, new Set<string>())
      }

      directionMap.get(railcarId)!.add(railTrackId)
    }

    return Array.from(railcarMap.values()).some(directionMap =>
      Array.from(directionMap.values()).some(railTrackIds => railTrackIds.size > 1),
    )
  }
}
