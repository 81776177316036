import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { FieldboxWithRadio, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

import { Stack } from '@mui/system'
import { CarrierVisitDirection, RemoveOrFinishOrderOption } from '@planning/app/api'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { OrderItemChips } from '@planning/components/organisms/OrderItemChips'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { emptyIndicator } from '@planning/constants'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { orderService } from '@planning/services'

interface Props {
  visitItem: IVesselVisitItem | IRailVisitItem
  callback?: () => Promise<void>
  hideFinishOrderOptionForLoading?: boolean
}

export const RemoveOrFinishOpenOrders: FC<Props> = observer(
  ({ visitItem, callback, hideFinishOrderOptionForLoading }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { dialogStore, appViewStore } = usePlanningStore()
    const [carrierVisitDirection, setCarrierVisitDirection] = useState<CarrierVisitDirection>(
      visitItem.outboundOpenOrders.length === 0 && visitItem.inboundOpenOrders.length > 0
        ? CarrierVisitDirection.Inbound
        : CarrierVisitDirection.Outbound,
    )
    const [inboundRemoveOrFinishOrderOption, setInboundRemoveOrFinishOrderOption] = useState<
      RemoveOrFinishOrderOption | undefined
    >()

    const [outboundRemoveOrFinishOrderOption, setOutboundRemoveOrFinishOrderOption] = useState<
      RemoveOrFinishOrderOption | undefined
    >()

    useEffect(() => {
      setInboundRemoveOrFinishOrderOption(undefined)
      setOutboundRemoveOrFinishOrderOption(undefined)
    }, [])

    const selectedOrders =
      carrierVisitDirection === CarrierVisitDirection.Inbound
        ? visitItem.inboundOpenOrders
        : visitItem.outboundOpenOrders
    const selectedRemoveOrFinishOrderOption =
      carrierVisitDirection === CarrierVisitDirection.Inbound
        ? inboundRemoveOrFinishOrderOption
        : outboundRemoveOrFinishOrderOption

    const handleOptionChange = (value: string): void => {
      if (carrierVisitDirection === CarrierVisitDirection.Inbound)
        setInboundRemoveOrFinishOrderOption(value as RemoveOrFinishOrderOption)
      else setOutboundRemoveOrFinishOrderOption(value as RemoveOrFinishOrderOption)
    }

    const handleConfirm = async () => {
      try {
        await orderService.removeOrFinishOpenOrders({
          carrierVisitId: visitItem.id,
          inboundRemoveOrFinishOrderOption: inboundRemoveOrFinishOrderOption,
          outboundRemoveOrFinishOrderOption: outboundRemoveOrFinishOrderOption,
        })
        if (callback) await callback()
        appViewStore.setShowAlert('success', t('success', 'Success'))
        dialogStore.close()
      } catch (error: any) {
        appViewStore.setShowAlert(
          'error',
          error.response?.data?.errors?.validationError ?? 'An unexpected error occurred',
        )
      }
    }

    return (
      <>
        <Typography variant='h4' m={theme.spacing(3)} align='left'>
          {t('containersWithStatusInProgress', 'Containers with status in progress')}
        </Typography>
        <Divider />
        <DialogContent>
          <Box mt={theme.spacing(1)}>
            <Stack spacing={theme.spacing(3)}>
              <Stack spacing={theme.spacing(1)} direction='row'>
                <Button
                  variant='contained'
                  disabled={visitItem.outboundOpenOrders.length === 0}
                  onClick={() => setCarrierVisitDirection(CarrierVisitDirection.Outbound)}
                  color={
                    carrierVisitDirection === CarrierVisitDirection.Outbound
                      ? 'secondary'
                      : 'inherit'
                  }
                >
                  {t('loading', 'Loading')} {visitItem.outboundOpenOrders.length}
                </Button>
                <Button
                  variant='contained'
                  disabled={visitItem.inboundOpenOrders.length === 0}
                  onClick={() => setCarrierVisitDirection(CarrierVisitDirection.Inbound)}
                  color={
                    carrierVisitDirection === CarrierVisitDirection.Inbound
                      ? 'secondary'
                      : 'inherit'
                  }
                >
                  {t('discharge', 'Discharge')} {visitItem.inboundOpenOrders.length}
                </Button>
              </Stack>
              <Alert severity='warning'>
                {t(
                  'thereAreStillContainerWithTheStatusInProgress.',
                  'There are still container with the status in progress.',
                )}
              </Alert>
              <Stack border={`1px solid ${theme.palette.grey[300]}`}>
                {selectedOrders.map(item => (
                  <OrderItem item={item} key={`grp-row-${item.id}`} />
                ))}
              </Stack>
              <Stack>
                <FieldboxWithRadio
                  selectionKey={RemoveOrFinishOrderOption.Remove}
                  selectedKey={selectedRemoveOrFinishOrderOption}
                  onSelect={handleOptionChange}
                  label={t('removeOrders', 'Remove orders')}
                  description={t(
                    'theOrdersWillBeDeletedAndWorkInstructionsWillBeCancelled.',
                    'The orders will be deleted and work instructions will be cancelled.',
                  )}
                />

                {hideFinishOrderOptionForLoading &&
                carrierVisitDirection === CarrierVisitDirection.Outbound ? (
                  <></>
                ) : (
                  <FieldboxWithRadio
                    selectionKey={RemoveOrFinishOrderOption.Finish}
                    selectedKey={selectedRemoveOrFinishOrderOption}
                    onSelect={handleOptionChange}
                    label={t('markAsDone', 'Mark as done')}
                    description={
                      carrierVisitDirection === CarrierVisitDirection.Inbound
                        ? t(
                            'theOrdersWillBeFulfilledAndWorkInstructionsWillRemainOpen.',
                            'The orders will be fulfilled and work instructions will remain open but with issues so the Yard Position can be provided.',
                          )
                        : t(
                            'theOrdersWillBeFulfilledAndWorkInstructionsWillBeFinished.',
                            'The orders will be fulfilled and work instructions will be finished.',
                          )
                    }
                  />
                )}
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <Divider />
        <Grid container>
          <Grid item xs={11}>
            <DialogActions>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  width: '100%',
                }}
              >
                <Button variant='contained' color='primary' onClick={handleConfirm}>
                  {t('confirm', 'Confirm')}
                </Button>

                <Button variant='text' color='inherit' onClick={dialogStore.close}>
                  {t('cancel', 'Cancel')}
                </Button>
              </Box>
            </DialogActions>
          </Grid>
        </Grid>
      </>
    )
  },
)

const OrderItem: FC<{ item: IOrderItem }> = observer(({ item }: { item: IOrderItem }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Grid
      container
      paddingX={1.5}
      paddingLeft={1.5}
      height={'60px'}
      display={'flex'}
      alignItems={'center'}
      borderBottom={`1px solid ${theme.palette.grey[300]}`}
    >
      <Grid item xs={2} xl={2} textAlign={'start'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Box key={item.id}>{useUnitTypeIcon(item.data.unitType)}</Box>
          <Box>
            <Typography component={'div'} variant='body2'>
              {item.data.containerNumber ??
                (item.data.nonNumericOrderId ? t('nonNumeric', 'Non-numeric') : '')}
            </Typography>
            <Typography component={'div'} variant='mini'>
              {item.data.referenceNumber ? item.data.referenceNumber : emptyIndicator}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={7} xl={7} alignSelf={'center'} textAlign={'start'}>
        <OrderItemChips order={item.data} />
      </Grid>
      <Grid textAlign={'end'} item xs={3}>
        <Grid container>
          <Grid item xs={10} xl={11} sx={{ placeContent: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                placeContent: 'flex-end',
                gap: '4px',
              }}
            >
              <OrderStatusChip status={item.data.status} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})
