import DownloadIcon from '@mui/icons-material/Download'
import { Button, MenuItem, Tooltip } from '@mui/material'
import { GridApi } from '@mui/x-data-grid'
import { NonNumericOrderDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { nnrOrderService } from '@planning/services'
import { PaginatedLocalStore } from '@planning/stores/PaginatedLocalStore'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, Header, ThreeDotMenu } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, MutableRefObject } from 'react'
import { useNavigate } from 'react-router'
import { AssignNNROrderContainer } from '../Components/AssignNNROrderContainer'

interface HeaderOptionsProps {
  id: number
  nnrOrder: NonNumericOrderDto
  apiRef: MutableRefObject<GridApi>
}

interface Props extends HeaderOptionsProps {
  orderLocalPaginationStore: PaginatedLocalStore<IOrderItem>
}

const NNROrderHeaderOptions: FC<HeaderOptionsProps> = observer(({ nnrOrder, apiRef }) => {
  const { t } = useTranslate()

  const { appViewStore, drawerStore } = usePlanningStore()

  const { id, referenceNumber, shippingLine, isClosed } = nnrOrder
  const showAssignButton = !isClosed && nnrOrder.orderCount !== nnrOrder.amount

  const handleOrderClose = async () => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'areYouSureYouWantToCloseThisNonNumericRelease',
          'Do you really want to close this Non-numeric release? The action is irreversible.',
        ),
      )

      if (confirmed) {
        await nnrOrderService.closeOrder({ id })
        appViewStore.setShowAlert(
          'success',
          t('orderClosedSuccessfully', 'Order closed successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToCloseOrder', 'Failed to close order'))
    }
  }

  const onClickExportNNROrder = async () => {
    apiRef.current?.exportDataAsCsv({
      fileName: `${shippingLine}-${referenceNumber}`,
    })
  }

  const onClickAssign = () => {
    drawerStore.showView(<AssignNNROrderContainer nnrOrder={nnrOrder} />)
  }

  return (
    <>
      {showAssignButton && (
        <Tooltip title={t('assignContainer', 'Assign Container')}>
          <Button size='large' color='primary' onClick={onClickAssign} variant='contained'>
            {t('assign', 'Assign')}
          </Button>
        </Tooltip>
      )}
      <Tooltip title={t('exportOrders', 'Export Orders')}>
        <Button
          startIcon={<DownloadIcon />}
          size='large'
          color='inherit'
          onClick={onClickExportNNROrder}
          variant='contained'
        >
          {t('export', 'Export')}
        </Button>
      </Tooltip>
      {!isClosed && (
        <ThreeDotMenu>
          <MenuItem onClick={handleOrderClose} sx={{ whiteSpace: 'nowrap' }}>
            {t('recordAsComplete', 'Record as complete')}
          </MenuItem>
        </ThreeDotMenu>
      )}
    </>
  )
})

export const NNROrderHeaderWithBreadcrumbs: FC<Props> = observer(
  ({ id, nnrOrder, orderLocalPaginationStore, apiRef }) => {
    const { t } = useTranslate()
    const navigate = useNavigate()

    const { referenceNumber, shippingLine } = nnrOrder

    const breadCrumbs = [
      {
        label: t('non-numericReleases', 'Non-numeric releases'),
        onClick: () => navigate('/nnr-orders'),
      },
      {
        label: `${referenceNumber}`,
      },
    ]

    return (
      <>
        <Breadcrumbs items={breadCrumbs} />
        <Header
          title={referenceNumber}
          subTitle={shippingLine}
          searchInputLabel={t('search', 'Search')}
          onSearchFieldChange={(filter: string) => orderLocalPaginationStore.setFilter(filter)}
          searchFilter={orderLocalPaginationStore.filter}
          displaySearchIcon
          enableSearchField
          rightRenderOption={<NNROrderHeaderOptions id={id} nnrOrder={nnrOrder} apiRef={apiRef} />}
        />
      </>
    )
  },
)
