import { RailcarTrackPositionResponseDto } from '@planning/app/api'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'
import { RailcarTrackPositionItemStore } from './RailcarTrackPositionItemStore'
import { RailTrackItem } from './RailTrackItemStore'

export interface IRailcarTrackPositionItem extends IEntity<RailcarTrackPositionResponseDto> {
  railTrack?: RailTrackItem
}

export interface PinInfo {
  length: number
  amount: number
}

export class RailcarTrackPositionItem extends EntityItem<RailcarTrackPositionResponseDto, number> {
  constructor(
    private itemStore: RailcarTrackPositionItemStore,
    id: number,
    data: RailcarTrackPositionResponseDto,
  ) {
    super(id, data)
    makeObservable(this, {
      railTrack: computed,
    })
  }

  get railTrack() {
    if (!this.data || !this.data.railTrackId || !this.itemStore || !this.itemStore.railTracksById) {
      return undefined
    }

    const railTracksById = this.itemStore.railTracksById

    if (this.data.railTrackId in railTracksById) {
      return railTracksById[this.data.railTrackId]
    }

    return undefined
  }
}
