import { useEffect, useState } from 'react'
import { FormStore } from './FormStore'
import { IFormStore, ISubFormStore } from './interfaces'

export const useRegisterSubFormStore = function <T, U extends ISubFormStore<any>>(
  formStore: IFormStore<T>,
  name: keyof T,
  store: U,
) {
  const [savedStore] = useState(store)
  useEffect(() => {
    formStore.register(name, savedStore)
    return () => {
      formStore.unregister(name)
    }
  }, [formStore, name, savedStore])

  return savedStore
}

export const useFormStore = <T>() => {
  const [formStore] = useState<IFormStore<T>>(new FormStore())
  return formStore
}
