import { CustomsResponseDto, CustomsStatus } from '@planning/app/api'
import {
  extractSubFormData,
  FieldStore,
  ISubFormStore,
  ListFieldStore,
  required,
  validateSubForm,
} from '@tom-ui/utils'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export type CustomsItemType = {
  reference: FieldStore<string>
  status: FieldStore<CustomsStatus>
  reason: FieldStore<string>
  selected: FieldStore<boolean>
}

const createCustomsItem = (value: CustomsResponseDto): CustomsItemType => ({
  reference: new FieldStore(value.reference, required()),
  status: new FieldStore(value.status),
  selected: new FieldStore<boolean>(false),
  reason: new FieldStore(value.reason ?? ''),
})

export class CustomsSubFormStore implements ISubFormStore<CustomsResponseDto[]> {
  items = new ListFieldStore<CustomsItemType>([], (val: CustomsItemType[]) => {
    const references = new Set<string>()

    val.filter(item => item.reference.isTouched)
    for (let index = 0; index < val.length; index++) {
      const reference = val[index].reference.value

      if (references.has(reference)) {
        return `duplicateValue ${reference}`
      } else {
        references.add(reference)
      }
    }
  })

  constructor(data: CustomsResponseDto[]) {
    makeObservable(this, {
      items: observable,
      addCustoms: action,
      deleteCustoms: action,
      setSelectAll: action,
      setSelectItem: action,
      updateSelectedCustoms: action,
      deleteSelectedCustoms: action,
      selectAllState: computed,
    })

    runInAction(() => {
      data.forEach(item => {
        this.items.push(createCustomsItem(item))
      })
    })
  }

  addCustoms = (value: CustomsResponseDto) => {
    this.items.push(createCustomsItem(value))
  }

  deleteCustoms = (index: number) => {
    this.items.removeByIndex(index)
  }

  setSelectAll = (value: boolean) => {
    this.items.value.forEach(item => {
      item.selected.setValue(value)
    })
  }

  setSelectItem = (index: number, value: boolean) => {
    const item = this.items.value.at(index)
    if (!item) return
    item.selected.setValue(value)
  }

  updateSelectedCustoms = (value: CustomsStatus) => {
    this.items.value.forEach(item => {
      if (item.selected.value) {
        item.status.setValue(value)
      }
    })
  }

  deleteSelectedCustoms = () => {
    this.items.removeByCondition(item => item.selected.value)
  }

  get selectAllState(): boolean | 'indeterminate' {
    if (
      this.items.value.map(item => item.selected.value).includes(true) &&
      this.items.value.map(item => item.selected.value).includes(false)
    )
      return 'indeterminate'

    return this.items.value.map(item => item.selected.value).every(state => state)
  }

  validate = () => {
    return validateSubForm(this)
  }

  get data() {
    return extractSubFormData(this).items
  }
}
