import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ContainerHeight, ContainerType, useAdminStore } from '@tom-ui/admin'
import { ContainerCharacteristics } from '@tom-ui/planning/app/api'
import { EditIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { IsoCodeMappingFormDialog } from 'modules/admin/src/pages/IsoCodeMapping/components/IsoCodeMappingForm'
import { FC, useCallback, useState } from 'react'
import { Controller, FieldErrors, FieldValues } from 'react-hook-form'
import {
  getContainerCharacteristicsByIsoCode,
  IControlledIsoCodeMappingInputProps,
} from './ControlledIsoCodeMappingInput'

const IsoCodeNotFoundMessage: FC<{
  isoCodeInput: string
}> = observer(({ isoCodeInput }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { isoCodeMappingUIStore } = useAdminStore()

  return (
    <Grid item xs={8} mt={theme.customSpacing.xxs}>
      <Box
        bgcolor={theme.palette.grey[200]}
        p={1}
        borderRadius={theme.customRadius.medium}
        color={theme.palette.grey[600]}
      >
        <Grid container>
          <Grid
            item
            xs={10}
            display={'flex'}
            sx={{
              alignItems: 'center',
            }}
          >
            {t('couldNotFindDetailsForThisIsoCode', 'Could not find details for this ISO code.')}
          </Grid>
          <Grid item xs={2} display={'flex'} sx={{ placeContent: 'end' }}>
            <Button
              variant='text'
              color='inherit'
              onClick={() =>
                isoCodeMappingUIStore.toggleFormDialog(true, {
                  isoCode: isoCodeInput,
                  height: ContainerHeight.Standard,
                  type: ContainerType.General,
                })
              }
            >
              {t('addNewMapping', 'Add new mapping')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
})

const ContainerCharacteristicsInfo: FC<{
  isoCode: string
  containerCharacteristics: ContainerCharacteristics
}> = observer(({ isoCode, containerCharacteristics }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { isoCodeMappingUIStore } = useAdminStore()

  return (
    <Grid item xs={8} mt={theme.customSpacing.xxs}>
      <Box
        bgcolor={theme.palette.grey[200]}
        borderRadius={theme.customRadius.medium}
        color={theme.palette.grey[600]}
        height={'100%'}
        sx={{
          padding: `${theme.customSpacing.xxs} ${theme.customSpacing.s}`,
        }}
      >
        <Grid height={'100%'} container>
          <Grid height={'100%'} item xs={11}>
            <Grid height={'100%'} container>
              <ContainerCharacteristicsAttribute
                size={1}
                label={t('length', 'Length')}
                value={
                  containerCharacteristics.length
                    ? `${containerCharacteristics.length}ft`
                    : t('unknown', 'Unknown')
                }
              />
              <ContainerCharacteristicsAttribute
                size={1}
                label={t('height', 'Height')}
                value={
                  containerCharacteristics.height
                    ? containerCharacteristics.height
                    : t('unknown', 'Unknown')
                }
              />
              <ContainerCharacteristicsAttribute
                size={10}
                label={t('type', 'Type')}
                value={
                  containerCharacteristics.type
                    ? containerCharacteristics.type
                    : t('unknown', 'Unknown')
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            sx={{ alignItems: 'end', placeContent: 'center' }}
          >
            <IconButton
              aria-label='edit'
              onClick={() =>
                isoCodeMappingUIStore.toggleFormDialog(true, {
                  id: containerCharacteristics.id ?? undefined,
                  isoCode: isoCode,
                  height: containerCharacteristics.height ?? ContainerHeight.Standard,
                  type: containerCharacteristics.type ?? ContainerType.General,
                  length: containerCharacteristics.length ?? undefined,
                })
              }
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
})

interface AttributeProps {
  label: string
  value: string | number
  size: number
}

const ContainerCharacteristicsAttribute: FC<AttributeProps> = ({ label, value, size }) => {
  return (
    <Grid
      item
      xs={size}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      sx={{ alignItems: 'start', placeContent: 'center' }}
    >
      <Typography variant='body2'>{label}</Typography>
      <Typography variant='body2' fontWeight={'bold'}>
        {value}
      </Typography>
    </Grid>
  )
}

export const ControlledIsoCodeMappingInputWithDetails = <T extends FieldValues>(
  props: IControlledIsoCodeMappingInputProps<T>,
) => {
  const { t } = useTranslate()

  const { isoCodeMappingUIStore } = useAdminStore()

  const {
    name,
    control,
    label,
    fullWidth,
    disabled,
    variant,
    type,
    formState,
    required,
    setValue,
    watch,
  } = props
  const { errors } = formState
  const isoCodeInput = watch(name)

  const [isIsoCodeValid, setIsIsoCodeValid] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [containerCharacteristics, setContainerCharacteristics] =
    useState<ContainerCharacteristics>({})

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.isoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'minLength' || errorType === 'maxLength')
        return t(
          'isoCodeConsistsOf4AlphanumericCharacters',
          'ISO Codes consists of 4 alphanumeric characters.',
        )

      return isoCodeError.message?.toString()
    }
  }

  const fetchContainerCharacteristicsByIsoCode = useCallback(async (isoCode?: string) => {
    if (isoCode?.length === 4) {
      try {
        setIsLoading(true)

        const characteristics = await getContainerCharacteristicsByIsoCode(isoCode)
        const isValid = !!characteristics

        setContainerCharacteristics(characteristics)
        setIsIsoCodeValid(isValid)
      } catch (e) {
        setContainerCharacteristics({})
        setIsIsoCodeValid(false)
      } finally {
        setIsLoading(false)
      }
    } else {
      setContainerCharacteristics({})
      setIsIsoCodeValid(false)
    }
  }, [])

  useAsyncFetch(async () => {
    await fetchContainerCharacteristicsByIsoCode(isoCodeInput)
  }, [fetchContainerCharacteristicsByIsoCode, isoCodeInput])

  return (
    <>
      <Grid container spacing={1} paddingTop={0.2}>
        <Grid item xs={4}>
          <Controller
            rules={{ required, minLength: 4, maxLength: 4, validate: () => isIsoCodeValid }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors[name]}
                helperText={containerIsoCodeErrorText(errors)}
                label={label}
                type={type}
                fullWidth={fullWidth}
                disabled={disabled}
                required={required}
                variant={variant ?? 'outlined'}
                onChange={async event => {
                  event.target.value = event.target.value.toUpperCase()
                  field.onChange(event)
                }}
                inputProps={{
                  maxLength: 4,
                }}
                InputProps={{
                  endAdornment: <>{isLoading && <CircularProgress size={20} />}</>,
                }}
              />
            )}
            name={name}
            control={control}
          />
        </Grid>

        {!isLoading && !isIsoCodeValid && isoCodeInput?.length === 4 && (
          <IsoCodeNotFoundMessage isoCodeInput={isoCodeInput} />
        )}

        {!isLoading && isIsoCodeValid && isoCodeInput?.length === 4 && (
          <ContainerCharacteristicsInfo
            isoCode={isoCodeInput}
            containerCharacteristics={containerCharacteristics}
          />
        )}
      </Grid>

      <IsoCodeMappingFormDialog
        viewStore={isoCodeMappingUIStore}
        onFilter={getContainerCharacteristicsByIsoCode}
        onSubmitFn={(id, data) => {
          if (data.isoCode) setValue?.(data.isoCode)
          setContainerCharacteristics({ ...data, id })
          setIsIsoCodeValid(true)
        }}
      />
    </>
  )
}
