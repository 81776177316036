import { ContainerResponseDto, UnitType } from '@planning/app/api'
import { containerService } from '@planning/services'
import { debounce } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

export class ContainerMasterDataListViewStore {
  data: ContainerResponseDto[] = []
  page = 1
  pageSize = 20
  totalCount = 0
  hasNextPage = true

  filter = ''
  isLoading = false

  constructor() {
    makeObservable(this, {
      data: observable,
      page: observable,
      pageSize: observable,
      totalCount: observable,
      filter: observable,
      isLoading: observable,

      setFilter: action,
      setIsLoading: action,
      setTotalCount: action,
      setHasNextPage: action,
      setPage: action,
      showMore: action,
    })
  }

  fetch = async (page: number, limit: number, filter?: string) => {
    try {
      this.setIsLoading(true)
      const response = await containerService.get(page, limit, filter, UnitType.Container)
      this.setTotalCount(response.totalCount)
      this.setHasNextPage(response.hasNextPage)
      this.upsertData(response.payload)
    } finally {
      this.setIsLoading(false)
    }
  }

  showMore = async () => {
    const nextPage = this.page + 1

    await this.fetch(nextPage, this.pageSize, this.filter)
    this.setPage(nextPage)
  }

  onFilter = debounce(async (value: string) => {
    this.setFilter(value.toUpperCase())
    this.clearData()

    await this.fetch(1, this.pageSize, this.filter)
  }, 500)

  upsertData = (data: ContainerResponseDto[]) => {
    if (!data.length) return

    const idsFromUpdate = data.map(d => d.id)
    this.data = [...this.data.filter(d => !idsFromUpdate.includes(d.id)), ...data]
  }

  clearData = () => (this.data = [])

  setPage = (page: number) => (this.page = page)

  setFilter = (filter: string) => (this.filter = filter)

  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

  setTotalCount = (totalCount: number) => (this.totalCount = totalCount)

  setHasNextPage = (hasNextPage: boolean) => (this.hasNextPage = hasNextPage)
}
