import { action, makeObservable, observable } from 'mobx'
import { IIssueItem } from './IssueItem'

export class IssueResolutionViewStore {
  isOpen = false
  issueToBeResolved?: IIssueItem

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      issueToBeResolved: observable,
      openDialog: action,
      closeDialog: action,
    })
  }

  openDialog = (issue: IIssueItem) => {
    this.issueToBeResolved = issue
    this.isOpen = true
  }

  closeDialog = () => {
    this.isOpen = false
    this.issueToBeResolved = undefined
  }
}
