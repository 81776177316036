import { Box, Paper, Radio, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useMinimalsTheme } from '../../../index'
import { FieldboxSelectionGroupController } from '../fieldboxSelectionGroup/FieldboxSelectionGroup'

interface IProps {
  selectionKey: string
  selectedKey?: string
  label: string
  description?: string
  children?: React.ReactNode
  onSelect?: (key: string) => void
  controller?: FieldboxSelectionGroupController
  dataCy?: string
}

export const FieldboxWithRadio: FC<IProps> = ({
  selectionKey,
  selectedKey,
  label,
  description,
  children,
  onSelect,
  controller,
  dataCy,
}) => {
  const theme = useMinimalsTheme()
  const isSelected = (controller?.selectedKey ?? selectedKey) === selectionKey

  const handleSelect = () => {
    onSelect?.(selectionKey)
    controller?.handleSelect(selectionKey)
  }

  return (
    <Paper
      variant='outlined'
      sx={{
        backgroundColor: isSelected ? theme.palette.primary.lighter : 'transparent',
        width: '100%',
        cursor: 'pointer',
        padding: theme.customSpacing.m,
        marginBottom: 2,
      }}
      onClick={handleSelect}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <Radio
          data-cy={dataCy}
          checked={isSelected}
          onChange={handleSelect}
          value={selectionKey}
          onClick={e => e.stopPropagation()}
        />
        <Typography variant='body1'>{label}</Typography>
      </Stack>
      {description && (
        <Typography variant='caption' color='secondary'>
          {description}
        </Typography>
      )}

      {isSelected && children && <Box mt={2}>{children}</Box>}
    </Paper>
  )
}
