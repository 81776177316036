import { Box, Button, DialogActions } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { ColorSchema } from '../../palette'
import { LoaderButton } from '../loaderButton/LoaderButton'

export interface ConfirmationProps {
  formId?: string

  isLoading?: boolean

  primaryActionText: string
  primaryActionDisabled?: boolean
  primaryActionDataCy?: string

  secondaryActionColor?: ColorSchema
  secondaryActionText?: string
  secondaryActionDisabled?: boolean
  secondaryType?: 'button' | 'submit'

  closeLabel: string

  mobile?: boolean
  hasBorderTop?: boolean
  rightRenderOption?: ReactNode

  onClose: () => void
  onConfirm?: () => void
  onSecondaryAction?: () => void
}

export const ConfirmationActions = observer(
  ({
    formId,
    isLoading,
    primaryActionText,
    primaryActionDisabled,
    primaryActionDataCy,
    secondaryActionText,
    secondaryActionColor,
    secondaryActionDisabled,
    secondaryType,
    closeLabel,
    mobile,
    hasBorderTop,
    rightRenderOption,
    onClose,
    onConfirm,
    onSecondaryAction,
  }: ConfirmationProps) => {
    return (
      <DialogActions
        sx={{ borderTop: hasBorderTop ? '1px solid rgba(145, 158, 171, 0.24)' : undefined }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <LoaderButton
            isLoading={!!isLoading}
            variant={mobile ? 'text' : 'contained'}
            color={mobile ? 'inherit' : 'primary'}
            type='submit'
            formId={formId}
            onClick={onConfirm}
            disabled={primaryActionDisabled}
            dataCy={primaryActionDataCy}
          >
            {primaryActionText ?? 'Confirm'}
          </LoaderButton>
          {!!onSecondaryAction && secondaryActionText && (
            <Button
              variant={mobile ? 'text' : 'outlined'}
              color={secondaryActionColor ?? 'secondary'}
              type={secondaryType ?? 'button'}
              form={formId}
              onClick={onSecondaryAction}
              disabled={secondaryActionDisabled}
            >
              {secondaryActionText}
            </Button>
          )}
          <Button variant='text' onClick={onClose} color='inherit'>
            {closeLabel ?? 'Cancel'}
          </Button>
        </Box>
        {rightRenderOption}
      </DialogActions>
    )
  },
)
