import { SelectChangeEvent } from '@mui/material'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { ChangeEvent } from 'react'
import { ISubFormStore } from './interfaces'

export class FieldStore<T> implements ISubFormStore<T> {
  readonly initialValue: T
  value: T
  isTouched = false
  error?: string

  constructor(
    value: T,
    private validator?: (val: T) => string | undefined,
  ) {
    this.value = value
    this.initialValue = value
    makeObservable(this, {
      value: observable,
      isTouched: observable,
      error: observable,
      setValue: action,
      touch: action,
      unTouch: action,
      validate: action,
      clear: action,
      setError: action,
      dirty: computed,
      props: computed,
    })
  }

  onChange = (event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    if (!event) {
      this.setValue(this.initialValue)
    } else if (typeof this.initialValue === 'number') {
      const isNumber = !isNaN(parseInt(event.target.value))
      if (isNumber) {
        this.setValue(parseInt(event.target.value) as T)
      }
    } else this.setValue(event.target.value as T)
  }

  setValue = (value: T) => {
    this.value = value
    this.error = undefined
  }

  setError = (error: string) => {
    this.error = error
  }

  get dirty() {
    return this.value !== this.initialValue
  }

  touch = () => {
    this.isTouched = true
  }

  unTouch = () => {
    this.isTouched = false
  }

  validate = () => {
    if (this.validator) {
      const error = this.validator(this.value)
      this.error = error
      return error === undefined
    }
    return true
  }

  clear = () => {
    this.value = this.initialValue
    this.error = undefined
    this.isTouched = false
  }

  get data() {
    return toJS(this.value)
  }

  get props() {
    return {
      value: this.value,
      onChange: this.onChange,
      error: !!this.error,
      onBlur: () => this.touch(),
    }
  }
}
