import { IssueType, OrderIssueResolutionType } from '@planning/app/api'
import { FieldboxWithRadio } from '@planning/components/atoms/FieldboxWithRadio'
import { FieldboxSelectionGroup } from '@planning/components/molecules/FieldboxSelectionGroup'
import { RegisteredChangeTransportSubForm } from '@planning/components/organisms/ChangeTransportSubForm'
import { CustomsSubForm } from '@planning/components/organisms/CustomsSubForm'
import { useTranslate } from '@tolgee/react'
import { FieldStore, IFormStore, required, useRegisterSubFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { IssueResolutionViewStore } from '../../Stores/IssueResolutionViewStore'
import { ResolveIssuesFormData } from '../templates/ResolveIssueDialog'

interface IProps {
  store: IssueResolutionViewStore
  formStore: IFormStore<ResolveIssuesFormData>
}

const customsIssueTypes = new Set<IssueType>([
  IssueType.CustomsPending,
  IssueType.CustomsRefused,
  IssueType.CustomsInspectionRequired,
])

export const ResolveIssuesFormList = observer(({ store, formStore }: IProps) => {
  const { t } = useTranslate()
  const selectedIssueResolutionStore = useRegisterSubFormStore(
    formStore,
    'selectedIssueResolution',
    new FieldStore<string>(OrderIssueResolutionType.FollowUp, required()),
  )

  const issueType = store.issueToBeResolved?.data.issueType

  if (!issueType) return <></>

  return (
    <FieldboxSelectionGroup
      onSelect={selectedIssueResolutionStore.setValue}
      defaultValue={OrderIssueResolutionType.FollowUp}
      options={controller => (
        <>
          {issueType === IssueType.MissingRailcar && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ChangeOutboundTransport}
              controller={controller}
              label={t('changeOutbountTranport', 'Change outbound transport')}
            >
              <RegisteredChangeTransportSubForm formStore={formStore} name={'changeTransport'} />
            </FieldboxWithRadio>
          )}
          {issueType === IssueType.Shortlanded && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ChangeInboundTransport}
              controller={controller}
              label={t('changeInbountTranport', 'Change inbound transport')}
            >
              <RegisteredChangeTransportSubForm formStore={formStore} name={'changeTransport'} />
            </FieldboxWithRadio>
          )}
          {issueType === IssueType.MissingRailcar ||
            (issueType === IssueType.Shortlanded && (
              <FieldboxWithRadio
                selectionKey={OrderIssueResolutionType.DeleteOrder}
                controller={controller}
                label={t('removeOrder', 'Remove order')}
              />
            ))}
          {issueType === IssueType.MissingRailcar && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.RailcarFound}
              controller={controller}
              label={t('railcarWasFound', 'Railcar was found')}
            />
          )}
          {issueType === IssueType.Shortlanded && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ContainerFound}
              controller={controller}
              label={t('containerFound', 'Container found on original railcar')}
            />
          )}
          {customsIssueTypes.has(issueType) && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.UpdateCustomsStatus}
              controller={controller}
              label={t('updateCustomsStatus', 'Update customs status’')}
            >
              <CustomsSubForm
                formStore={formStore}
                name={'customs'}
                initialValue={
                  store.issueToBeResolved?.containerJourney?.outboundOrder?.order?.customs
                }
              />
            </FieldboxWithRadio>
          )}
          <FieldboxWithRadio
            selectionKey={OrderIssueResolutionType.FollowUp}
            controller={controller}
            label={t('markForFollowUp', 'Mark for follow up')}
          />
        </>
      )}
    />
  )
})
