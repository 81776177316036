import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Grid, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export interface ContainerSummary {
  expected: number
  completed: number
}

interface Props {
  containersIn: ContainerSummary
  containersOut: ContainerSummary
  dataCy?: string
}
export const ContainerAggregation = observer(({ containersIn, containersOut, dataCy }: Props) => {
  const { t } = useTranslate()

  const getContainerAmount = (summary: ContainerSummary, direction: 'inbound' | 'outbound') => (
    <Stack flexDirection='row' gap={1} divider={<Typography variant='h3'>/</Typography>}>
      <Typography variant='h3' data-cy={`${dataCy}-${direction}-completed`}>
        {summary.completed}
      </Typography>
      <Typography variant='h3' data-cy={`${dataCy}-${direction}-expected`}>
        {summary.expected}
      </Typography>
    </Stack>
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4'>{t('containers', 'Containers')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Stack p={4} justifyItems='center' alignItems='center'>
            <ArrowDownward />
            {getContainerAmount(containersIn, 'inbound')}

            <Typography variant='body2' color='secondary'>
              {t('in', 'In')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Stack p={4} justifyItems='center' alignItems='center'>
            <ArrowUpward />
            {getContainerAmount(containersOut, 'outbound')}

            <Typography variant='body2' color='secondary'>
              {t('out', 'Out')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
})
