import { ContainerHeight, ContainerType, IsoCodeMappingResponseDto } from '@admin/app/api'
import { useAdminStore } from '@admin/AppProvider'
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { useContainerTypeParameters } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form'
import { IsoCodeMappingUIStore } from '../stores/iso-code-mapping.ui-store'

interface ContainerCharacteristics {
  isoCode?: string
  length?: number | null
  height?: ContainerHeight
  type?: ContainerType
}

interface Props {
  viewStore: IsoCodeMappingUIStore
  onFilter: (filter: string) => Promise<ContainerCharacteristics | null>
  onSubmitFn?: (id: number, data: ContainerCharacteristics) => void
}

export const IsoCodeMappingFormDialog = observer(({ viewStore, onFilter, onSubmitFn }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appStore } = useAdminStore()

  const containerTypeParameters = useContainerTypeParameters()

  const containerTypes = useMemo(
    () =>
      Object.entries(containerTypeParameters)
        .map(([key, value]) => ({ key, label: value.label, icon: value.icon }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [containerTypeParameters],
  )
  const containerHeights = useMemo(() => Object.values(ContainerHeight), [])

  const [isIsoCodeStandard, setIsIsoCodeStandard] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isEditMode = !!viewStore.dataToBeEdited?.id
  const title = isEditMode
    ? t('editIsoCodeMapping', 'Edit ISO Code')
    : t('addNewIsoCodeMapping', 'Add new ISO Code')

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
  } = useForm<IsoCodeMappingResponseDto>()

  const isoCodeInput = watch('isoCode')

  const resetForm = useCallback(() => {
    reset({
      isoCode: '',
      height: ContainerHeight.Standard,
      type: ContainerType.General,
    })
    setIsIsoCodeStandard(false)
  }, [reset])

  const getContainerCharacteristicsByIsoCode = useCallback(
    async (isoCode: string) => {
      try {
        setIsLoading(true)
        const filteredIsoCode = await onFilter(isoCode)
        const isStandard = !!filteredIsoCode
        if (isStandard) {
          setValue('length', filteredIsoCode.length ?? 0)
          setValue('height', filteredIsoCode.height ?? ContainerHeight.Standard)
          setValue('type', filteredIsoCode.type ?? ContainerType.General)
          setIsIsoCodeStandard(isStandard)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [onFilter, setValue],
  )

  const onSubmit: SubmitHandler<IsoCodeMappingResponseDto> = async data => {
    const id = await viewStore.onSubmit(data)
    appStore.setShowAlert(
      'success',
      `ISO Code ${data.isoCode} ${viewStore.dataToBeEdited ? 'updated' : 'created'}`,
    )
    handleDialogClose()
    onSubmitFn?.(id, data)
  }

  const handleDialogClose = () => {
    viewStore.dataToBeEdited = undefined
    viewStore.toggleFormDialog(false)
    resetForm()
  }

  const handleDelete = async () => {
    if (viewStore.dataToBeEdited?.id) {
      await viewStore.onDelete(viewStore.dataToBeEdited.id, viewStore.dataToBeEdited.isoCode)
      appStore.setShowAlert('success', `ISO Code ${viewStore.dataToBeEdited.isoCode} deleted`)
      handleDialogClose()
    }
  }

  const handleFormSubmit = async () => {
    const isValid = await trigger()
    if (isValid) {
      const formData = getValues()
      onSubmit(formData)
    }
  }

  const getLengthErrorText = useCallback(
    (errors: FieldErrors) => {
      if (errors.length?.type === 'required') {
        return t('fieldIsRequired', 'Field is required.')
      }
      if (errors.length?.type === 'min') {
        return t('lengthMustBeGreaterThan0', 'Length must be greater than 0.')
      }
      return ''
    },
    [t],
  )

  useEffect(() => {
    if (viewStore.dataToBeEdited) {
      reset({
        id: viewStore.dataToBeEdited.id,
        isoCode: viewStore.dataToBeEdited.isoCode,
        height: viewStore.dataToBeEdited.height,
        length: viewStore.dataToBeEdited.length,
        type: viewStore.dataToBeEdited.type,
      })

      if (!viewStore.dataToBeEdited.id)
        getContainerCharacteristicsByIsoCode(viewStore.dataToBeEdited.isoCode)
    } else {
      resetForm()
    }
  }, [viewStore.dataToBeEdited, resetForm, reset, getContainerCharacteristicsByIsoCode])

  return (
    <>
      <ConfirmationDialog
        open={viewStore.isDeleteDialogOpen}
        title={t('confirmDelete', 'Confirm Delete')}
        message={t(
          'confirmDeleteIsoCodeMapping',
          'ISO Code will be deleted and you cannot undo this action!',
        )}
        primaryActionText={t('Delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        onConfirm={handleDelete}
        onClose={() => viewStore.toggleDeleteDialog(false)}
      />

      <Dialog fullWidth maxWidth='md' open={viewStore.isFormDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <form>
          <DialogContent>
            <Grid container spacing={1} paddingTop={0.2}>
              <Grid item xs={12}>
                <Controller
                  name='isoCode'
                  control={control}
                  rules={{ required: true, maxLength: 4 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${t('isoCode', 'ISO Code')} *`}
                      variant='outlined'
                      fullWidth
                      type='text'
                      onChange={async event => {
                        event.target.value = event.target.value.toUpperCase()
                        field.onChange(event)

                        const isoCode = event.target.value

                        if (isoCode.length === 4) {
                          getContainerCharacteristicsByIsoCode(isoCode)
                        } else {
                          setIsIsoCodeStandard(false)
                        }
                      }}
                      InputProps={{
                        endAdornment: <>{isLoading && <CircularProgress size={20} />}</>,
                        readOnly: isEditMode,
                      }}
                      inputProps={{ maxLength: 4, style: { textTransform: 'uppercase' } }}
                      error={!!errors.isoCode}
                      helperText={errors.isoCode ? t('fieldIsRequired', 'Field is required.') : ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {!isLoading && isIsoCodeStandard && isoCodeInput?.length === 4 && (
                  <Alert sx={{ marginBottom: theme.customSpacing.s }} severity='warning'>
                    {t(
                      'youAreAboutToChangeStandardIsoCode',
                      'You are about to change a standard ISO code.',
                    )}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='length'
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!isLoading && isIsoCodeStandard && isoCodeInput?.length === 4}
                      label={`${t('length', 'Length')} *`}
                      variant='outlined'
                      fullWidth
                      type='text'
                      error={!!errors.length}
                      helperText={getLengthErrorText(errors)}
                      value={field.value ?? ''}
                      InputProps={{
                        endAdornment: (
                          <CustomInputAdornment position='end' color='secondary'>
                            ft
                          </CustomInputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={`height`}
                  render={({ field: { onChange } }) => (
                    <FormControl fullWidth>
                      <InputLabel sx={{ verticalAlign: 'center' }}>
                        {`${t('height', 'Height')} *`}
                      </InputLabel>
                      <Select
                        label={t('height', 'Height')}
                        value={getValues(`height`)?.toString() ?? ''}
                        onChange={(e: any) => {
                          onChange(e)
                        }}
                      >
                        {containerHeights.map(height => {
                          return (
                            <MenuItem key={height} value={height}>
                              {height}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`type`}
                  render={({ field: { onChange } }) => (
                    <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                      <InputLabel
                        sx={{ verticalAlign: 'center' }}
                      >{`${t('type', 'Type')} *`}</InputLabel>
                      <Select
                        label={t('type', 'Type')}
                        value={getValues(`type`)?.toString() ?? ''}
                        onChange={(e: any) => {
                          onChange(e)
                        }}
                      >
                        {containerTypes.map(type => {
                          return (
                            <MenuItem key={type.key} value={type.key}>
                              {type.label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{
              mt: theme.customSpacing.l,
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Stack direction={'row'} spacing={2}>
              <Button onClick={handleFormSubmit} variant='contained' color='primary'>
                {t('save', 'Save')}
              </Button>
              <Button onClick={handleDialogClose} color='secondary'>
                {t('cancel', 'Cancel')}
              </Button>
            </Stack>
            {viewStore.dataToBeEdited && (
              <IconButton onClick={() => viewStore.toggleDeleteDialog(true)} color='secondary'>
                <BinIcon />
              </IconButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
})
