import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CarrierVisitDirection, CheckType } from '@planning/app/api'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../Stores/RailTallyViewStore'
import { IStep, IStepItemProps, TallySelectionStepper } from './TallySelectionStepper'

export interface IRailSelectionStepperProps {
  store: RailTallyViewStore
  skipRailTallyLoadPreparation?: boolean
  onSelectTrainOperation: (operation: CheckType) => void
}

export const RailSelectionStepper: FC<IRailSelectionStepperProps> = observer(
  ({ store, skipRailTallyLoadPreparation, onSelectTrainOperation }) => {
    const { t } = useTranslate()

    const enableLoadPrepAndDepartureCheck = useBooleanFlagValue(
      'rail-load-prep-and-departure-check',
      false,
    )

    const getOperationItems = () => {
      const items: IStepItemProps[] = []

      if (!store.selectedVisit) return items

      if (store.selectedVisit.orders.some(o => o.data.direction === CarrierVisitDirection.Inbound))
        items.push({
          label: t('dischargePreparation', 'Discharge preparation'),
          onSelect: () => {
            onSelectTrainOperation(CheckType.DischargePreparation)
            store.selectVisitOrders(store.selectedVisit!.orders ?? [])
          },
          testId: 'discharge-preparation-item',
        })
      if (
        enableLoadPrepAndDepartureCheck &&
        store.selectedVisit.orders.some(o => o.data.direction === CarrierVisitDirection.Outbound)
      ) {
        if (!skipRailTallyLoadPreparation)
          items.push({
            label: t('loadPreparation', 'Load preparation'),
            onSelect: () => onSelectTrainOperation(CheckType.LoadPreparation),
            testId: 'load-preparation-item',
          })

        items.push({
          label: t('departureCheck', 'Departure check'),
          onSelect: () => {
            onSelectTrainOperation(CheckType.DepartureCheck)
            store.selectVisitOrders(store.selectedVisit!.orders ?? [])
          },
          testId: 'departure-check-item',
        })
      }

      return items
    }

    const steps: IStep[] = [
      {
        label: t('trackSelection', 'Track selection'),
        items: store.tracks.map(track => ({
          label: track?.data.name ?? '',
          onSelect: () => store.selectTrack(track),
          testId: `tally-select-rail-track-${track?.data.name}`,
        })),
      },
      {
        label: t('railVisitSelection', 'Rail visit selection'),
        items:
          store.railVisitsOnSelectedTrack?.map(rv => ({
            label: `${rv.data.name} ${formatDateTime(rv.data.ata)}`,
            onSelect: () => store.selectVisit(rv),
            testId: `tally-select-rail-visit-${rv.data.name}`,
          })) ?? [],
      },
      {
        label: t('operationSelection', 'Operation selection'),
        items: getOperationItems(),
      },
    ]

    return <TallySelectionStepper store={store} steps={steps} />
  },
)
