import { CarrierType, RailVisitResponseDto, VesselVisitDto } from '@planning/app/api'
import { railVisitService } from '@planning/services'
import { FieldStore, ISubFormStore, required, validateSubForm } from '@tom-ui/utils'
import { action, makeObservable, observable, runInAction } from 'mobx'

export type ChangeTransportSubFormType = {
  carrierType?: CarrierType
  carrierVisitId?: number
  railTrackId?: string
  railcarId?: number
  releaseExpires?: string
}

export class ChangeTransportSubFormStore implements ISubFormStore<ChangeTransportSubFormType> {
  carrierType: FieldStore<string>
  carrierVisitId: FieldStore<number>
  railTrackId: FieldStore<string>
  railcarId: FieldStore<number>
  releaseExpires: FieldStore<string>

  showDatetimeForPickUp = false
  selectedRailVisit?: RailVisitResponseDto
  selectedVesselVisit?: VesselVisitDto

  constructor(initialValues?: Partial<ChangeTransportSubFormType>) {
    makeObservable(this, {
      showDatetimeForPickUp: observable,
      selectedRailVisit: observable,
      selectedVesselVisit: observable,
      setCarrierType: action,
      setShowDatetimeForPickUp: action,
      setSelectedRailVisit: action,
      setSelectedVesselVisit: action,
    })

    this.carrierType = new FieldStore<string>(initialValues?.carrierType ?? '')
    this.carrierVisitId = new FieldStore<number>(initialValues?.carrierVisitId ?? 0, required())
    this.railTrackId = new FieldStore<string>(initialValues?.railTrackId ?? '', required())
    this.railcarId = new FieldStore<number>(initialValues?.railcarId ?? 0, required())
    this.releaseExpires = new FieldStore<string>(initialValues?.releaseExpires ?? '')

    this.carrierType.touch()

    if (initialValues?.carrierType === CarrierType.Train && initialValues?.carrierVisitId) {
      this.loadRailVisit(initialValues.carrierVisitId)
    }
  }

  loadRailVisit = async (visitId: number) => {
    const visit = await railVisitService.getById(visitId)
    runInAction(() => {
      this.selectedRailVisit = visit
    })
  }

  validate = () => {
    const result = validateSubForm(this, true)
    console.log('validate', result)
    return result
  }

  setCarrierType = (value: string) => {
    this.carrierVisitId.clear()
    this.railTrackId.clear()
    this.railcarId.clear()
    this.carrierType.setValue(value)

    switch (value) {
      case CarrierType.Train:
        this.carrierVisitId.touch()
        this.railTrackId.touch()
        this.railcarId.touch()
        break
      case CarrierType.Vessel:
        this.carrierVisitId.touch()
        break
    }
  }

  setShowDatetimeForPickUp = (value: boolean) => {
    this.showDatetimeForPickUp = value
    if (value) {
      this.releaseExpires.touch()
    } else {
      this.releaseExpires.unTouch()
    }
  }

  setSelectedRailVisit = (value: RailVisitResponseDto | undefined) => {
    this.selectedRailVisit = value
  }

  setSelectedVesselVisit = (value: VesselVisitDto | undefined) => {
    this.selectedVesselVisit = value
  }

  get data() {
    switch (this.carrierType.value) {
      case CarrierType.Vessel:
        return {
          carrierType: this.carrierType.value as CarrierType,
          carrierVisitId: this.carrierVisitId.value,
        }
      case CarrierType.Train:
        return {
          carrierType: this.carrierType.value as CarrierType,
          carrierVisitId: this.carrierVisitId.value,
          railTrackId: this.railTrackId.value,
          railcarId: this.railcarId.value,
        }
      case CarrierType.Truck:
        return {
          carrierType: this.carrierType.value as CarrierType,
          releaseExpires: this.releaseExpires.value,
        }
    }
    return {
      carrierType: this.carrierType.value as CarrierType,
      carrierVisitId: this.carrierVisitId.value,
      railTrackId: this.railTrackId.value,
      railcarId: this.railcarId.value,
      releaseExpires: this.releaseExpires.value,
    }
  }
}
