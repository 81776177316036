import { CarrierType, ContainerPositionType, OperationType } from '@operations/app/api'

export interface JobStep {
  step: JobSteps
  active?: boolean
  isOptional?: boolean
  canBeOptional?: boolean
  stepNumber: number
}

export enum JobSteps {
  selectContainer,
  editDestination,
  plugReefer,
  assignEquipment,
  confirmPickup,
  checkInformation,
  confirmWagonNumber,
  confirmWeight,
  restowDestination,
  directLoad,
}

export interface CreateJobStepperProps {
  operationType: OperationType
  toType: ContainerPositionType
  fromType: ContainerPositionType
  cargoUnitDisplayName?: string | null
  cargoUnitId?: number
  carrierVisitId?: number
  carrierVisitType?: CarrierType
  isEmpty?: boolean
  hasCoolingOrder?: boolean
  currentStep?: JobStep
  doesTrailerNeedRailcarSelection?: boolean
}

export enum DirectLoadOption {
  inbound = 'Inbound',
  outbound = 'Outbound',
}
