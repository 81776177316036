import { Box, Stack, Typography } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { DeleteIconButton } from '@planning/components/atoms/DeleteIconButton'
import { MultiselectCheckbox } from '@planning/components/atoms/MultiselectCheckbox'
import { NonPrimaryButton } from '@planning/components/atoms/NonPrimaryButton'
import { MultiselectActionBar } from '@planning/components/molecules/MultiselectActionBar'
import { OrderListItem } from '@planning/components/organisms/OrderListItem'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { OrderListViewStore } from '../../stores/OrderListViewStore'
import { AddCustomersReferencesDialog } from './AddCustomersReferencesDialog'
import { HeaderWithBreadcrumbs } from './HeaderWithBreadcrumbs'

export const OrdersList: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { appViewStore, orderItemStore } = usePlanningStore()

  const store = useMemo(() => new OrderListViewStore(orderItemStore), [orderItemStore])

  const { navigateBookingOrderPage } = useOrderNavigation()
  const navigate = useNavigate()

  const { bookingNumber, visitId } = useParams()

  const { loading, error } = useAsyncFetch(async () => {
    if (bookingNumber) await store.fetch(bookingNumber, Number(visitId))
  }, [store, bookingNumber, visitId])

  if (error) {
    return <Typography variant='h4'>{t('errorLoadingOrders', 'Error loading orders')}</Typography>
  }

  if (!loading && store.isEmpty) {
    navigate('/bookings')
  }

  const onDeleteOrder = async (orders: OrderResponseDto[]) => {
    if (orders.length === 0) return

    const hasServiceOrders = orders.some(o => o.hasServiceOrders)

    const content =
      orders.length === 1
        ? `${orders[0].containerNumber} ${t(
            'willBeDeletedAndYouCannotUndoThisAction',
            'will be deleted and you cannot undo this action',
          )}!`
        : `${t(
            'allSelectedOrdersWillBeDeletedAndYouCannotUndoThisAction',
            'All selected orders will be deleted and you cannot undo this action',
          )}!`

    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      content,
      `${t('deleteOrder', 'Delete order')}?`,
      hasServiceOrders
        ? t(
            'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
            'Any associated stripping/stuffing orders will be deleted',
          )
        : undefined,
    )

    if (!isConfirmed) return

    try {
      await orderService.deleteByIds(orders.map(o => o.id))

      appViewStore.setShowAlert(
        'success',
        t('successfullyDeletedOrder', 'Successfully deleted order'),
      )
    } catch (e) {
      appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
    }
  }

  const actions = [
    <NonPrimaryButton
      key={'add-customs-references'}
      text={t('addCustomsReferences', 'Add customs references')}
      onClick={() => store.openCustomsDialog()}
      size='small'
    />,
  ]

  const secondaryActions = [
    <DeleteIconButton
      key={'deleted'}
      onClick={() => {
        const orders = [...store.selectedOrderIds.keys()].map(
          key => orderItemStore.elements[key].data,
        )
        onDeleteOrder(orders)
      }}
    />,
  ]

  if (!bookingNumber) return <></>

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box sx={{ backgroundColor: theme.palette.grey[200], minHeight: '100%' }}>
        <HeaderWithBreadcrumbs bookingNumber={bookingNumber} store={store} />
        {!store.isNoResults && (
          <>
            <Box m={theme.customSpacing.l}>
              <MultiselectActionBar
                state={store.selectAllState}
                handleOnStateChange={(checked: boolean) => store.setSelectAll(checked)}
                label={`${store.selectedOrderIds.size} ${t('selected', 'selected').toLocaleLowerCase()}`}
                actions={actions}
                secondaryActions={secondaryActions}
              />
            </Box>

            <Box
              m={theme.customSpacing.l}
              bgcolor={'white'}
              border={`1px solid ${theme.palette.grey[300]}`}
            >
              {store.filteredOrders.map(order => {
                return (
                  <Stack
                    direction={'row'}
                    key={order.id}
                    borderBottom={`1px solid ${theme.palette.grey[300]}`}
                  >
                    <MultiselectCheckbox
                      value={store.selectedOrderIds.has(order.id)}
                      handleOnChange={(checked: boolean) =>
                        checked ? store.selectOrder(order.id) : store.unselectOrder(order.id)
                      }
                    />

                    <OrderListItem
                      order={order}
                      filter={store.filter}
                      options={[
                        {
                          label: 'Order Details',
                          onClick: () => navigateBookingOrderPage(order),
                        },
                        {
                          label: 'Delete',
                          color: 'error',
                          onClick: () => onDeleteOrder([order]),
                        },
                      ]}
                      showBorderBottom={false}
                    />
                  </Stack>
                )
              })}
            </Box>
          </>
        )}

        {store.isNoResults && <NoListResult result='No orders found' />}

        {store.isCustomsDialogOpen && <AddCustomersReferencesDialog store={store} />}
      </Box>
    </InitializationWrapper>
  )
})
