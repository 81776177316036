import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { DragDropContext, DragStart, DropResult, Id } from 'react-beautiful-dnd'
import GroupingArea from './GroupingArea'
import { GroupingContextAction } from './GroupingContextMenu'
import { GroupingTabPanel } from './GroupingTabPanel'
import { GroupingTabs } from './GroupingTabs'
import {
  Entities,
  Filter,
  GenericFilter,
  Group,
  GroupChangeFnType,
  GroupingState,
  Item,
  SequenceChangeFnType,
  SequenceMap,
} from './types'
import {
  getFilteredItems,
  insertDefaultGroup,
  multiDragAwareReorder,
  MultiDragAwareReorderProps,
  multiSelectTo as multiSelect,
} from './utils/utils'

export interface GroupChangeProps {
  groupChangeState: GroupingState
  sourceGroupId: Id
  destinationGroupId: Id
  sourceItemIndex?: number
  destinationItemIndex?: number
  selectedItemIds: Id[]
}

interface Props {
  entities: Entities
  itemSize?: number
  height?: number
  genericFilter?: GenericFilter
  filters?: Filter[]
  defaultGroupId?: string
  customGroupName?: string
  hasDefaultGroup?: boolean
  hasContextMenu?: boolean
  allowSequence?: boolean
  allowGroupAdd?: boolean
  groupingActionsIndex?: number
  allowGroupOptions?: boolean
  allowGroupNotes?: boolean
  uniqueGroupNames?: boolean
  ignoreGroupChanges?: boolean
  actions?: GroupingContextAction[]
  toggleSeqFn?: (entities: Entities) => Promise<void> | void
  updateGroupFn?: (entities: Entities) => Promise<void> | void
  sequenceChangeFn?: SequenceChangeFnType
  groupChangeFn?: GroupChangeFnType
  groupChangeIsValidFn?: (args: MultiDragAwareReorderProps) => Promise<boolean> | boolean
  ungroupFn?: (entities: Entities) => Promise<void> | void
  renderData?: (item: Item, group: Group) => ReactNode
  itemSizeFn?: (item: Item) => number
  heightFn?: (items: Item[]) => number
}

export const Grouping = ({
  entities,
  itemSize,
  height,
  filters,
  genericFilter,
  actions,
  defaultGroupId = crypto.randomUUID(),
  customGroupName = 'Group',
  hasDefaultGroup = true,
  hasContextMenu = true,
  allowSequence = true,
  allowGroupAdd = true,
  groupingActionsIndex = 0,
  allowGroupOptions = true,
  allowGroupNotes = true,
  uniqueGroupNames = false,
  ignoreGroupChanges = false,
  toggleSeqFn,
  updateGroupFn,
  groupChangeFn,
  groupChangeIsValidFn,
  sequenceChangeFn,
  ungroupFn,
  renderData,
  itemSizeFn,
  heightFn,
}: Props) => {
  const { t } = useTranslate()

  const stateEntities = useMemo(
    () => (hasDefaultGroup ? insertDefaultGroup(entities, defaultGroupId) : entities),
    [defaultGroupId, entities, hasDefaultGroup],
  )

  const [state, setState] = useState<GroupingState>({
    entities: stateEntities,
    selectedItemIds: [],
    draggingItemId: undefined,
  })

  useEffect(() => {
    setState({
      entities: stateEntities,
      selectedItemIds: [],
      draggingItemId: undefined,
    })
  }, [stateEntities])

  const onGroupChange = async (groupChangeProps: GroupChangeProps) => {
    const updatedState = await getUpdatedGroupingState(groupChangeProps)

    const {
      selectedItemIds,
      groupChangeState,
      sourceGroupId,
      destinationGroupId,
      destinationItemIndex,
    } = groupChangeProps

    if (!updatedState) return

    if (groupChangeFn) {
      let items = selectedItemIds.map(id => groupChangeState.entities.items[id])

      if (items.length === 0 && groupChangeState.draggingItemId) {
        items = [groupChangeState.entities.items[groupChangeState.draggingItemId]]
      }

      const sourceGroup = groupChangeState.entities.groups[sourceGroupId]
      const destinationGroup = groupChangeState.entities.groups[destinationGroupId]
      groupChangeFn(
        updatedState.entities,
        sourceGroup,
        destinationGroup,
        items,
        destinationItemIndex,
      )
    }
  }

  const onSequenceChange = async (
    groupChangeState: GroupingState,
    item: Item,
    groupId: Id,
    previousSequence: number,
    newSequence: number,
  ) => {
    if (!newSequence || newSequence === previousSequence) return

    const sourceItemIndex = (previousSequence ?? 0) - 1
    const destinationItemIndex = (newSequence ?? 0) - 1

    const updatedState = await getUpdatedGroupingState({
      groupChangeState: groupChangeState,
      sourceGroupId: groupId,
      destinationGroupId: groupId,
      selectedItemIds: [item.id],
      sourceItemIndex,
      destinationItemIndex,
    })

    if (!updatedState) return

    if (sequenceChangeFn) {
      sequenceChangeFn(updatedState.entities, item, previousSequence, newSequence, groupId)
    }
  }

  const getUpdatedGroupingState = async ({
    groupChangeState,
    sourceGroupId,
    destinationGroupId,
    selectedItemIds,
    sourceItemIndex,
    destinationItemIndex,
  }: GroupChangeProps) => {
    const sourceFilteredItems = getFilteredItems(
      groupChangeState.entities,
      sourceGroupId,
      filters,
      genericFilter,
    )

    const filteredItems = sourceFilteredItems

    const args: MultiDragAwareReorderProps = {
      entities: groupChangeState.entities,
      filteredItems: filteredItems,
      selectedItemIds: selectedItemIds,
      sourceGroupId: sourceGroupId,
      sourceItemIndex: sourceItemIndex ?? 0,
      destinationGroupId: destinationGroupId,
      destinationItemIndex: destinationItemIndex ?? 0,
    }

    if (groupChangeIsValidFn && !(await groupChangeIsValidFn(args))) return

    let updatedState = { ...groupChangeState }

    if (!ignoreGroupChanges) {
      const processed = multiDragAwareReorder(args)

      updatedState = { ...processed, draggingItemId: undefined, selectedItemIds: [] }
      setState(updatedState)
    }

    return updatedState
  }

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.key === 'Escape') {
      unselectAll()
    }
  }

  const onWindowClick = (event: MouseEvent) => {
    unselectIfNotPrevented(event)
  }

  const onWindowTouchEnd = (event: TouchEvent) => {
    unselectIfNotPrevented(event)
  }

  const unselectIfNotPrevented = (event: Event) => {
    if (event.defaultPrevented) {
      return
    }
    unselectAll()
  }

  useEffect(() => {
    window.addEventListener('click', onWindowClick)
    window.addEventListener('keydown', onWindowKeyDown)
    window.addEventListener('touchend', onWindowTouchEnd)

    return () => {
      window.removeEventListener('click', onWindowClick)
      window.removeEventListener('keydown', onWindowKeyDown)
      window.removeEventListener('touchend', onWindowTouchEnd)
    }
  })

  const onDragStart = (start: DragStart) => {
    const selected = state.selectedItemIds.find((itemId: Id) => itemId === start.draggableId)

    if (!selected) {
      unselectAll()
    }
    setState({
      ...state,
      draggingItemId: start.draggableId,
    })
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (!destination || result.reason === 'CANCEL') {
      setState({
        ...state,
        draggingItemId: undefined,
      })
      return
    }

    const sourceGroupId = source.droppableId
    const destinationGroupId = destination.droppableId

    const sourceFilteredItems = getFilteredItems(
      state.entities,
      sourceGroupId,
      filters,
      genericFilter,
    )
    const destinationFilteredItems = getFilteredItems(
      state.entities,
      destinationGroupId,
      filters,
      genericFilter,
    )

    const sourceItemIndex = getFilteredItemIndex(
      sourceFilteredItems,
      state.entities.groups[sourceGroupId].sequence,
      source.index,
    )

    const destinationItemIndex = getFilteredItemIndex(
      destinationFilteredItems,
      state.entities.groups[destinationGroupId].sequence,
      destination.index,
    )

    onGroupChange({
      groupChangeState: state,
      sourceGroupId,
      destinationGroupId,
      sourceItemIndex,
      destinationItemIndex,
      selectedItemIds: state.selectedItemIds,
    })
  }

  const getFilteredItemIndex = (filteredItems: Item[], sequenceMap: SequenceMap, index: number) => {
    if (!filteredItems || filteredItems.length === 0) return 0

    const targetedItem = filteredItems[index]
    if (!targetedItem) return index

    return sequenceMap[targetedItem.id]
  }

  const toggleSelection = (itemId: Id) => {
    const selectedItemIds = state.selectedItemIds
    const wasSelected = selectedItemIds.includes(itemId)

    const newTaskIds = (() => {
      if (!wasSelected) {
        return [itemId]
      }

      if (selectedItemIds.length > 1) {
        return [itemId]
      }
      return []
    })()

    setState({
      ...state,
      selectedItemIds: newTaskIds,
    })
  }

  const toggleSelectionInGroup = (itemId: Id) => {
    const selectedItemIds = state.selectedItemIds
    const index = selectedItemIds.indexOf(itemId)

    // if not selected - add it to the selected items
    if (index === -1) {
      setState({
        ...state,
        selectedItemIds: [...selectedItemIds, itemId],
      })
      return
    }

    const shallow = [...selectedItemIds]
    shallow.splice(index, 1)
    setState({
      ...state,
      selectedItemIds: shallow,
    })
  }

  const multiSelectTo = (newItemId: Id) => {
    const updated = multiSelect(
      state.entities,
      state.selectedItemIds,
      newItemId,
      filters,
      genericFilter,
    )

    if (updated == null) {
      return
    }

    setState({
      ...state,
      selectedItemIds: updated,
    })
  }

  const unselectAll = () => {
    setState({
      ...state,
      selectedItemIds: [],
    })
  }

  const addGroup = (itemIds?: Id[]) => {
    const newGroup: Group = {
      id: crypto.randomUUID(),
      name: `${customGroupName} Group ${state.entities.groupOrder.length}`,
      note: '',
      sequence: {},
      sequenced: false,
      ItemIds: [],
    }

    const updatedState = {
      ...state,
      entities: {
        groupOrder: [...state.entities.groupOrder, newGroup.id],
        groups: { ...state.entities.groups, [newGroup.id]: newGroup },
        items: { ...state.entities.items },
      },
    }

    setState(updatedState)

    if (itemIds && itemIds.length > 0) {
      onGroupChange({
        groupChangeState: updatedState,
        sourceGroupId: defaultGroupId,
        destinationGroupId: newGroup.id,
        selectedItemIds: itemIds,
      })
    }
  }

  const removeGroup = (groupIdToRemove: Id) => {
    if (groupIdToRemove === defaultGroupId) return

    const itemsToRemove = state.entities.groups[groupIdToRemove].ItemIds
    state.entities.groups[defaultGroupId].ItemIds.push(...itemsToRemove)

    const sequenceToRemove = state.entities.groups[groupIdToRemove].sequence
    state.entities.groups[defaultGroupId].sequence = {
      ...state.entities.groups[defaultGroupId].sequence,
      ...sequenceToRemove,
    }

    const updatedSequence = Object.keys(state.entities.groups[defaultGroupId].sequence).reduce(
      (previous: SequenceMap, current: Id, index) => {
        previous[current] = index
        return previous
      },
      {},
    )

    state.entities.groups[defaultGroupId].sequence = updatedSequence

    const updatedGroupIds = state.entities.groupOrder.filter(groupId => groupId !== groupIdToRemove)

    if (Object.prototype.hasOwnProperty.call(state.entities.groups, groupIdToRemove)) {
      delete state.entities.groups[groupIdToRemove]
    }

    const updatedState = {
      ...state,
      entities: {
        ...state.entities,
        groupOrder: updatedGroupIds,
        groups: state.entities.groups,
      },
    }
    setState(updatedState)

    if (ungroupFn) ungroupFn(updatedState.entities)
  }

  const getMoveToGroupProperties = (
    originGroup: Group,
    destinationGroup: Group,
    selectedItemId: Id,
  ) => {
    const destinationLastItemIndex =
      destinationGroup.ItemIds.length > 0 ? destinationGroup.ItemIds.length : 0
    const destinationGroupId = destinationGroup.id
    const originGroupId = originGroup.id

    const originItemId = selectedItemId
    const originItemIndex = originGroup.ItemIds.indexOf(originItemId) ?? 0

    return {
      originGroupId,
      destinationGroupId,
      originItemIndex,
      destinationLastItemIndex,
    }
  }

  const getGroupOptions = (
    group: Group,
    items: Item[],
    selectedItemIds: Id[],
  ): GroupingContextAction[] => {
    const groupValues = Object.values(state.entities.groups).filter(x => !x.isMoveActionDisabled)
    const groupOptions = []

    if (allowGroupAdd) {
      groupOptions.push({
        label: () => t('createNewGroup', 'Create new group'),
        onClick: () => addGroup(selectedItemIds),
      })
    }

    groupOptions.push(
      ...groupValues
        .filter(g => g.id !== group.id)
        .map<GroupingContextAction>(g => ({
          label: () => g.name,
          onClick: selectedIds => {
            const { originGroupId, destinationGroupId, originItemIndex, destinationLastItemIndex } =
              getMoveToGroupProperties(group, g, selectedIds[0])

            onGroupChange({
              groupChangeState: state,
              sourceGroupId: originGroupId,
              destinationGroupId,
              sourceItemIndex: originItemIndex,
              destinationItemIndex: destinationLastItemIndex,
              selectedItemIds: selectedIds,
            })
          },
        })),
    )

    return groupOptions
  }

  const dropdownOptions = (): GroupingContextAction[] => {
    const groupValues = Object.values(state.entities.groups).filter(x => !x.isMoveActionDisabled)
    const options: GroupingContextAction[] = actions ? [...actions] : []

    if (allowGroupAdd || groupValues.length > 1) {
      options.splice(groupingActionsIndex, 0, {
        label: () => t('moveTo', 'Move to'),
        children: getGroupOptions,
      })
    }

    return options
  }

  const [currentTabId, setCurrentTabId] = useState<Id>(`${state.entities.groupOrder[0]}`)

  return (
    <Box>
      <GroupingTabs
        allowSequence={allowSequence}
        groupOrder={state.entities.groupOrder}
        groups={state.entities.groups}
        currentTabId={currentTabId}
        areaLabel={customGroupName}
        uniqueGroupNames={uniqueGroupNames}
        allowGroupNotes={allowGroupNotes}
        allowGroupAdd={allowGroupAdd}
        allowGroupOptions={allowGroupOptions}
        addGroup={addGroup}
        setCurrentTabId={setCurrentTabId}
        ungroup={removeGroup}
        updateGroupFn={() => {
          if (updateGroupFn) updateGroupFn(state.entities)
        }}
        toggleSeqFn={() => {
          if (toggleSeqFn) toggleSeqFn(state.entities)
        }}
      />
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {state.entities.groupOrder.map((groupId: Id) => {
          return (
            <GroupingTabPanel key={`${groupId}`} groupId={groupId} currentTabId={currentTabId}>
              <GroupingArea
                state={state}
                groups={state.entities.groups}
                group={state.entities.groups[groupId]}
                items={getFilteredItems(state.entities, groupId, filters, genericFilter)}
                itemSize={itemSize}
                height={height}
                selectedItemIds={state.selectedItemIds}
                key={groupId}
                hasContextMenu={hasContextMenu}
                draggingItemId={state.draggingItemId}
                actions={dropdownOptions()}
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={toggleSelectionInGroup}
                multiSelectTo={multiSelectTo}
                renderData={renderData}
                onSequenceChange={(
                  groupId: Id,
                  item: Item,
                  previousSequence: number,
                  newSequence: number,
                ) => onSequenceChange(state, item, groupId, previousSequence, newSequence)}
                addGroup={addGroup}
                itemSizeFn={itemSizeFn}
                heightFn={heightFn}
              />
            </GroupingTabPanel>
          )
        })}
      </DragDropContext>
    </Box>
  )
}
