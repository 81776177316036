import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ISummaryAttribute, SummaryCard } from './SummaryCard'

interface IProps {
  isEmpty: boolean
  grossWeight?: number
  vgm?: number
  content?: string
  dangerousGoods: boolean
  isOog?: boolean
  seals: boolean
  onEdit?: () => void
}

export const CargoDetailsSummary = ({
  isEmpty,
  grossWeight,
  vgm,
  content,
  dangerousGoods,
  isOog,
  seals,
  onEdit,
}: IProps) => {
  const { t } = useTranslate()

  const attributes: ISummaryAttribute[] = [
    {
      key: 'status',
      label: t('status', 'Status'),
      value: isEmpty ? t('empty', 'Empty') : t('full', 'Full'),
    },
  ]

  if (!isEmpty)
    attributes.push(
      { key: 'content', label: t('content', 'Content'), value: content },
      { key: 'grossWeight', label: t('grossWeight', 'Gross Weight'), value: `${grossWeight}kg` },
      { key: 'vgm', label: t('vgm', 'VGM'), value: `${vgm}kg` },
      {
        key: 'dangerousGoods',
        label: t('dangerousGoods', 'Dangerous Goods'),
        value: dangerousGoods ? t('yes', 'Yes') : t('no', 'No'),
      },
      { key: 'seals', label: t('seals', 'Seals'), value: seals ? t('yes', 'Yes') : t('no', 'No') },
      { key: 'oog', label: t('oog', 'OOG'), value: isOog ? t('yes', 'Yes') : t('no', 'No') },
    )

  return (
    <SummaryCard
      title={<Typography variant='h4'>{t('cargoDetails', 'Cargo Details')}</Typography>}
      attributes={attributes}
      onClickLabel={t('edit', 'Edit')}
      onClickFn={onEdit}
    />
  )
}
