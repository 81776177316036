import { Card, CardActionArea, Grid, Stack, Typography } from '@mui/material'
import { ContainerResponseDto } from '@planning/app/api'
import { formatWeight } from '@planning/utils/container-utils'
import { useTranslate } from '@tolgee/react'
import { HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props extends ContainerResponseDto {
  filter?: string
  onClickEdit?: () => void
}

interface AttributeProps {
  filter?: string
  label: string
  value: string | number
  size: number
  highlightFilteredValue?: boolean
}

const ContainerMasterDataCardAttribute: FC<AttributeProps> = ({
  filter,
  label,
  value,
  size,
  highlightFilteredValue,
}) => {
  const theme = useMinimalsTheme()
  const stringValue = value.toLocaleString()

  return (
    <Grid item xs={size}>
      <Stack mt={theme.customSpacing.s}>
        <Typography variant='body1'>{label}</Typography>
        <Typography variant='h4'>
          <HighlightedText
            color={theme.palette.info.main}
            highlight={highlightFilteredValue ? filter?.toUpperCase() : ''}
          >
            {stringValue.toUpperCase()}
          </HighlightedText>
        </Typography>
      </Stack>
    </Grid>
  )
}

export const ContainerMasterDataCard = observer((props: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { filter, number, isoCode, length, height, type, tare, maxGross, operator, onClickEdit } =
    props

  const attributes = [
    {
      label: t('isoCode', 'isoCode'),
      value: isoCode && isoCode !== '' ? isoCode?.toUpperCase() : t('unknown', 'Unknown'),
      size: 1,
      highlightFilteredValue: true,
    },
    {
      label: t('length', 'Length'),
      value: length ? `${length}ft` : t('unknown', 'Unknown'),
      size: 1,
    },
    { label: t('height', 'Height'), value: height ?? t('unknown', 'Unknown'), size: 1 },
    { label: t('type', 'Type'), value: type ?? t('unknown', 'Unknown'), size: 2 },
    { label: t('tare', 'Tare'), value: formatWeight(tare), size: 1 },
    { label: t('maxGross', 'Max Gross'), value: formatWeight(maxGross), size: 1 },
    {
      label: t('maxPayload', 'Max Payload'),
      value: maxGross && tare ? formatWeight(maxGross - tare) : t('unknown', 'Unknown'),
      size: 1,
    },
    {
      label: t('operator', 'Operator'),
      value: operator && operator !== '' ? operator : t('unknown', 'Unknown'),
      size: 4,
    },
  ]

  return (
    <CardActionArea
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        mb: theme.customSpacing.m,
      }}
      onClick={onClickEdit}
    >
      <Card
        sx={{
          padding: theme.customSpacing.m,
        }}
      >
        <Grid container sx={{ placeContent: 'space-between' }}>
          <Typography variant='h2'>
            <HighlightedText color={theme.palette.info.main} highlight={filter?.toUpperCase()}>
              {number?.toUpperCase()}
            </HighlightedText>
          </Typography>
        </Grid>
        <Grid container spacing={theme.customSpacing.xll}>
          {attributes.map(attribute => (
            <ContainerMasterDataCardAttribute
              key={attribute.label}
              {...attribute}
              filter={filter}
            />
          ))}
        </Grid>
      </Card>
    </CardActionArea>
  )
})
