import { Autocomplete, TextField } from '@mui/material'
import { CustomerResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { CustomerAutocompleteViewStore } from '@planning/stores/customer/CustomerAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { FC, useEffect } from 'react'

interface Props {
  selectedCustomer?: CustomerResponseDto
  handleOnChange?: (value?: CustomerResponseDto) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disable?: boolean
  required?: boolean
}

export const CustomerAutoComplete: FC<Props> = observer(
  ({ selectedCustomer, handleOnChange, error, helperText, label, disable, required }) => {
    const { t } = useTranslate()
    const { appViewStore } = usePlanningStore()
    const customerAutocompleteViewStore = useLocalStore(() => new CustomerAutocompleteViewStore())

    useEffect(() => {
      const fetch = async () => {
        await customerAutocompleteViewStore.fetch(customerAutocompleteViewStore.filter)
      }

      fetch().catch(error => appViewStore.setShowAlert('error', error.message))
    }, [appViewStore, customerAutocompleteViewStore])

    return (
      <Autocomplete
        disablePortal
        disabled={disable}
        getOptionLabel={customer => customer.name}
        open={customerAutocompleteViewStore.isOpen}
        onOpen={() => customerAutocompleteViewStore.setOpen(true)}
        onClose={() => customerAutocompleteViewStore.setOpen(false)}
        options={customerAutocompleteViewStore.items}
        isOptionEqualToValue={(option: CustomerResponseDto, value: CustomerResponseDto) =>
          option.id === value.id
        }
        value={selectedCustomer ?? null}
        onChange={(_, customer) => {
          if (handleOnChange) handleOnChange(customer ?? undefined)
        }}
        renderInput={params => (
          <TextField
            required={required}
            {...params}
            data-cy='customer-autocomplete'
            label={label ?? t('customer', 'Customer')}
            error={error}
            helperText={helperText}
            onChange={e => {
              customerAutocompleteViewStore.setFilter(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
