import { ArrowRightAlt } from '@mui/icons-material'
import { Box, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import { CarrierVisitDirection, OrderStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  ColoredLabel,
  ColorSchema,
  CraneIcon,
  ReachStackerIcon,
  TruckIcon,
  useMinimalsTheme,
  YardIcon,
} from '@tom-ui/ui'

interface Props {
  status: string
  direction?: string
  equipment?: string
  currentLocation?: string
  plannedLocation?: string
  sx?: SxProps<Theme>
}

export const OrderStatusChip = ({
  status,
  direction,
  equipment,
  currentLocation,
  plannedLocation,
  sx,
}: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const iconSx = { fontSize: '16px' }

  const getChipBg = (value: string) => {
    switch (value) {
      case OrderStatus.Open:
      case 'Expected':
      case 'Arrived':
        return theme.palette.info.lighter
      case OrderStatus.Fulfilled:
      case 'OnTerminal':
      case 'OnYard':
        return theme.palette.success.lighter
      default:
        return theme.palette.grey[200]
    }
  }

  const getChipColor = (value: string): ColorSchema => {
    switch (value) {
      case OrderStatus.Open:
      case 'Expected':
      case 'Arrived':
        return 'info'
      case OrderStatus.Fulfilled:
      case 'OnTerminal':
      case 'OnYard':
        return 'success'
      default:
        return 'secondary'
    }
  }

  const getLabel = (value: string, currentLocation?: string) => {
    let label = t('expected', 'Expected')

    switch (value) {
      case OrderStatus.Open:
        label = t('open', 'Open')
        break
      case OrderStatus.Fulfilled:
        label = t('fulfilled', 'Fulfilled')
        break
      case 'Arrived':
        label = t('arrived', 'Arrived')
        break
      case 'OnTerminal':
        label = t('onTerminal', 'On Terminal')
        break
      case 'OnYard':
        label = currentLocation ?? t('onYard', 'On Yard')
        break
      case 'Departed':
        label = t('departed', 'Departed')
        break
    }

    return label
  }

  const getIcon = (
    status: string,
    direction?: string,
    equipment?: string,
    plannedLocation?: string,
  ) => {
    let iconLocation = <></>
    let iconDirection = <></>

    switch (equipment) {
      case 'Truck':
        iconLocation = <TruckIcon sx={iconSx} />
        break
      case 'RS':
        iconLocation = <ReachStackerIcon sx={iconSx} />
        break
      case 'Crane':
        iconLocation = <CraneIcon sx={iconSx} />
        break
    }

    switch (direction) {
      case CarrierVisitDirection.Inbound:
        iconDirection = <ArrowDownRightIcon sx={iconSx} />
        break
      case CarrierVisitDirection.Outbound:
        iconDirection = <ArrowUpRightIcon sx={iconSx} />
        break
    }

    if (status === 'OnYard') {
      iconLocation = <YardIcon sx={iconSx} />
    }

    if (direction === 'Move' && plannedLocation) {
      iconDirection = <ArrowRightAlt sx={iconSx} />
    }

    return (
      <>
        {iconLocation}
        {iconDirection}
      </>
    )
  }

  const getOrderStatusChipTooltip = (plannedLocation?: string) => {
    if (plannedLocation) {
      return (
        <Stack flexDirection='column' alignItems={'center'} gap={theme.customSpacing.xxxs}>
          <Typography variant='caption' color={theme.palette.grey[400]}>
            {t('plannedLocation', 'Planned location')}
          </Typography>
          <Typography variant='captionBold'>{plannedLocation}</Typography>
        </Stack>
      )
    }

    return
  }

  return (
    <Box data-cy='order-status-chip' sx={{ ...sx, width: 'fit-content' }}>
      <Tooltip arrow title={getOrderStatusChipTooltip(plannedLocation)}>
        <span>
          <ColoredLabel
            label={getLabel(status, currentLocation)}
            color={getChipColor(status)}
            sx={{
              width: 'fit-content',
              backgroundColor: getChipBg(status),
            }}
            endIcon={getIcon(status, direction, equipment, plannedLocation)}
          />
        </span>
      </Tooltip>
    </Box>
  )
}
