import { OrderResponseDto } from '@planning/app/api'

export type OogFormData = {
  isOog?: boolean
  overLengthRear?: number | null
  overLengthFront?: number | null
  overWidthLeft?: number | null
  overWidthRight?: number | null
  overHeight?: number | null
}

export const createOogFormDataFromOrderResponse = (order?: OrderResponseDto): OogFormData => {
  return {
    isOog: order?.isOOG ?? false,
    overLengthRear: order?.overLengthRear,
    overLengthFront: order?.overLengthFront,
    overWidthLeft: order?.overWidthLeft,
    overWidthRight: order?.overWidthRight,
    overHeight: order?.overHeight,
  }
}
