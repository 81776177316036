import { Autocomplete, Box, FormControl, Stack, TextField, Typography } from '@mui/material'
import { CarrierVisitDto } from '@operations/app/api'
import { EquipmentOperatorLandingUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-landing.ui-store'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorLandingUIStore
}

export const SelectVesselVisit = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={uiStore.readyForOperationVesselVisits}
        value={uiStore.selectedVesselVisit ?? null}
        onChange={(event: any, newValue: CarrierVisitDto | null) => {
          uiStore.selectVesselVisit(newValue)
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.carrierName ?? ''}
        fullWidth
        data-cy='operator-select-visit-field'
        renderInput={params => <TextField {...params} label={t('vesselName', 'Vessel name')} />}
        renderOption={(props, option) => (
          <Box
            component='li'
            {...props}
            key={option.id}
            data-cy={`operator-select-visit-${option.carrierName}-option`}
          >
            <Stack>
              <Typography variant='subtitle1'>{option.carrierName}</Typography>

              <Stack direction='row' gap={theme => theme.customSpacing.xxs}>
                <Typography variant='caption' color='secondary'>
                  {t('ata', 'ATA')}:
                </Typography>
                <Typography variant='caption' color='secondary'>
                  {option.ata ? dayjs(option.ata).format('L LT') : '-'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )}
      />
    </FormControl>
  )
})
