import { Paper, Radio, Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

export interface YardPositionInputProps {
  position?: string
  enableEnterPosition: boolean
  disableSelectFlexibleZones?: boolean
  areas: string[]
  radio: string
  hint: string
  onChangePosition: (value: string) => void
  onChangeRadio: (value: string) => void
  label?: string
  disabled?: boolean
}

export const YardPositionInput = ({
  position,
  enableEnterPosition,
  disableSelectFlexibleZones,
  areas,
  radio,
  hint,
  onChangePosition,
  onChangeRadio,
  disabled,
  label,
}: YardPositionInputProps) => {
  const { t } = useTranslate()
  const { palette, customSpacing } = useMinimalsTheme()
  const radioProps = (item: string) => ({
    checked: item === radio,
    value: item,
  })

  return (
    <Stack justifyContent='center' gap={customSpacing.m}>
      {(enableEnterPosition || !disableSelectFlexibleZones) && (
        <TextField
          value={position}
          sx={{
            input: {
              color: palette.grey[600],
              fontSize: 24,
              fontWeight: 600,
              textTransform: 'uppercase',
            },
          }}
          label={label ?? t('enterYardPosition', 'Enter Yard Position')}
          error={hint !== ''}
          helperText={hint}
          onChange={e => onChangePosition(e.target.value.toUpperCase())}
          onClick={() => onChangeRadio('#')}
          disabled={disabled}
          data-cy='yard-position-field'
        />
      )}
      {!disableSelectFlexibleZones &&
        areas.map(area => (
          <Paper
            key={`flex_area${area}`}
            variant='outlined'
            onClick={() => onChangeRadio(area)}
            sx={{
              backgroundColor: area === radio ? palette.primary.lighter : 'transparent',
              width: '100%',
              padding: customSpacing.m,
            }}
          >
            <Stack flexDirection='row' gap={1} alignItems={'center'}>
              <Radio {...radioProps(area)} />
              <Typography variant='body2'>{area}</Typography>
            </Stack>
          </Paper>
        ))}
    </Stack>
  )
}
