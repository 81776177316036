import { CarrierVisitDirection, RailcarTrackPositionResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetRailcarTrackPositionsQuery } from '@planning/messages/queries/getRailcarTrackPositionsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { RailcarItemStore } from '../railcar/RailcarItemStore'
import { IEntityStore } from '../types'
import { RailTrackItemStore } from './RailTrackItemStore'
import { IRailcarTrackPositionItem, RailcarTrackPositionItem } from './RailcarTrackPositionItem'

export class RailcarTrackPositionItemStore
  extends ItemStore<RailcarTrackPositionResponseDto, IRailcarTrackPositionItem, number>
  implements IEntityStore<IRailcarTrackPositionItem>
{
  constructor(
    messageBus: IMessageBus,
    private railTrackStore: RailTrackItemStore,
    private readonly railcarItemStore: RailcarItemStore,
  ) {
    super((key, data) => new RailcarTrackPositionItem(this, key, data), {
      messageBus,
      bulkFetchFunc: (ids: number[]) => new GetRailcarTrackPositionsQuery(ids),
    })
    makeObservable(this, {
      railTracksById: computed,
      receiveQuery: action,
      receiveUpsertedEvent: action,
      receiveDeletedEvent: action,
    })

    messageBus.subscribeEvent(GetRailcarTrackPositionsQuery.type, this.receiveQuery)
    messageBus.subscribeEvent(
      EventTypes.RailcarTrackPositionUpsertedEvent,
      this.receiveUpsertedEvent,
    )
    messageBus.subscribeEvent(EventTypes.RailcarTrackPositionDeletedEvent, this.receiveDeletedEvent)
  }

  get railTracksById() {
    return this.railTrackStore.elements
  }

  getById = (id: number) => {
    return _.get(this.elements, id)
  }

  receiveQuery = (event: IEvent<RailcarTrackPositionResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveUpsertedEvent = (event: IEvent<RailcarTrackPositionResponseDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveDeletedEvent = (event: IEvent<number>): void => {
    const id = event.payload
    if (id && _.has(this.elements, id)) {
      this.delete(id)
    }
  }

  // [Review] TODO: should be computed and return dictionary
  getRailcarsTrackPositionByRailVisitId(railVisitId?: number, direction?: CarrierVisitDirection) {
    if (!railVisitId || !direction) return []

    return _(this.elements)
      .map(x => x.data)
      .filter(x => x.railVisitId === railVisitId && x.direction === direction)
      .orderBy(x => x.railcarName)
      .value()
  }

  // [Review] TODO: should be computed
  getRailTracks() {
    return _(this.railTrackStore.elements)
      .map(x => x.data)
      .uniqBy(x => x.id)
      .value()
  }

  getRailcarLengthSum(
    railVisitId: number,
    railTrackId?: string,
    direction?: CarrierVisitDirection,
  ) {
    return _(this.elements)
      .filter(
        x =>
          x.data.railVisitId === railVisitId &&
          x.data.railTrackId === railTrackId &&
          x.data.direction === direction,
      )
      .map(x => this.railcarItemStore.getById(x.data.railcarId)?.data?.length)
      .sum()
  }

  isRailcarLengthSumExceedsRailtrackLengthOnDirection(
    railVisitId: number,
    railTrackId: string,
    direction: CarrierVisitDirection,
  ) {
    const railTrack = this.railTracksById[railTrackId]?.data
    if (!railTrack?.length) return false

    const railcarLengthSum = this.getRailcarLengthSum(railVisitId, railTrackId, direction)

    return railcarLengthSum > railTrack.length
  }

  isRailcarLengthSumExceedsAnyRailtrackLength(railVisitId: number) {
    return _(this.railTracksById)
      .map(x => x.data)
      .some(x => this.isRailcarLengthSumExceedsRailtrackLength(railVisitId, x.id))
  }

  // [Review] TODO: should move to RailVisitPlanningViewStore
  isRailcarLengthSumExceedsRailtrackLength(railVisitId: number, railTrackId: string) {
    const isInboundExceeding = this.isRailcarLengthSumExceedsRailtrackLengthOnDirection(
      railVisitId,
      railTrackId,
      CarrierVisitDirection.Inbound,
    )
    const isOutboundExceeding = this.isRailcarLengthSumExceedsRailtrackLengthOnDirection(
      railVisitId,
      railTrackId,
      CarrierVisitDirection.Outbound,
    )

    return isInboundExceeding || isOutboundExceeding
  }

  isAnyRailcarOfVisitWithoutLength(railVisitId: number) {
    return _(this.elements)
      .filter(x => x.data.railVisitId === railVisitId)
      .some(x => !this.railcarItemStore.getById(x.data.railcarId)?.data?.length)
  }

  isAnyRailcarOnTrackWithoutLength(railVisitId: number, railTrackId: string) {
    const isInboundWithoutLength = this.isAnyRailcarWithoutLengthOnDirection(
      railVisitId,
      railTrackId,
      CarrierVisitDirection.Inbound,
    )
    const isOutboundWithoutLength = this.isAnyRailcarWithoutLengthOnDirection(
      railVisitId,
      railTrackId,
      CarrierVisitDirection.Outbound,
    )

    return isInboundWithoutLength || isOutboundWithoutLength
  }

  isAnyRailcarWithoutLengthOnDirection(
    railVisitId: number,
    railTrackId: string,
    direction: CarrierVisitDirection,
  ) {
    return _(this.elements)
      .filter(
        x =>
          x.data.railVisitId === railVisitId &&
          x.data.railTrackId === railTrackId &&
          x.data.direction === direction,
      )
      .some(x => !this.railcarItemStore.getById(x.data.railcarId)?.data?.length)
  }
}
