import { Button, ListItemButton, ListItemText } from '@mui/material'
import { ContainerDto } from '@planning/app/api'
import { FC } from 'react'

interface Props {
  container: ContainerDto
  onAssign: (container: ContainerDto) => Promise<void> | void
}

export const AssignContainerListItem: FC<Props> = ({ container, onAssign }) => {
  const handleAssign = () => onAssign(container)

  return (
    <ListItemButton onClick={handleAssign}>
      <ListItemText primary={`${container.number}`} secondary={`${container.isoCode}`} />
      <Button variant='contained' data-cy='assign-nnr-order-container'>
        Assign
      </Button>
    </ListItemButton>
  )
}
