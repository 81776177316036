import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto, OrderStatus } from '@planning/app/api'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { orderService } from '@planning/services'
import { OrderGroupingStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { Group, Grouping, GroupingContextAction, Item } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { OrderGroupListItem } from './OrderGroupListItem'

// Hooks up grouping component with grouping store
export const ObserverGrouping: FC<{ store: OrderGroupingStore; disableSequencing?: boolean }> =
  observer(({ store: groupingStore, disableSequencing }) => {
    const { t } = useTranslate()
    const {
      appViewStore,
      dialogStore,
      containerEventViewStore,
      containerEventItemStore,
      orderUpdateItemStore,
    } = usePlanningStore()

    const { openEditOrder } = useOrderNavigation()

    const onDeleteOrder = async (items: OrderResponseDto[]) => {
      const count = items.length
      const confirmationMessageKey =
        count === 1 ? 'orderWillBeDeletedContinue' : 'ordersWillBeDeletedContinue'
      const confirmationMessage =
        count === 1
          ? 'Order will be deleted. Continue?'
          : `The selected ${count} Orders will be deleted. Continue?`

      const hasServiceOrders = items.some(item => item.hasServiceOrders)

      const isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(confirmationMessageKey, confirmationMessage),
        t('confirmOrderDelete', 'Confirm order delete'),
        hasServiceOrders
          ? t(
              'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
              'Any associated stripping/stuffing orders will be deleted',
            )
          : undefined,
      )

      if (!isConfirmed) return

      try {
        await orderService.deleteByIds(items.map(item => item.id))
        appViewStore.setShowAlert(
          'success',
          t('successfullyDeletedOrder', 'Successfully deleted order'),
        )
      } catch (e) {
        appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
      }
    }

    const onOrderHistory = async (containerNumber?: string | null) => {
      containerEventItemStore.setContainerNumber(containerNumber!)
      await containerEventItemStore.fetch()

      dialogStore.openDialog(
        <ContainerHistoryList containerNumber={containerNumber} store={containerEventViewStore} />,
      )
    }

    const getSelectedEntries = (selectedItemIds: Id[]) => {
      const entries = Object.entries(groupingStore.entities.items).filter(i =>
        selectedItemIds.includes(i[0]),
      )

      if (entries.length === 0) return []

      return entries
    }

    const getOrderProperty = (selectedItemIds: Id[], prop: string) => {
      return getSelectedEntries(selectedItemIds).map(i => i[1].content[prop])
    }

    const groupingActionsIndex = 2 // Index of grouping actions menu item in the actions list
    const getActions = (): GroupingContextAction[] => {
      return [
        {
          label: () => t('viewDetails', 'View details'),
          subtitle: (selectedItemIds: Id[]) => {
            const updates = orderUpdateItemStore.unreadOrderUpdates.filter(update =>
              selectedItemIds.includes(update.orderId.toString()),
            ).length

            if (!updates) return null

            return `${updates} updates`
          },
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length !== 1,
          onClick: (selectedItemIds: Id[]) => {
            if (selectedItemIds.length !== 1) return
            const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
            openEditOrder(selectedItem.content)
          },
        },
        {
          label: () => t('history', 'History'),
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length !== 1,
          onClick: (selectedItemIds: Id[]) => {
            if (selectedItemIds.length !== 1) return
            const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
            onOrderHistory(selectedItem.content.containerNumber)
          },
        },
        {
          label: () => t('copyDetail', 'Copy detail'),
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length === 0,
          children: () => [
            {
              label: () => t('containerNumber', 'Container Number'),
              onClick: (selectedItemIds: Id[]) => {
                const data = getOrderProperty(selectedItemIds, 'containerNumber')
                if (data) navigator.clipboard.writeText(data.join(', '))
              },
            },
            {
              label: () => t('referenceNumber', 'Reference Number'),
              onClick: (selectedItemIds: Id[]) => {
                const data = getOrderProperty(selectedItemIds, 'referenceNumber')
                if (data) navigator.clipboard.writeText(data.join(', '))
              },
            },
          ],
        },
        {
          label: () => t('admin', 'Admin'),
          children: () => [
            {
              label: () => t('undoCheckIn', 'Undo check in'),
              onClick: () => {
                appViewStore.setShowAlert('warning', 'Not implemented yet')
              },
            },
            {
              label: () => t('checkOutContainer', 'Check out container'),
              onClick: () => {
                appViewStore.setShowAlert('warning', 'Not implemented yet')
              },
            },
            {
              label: () => t('correctYardLocation', 'Correct yard location'),
              onClick: () => {
                appViewStore.setShowAlert('warning', 'Not implemented yet')
              },
            },
            {
              label: () => t('delete', 'Delete'),
              isActionDisabled: (selectedItemIds: Id[]) => {
                const entries = getSelectedEntries(selectedItemIds)

                return (
                  entries.some(i => i[1].content.status === OrderStatus.Fulfilled) ||
                  selectedItemIds.length === 0
                )
              },
              onClick: (selectedItemIds: Id[]) => {
                const entries = getSelectedEntries(selectedItemIds)

                onDeleteOrder(entries.map(i => i[1].content as OrderResponseDto))
              },
            },
          ],
        },
      ]
    }

    const notifyMovedOrders = (sourceGroup: Group, destinationGroup: Group, orders: Item[]) => {
      const sourceGroupNames = sourceGroup.name
      const destinationGroupName = destinationGroup.name

      const itemName = orders
        .slice(0, 3)
        .map(o => o.content.containerNumber)
        .join(', ')

      appViewStore.setShowAlert(
        'success',
        `Orders ${itemName} moved from ${sourceGroupNames} to ${destinationGroupName}`,
      )
    }

    return (
      <Grouping
        entities={groupingStore.entities}
        defaultGroupId={groupingStore.defaultGroupId}
        groupChangeFn={(entities, sourceGroup, destinationGroup, orders) => {
          groupingStore.saveChanges(entities)
          notifyMovedOrders(sourceGroup, destinationGroup, orders)
        }}
        ungroupFn={groupingStore.saveChanges}
        toggleSeqFn={groupingStore.saveChanges}
        updateGroupFn={groupingStore.saveChanges}
        sequenceChangeFn={groupingStore.saveChanges}
        actions={getActions()}
        genericFilter={{
          keys: ['referenceNumber', 'consignee', 'containerNumber'],
          value: groupingStore.filter ?? '',
        }}
        itemSize={60}
        allowSequence={!disableSequencing}
        allowGroupNotes={false}
        hasDefaultGroup={true}
        customGroupName={t('order', 'Order')}
        uniqueGroupNames
        groupingActionsIndex={groupingActionsIndex}
        filters={
          groupingStore.filterStatus
            ? [
                {
                  key: 'status',
                  value: groupingStore.filterStatus,
                },
              ]
            : []
        }
        renderData={(item: { id: Id; content: OrderResponseDto }, group) => (
          <OrderGroupListItem group={group} item={item} filter={groupingStore.filter} />
        )}
      />
    )
  })
