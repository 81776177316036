import { BookingsResponseDto } from '@planning/app/api'
import bookingsService from '@planning/services/bookingsService'
import { action, computed, makeObservable, observable } from 'mobx'

export class BookingsViewStore {
  data: BookingsResponseDto[] = []
  filter = ''
  firstLoad = false

  constructor() {
    makeObservable(this, {
      data: observable,
      filter: observable,
      firstLoad: observable,
      fetch: action,
      setFilter: action,
      filteredData: computed,
    })
  }

  async fetch() {
    try {
      const result = await bookingsService.get()
      this.data = result
      this.firstLoad = true
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  get filteredData() {
    if (!this.filter) return this.data

    const filterLowerCase = this.filter.toLocaleLowerCase()

    return this.data.filter(item => {
      const referenceNumberMatch = item.referenceNumber
        ?.toLocaleLowerCase()
        .includes(filterLowerCase)

      const identifierMatch = item.carrierVisit?.identifier
        ?.toLocaleLowerCase()
        .includes(filterLowerCase)

      const inboundTripIdMatch = item.inboundTripIds?.some(id =>
        id.toString().toLocaleLowerCase().includes(filterLowerCase),
      )

      const outboundTripIdMatch = item.outboundTripIds?.some(id =>
        id.toString().toLocaleLowerCase().includes(filterLowerCase),
      )

      return referenceNumberMatch || identifierMatch || inboundTripIdMatch || outboundTripIdMatch
    })
  }
}
