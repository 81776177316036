import { Autocomplete, TextField } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  errors: any
  setValue: any
  name: string
  label: string
  options: RailTrackResponseDto[]
  onChangeFn?: (value: RailTrackResponseDto | null) => void
}

const RailTrackControlledAutocomplete = observer(
  ({ control, errors, setValue, name, label, options, onChangeFn }: IProps) => {
    const singleOption = options.length === 1 ? options[0] : null

    useEffect(() => {
      if (singleOption) setValue(name, singleOption.id)
    }, [singleOption, name, setValue])

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={singleOption ? singleOption.id : null}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          const selectedValue = singleOption ?? options.find(option => option.id === value) ?? null

          return (
            <Autocomplete
              disablePortal
              getOptionLabel={railTrack => railTrack?.name || ''}
              options={options}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={selectedValue}
              onChange={(_, railTrack) => {
                onChange(railTrack?.id || null)
                onChangeFn?.(railTrack)
              }}
              disabled={!!singleOption}
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='rail-track-autocomplete'
                  label={label}
                  error={!!errors[name]}
                  helperText={errors[name] ? 'Field is required.' : undefined}
                />
              )}
            />
          )
        }}
      />
    )
  },
)

export default RailTrackControlledAutocomplete
