import { TenantDto, TerminalType } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { ControlledSelect } from '@admin/components/form-controls'
import { ControlledCheckbox } from '@admin/components/form-controls/ControlledCheckbox'
import { ControlledTextField } from '@admin/components/form-controls/ControlledTextField'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import { TenantLocationCodeField } from '@admin/pages/TenantConfiguration/components/form/TenantLocationCodeField'
import { TimezonesStore } from '@admin/stores/timezones.store'
import { Box, FormGroup, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { TenantFormMapper } from './tenant-form.mapper'
import { TenantFormProfile, defaultValues } from './tenant-form.profile'
import { schema } from './tenant-form.scheme'

interface Props extends FormProps {
  tenant?: TenantDto
  timezonesStore: TimezonesStore
  onSubmit: (formValues: TenantFormProfile) => Promise<void> | void
}

export const TenantForm = ({ id, tenant, timezonesStore, onSubmit }: Props) => {
  const { handleSubmit, formState, control, setValue, getValues, reset } = useFormWithSchema<
    Pick<TenantFormProfile, 'id' | 'name' | 'unloCodes'>
  >(schema(), defaultValues)

  const [formErrors, setFormErrors] = useState({
    hasDuplicateLocationCodes: false,
    isRequiredLocationCode: false,
  })

  const { t } = useTranslate()

  useEffect(() => {
    if (tenant) {
      reset(TenantFormMapper.mapDtoToFormValues(tenant))
    }
  }, [reset, tenant])

  const operatorViewSettingsDescription = (type: TerminalType) => {
    switch (type) {
      case TerminalType.Hig:
        return t('default', 'Default')

      case TerminalType.Inland:
        return t('inland', 'Inland')
      default:
        return t('unknown', 'Unknown')
    }
  }

  const handleSubmitForm = async (formValues: TenantFormProfile) => {
    const locationCodes = getValues('unloCodes')
    const uniqueLocationCodes = uniq(locationCodes?.map(lc => lc.name))

    if (!locationCodes || locationCodes.length === 0) {
      return setFormErrors({ ...formErrors, isRequiredLocationCode: true })
    }

    if (locationCodes?.length !== uniqueLocationCodes.length) {
      return setFormErrors({ ...formErrors, hasDuplicateLocationCodes: true })
    }

    setFormErrors({ hasDuplicateLocationCodes: false, isRequiredLocationCode: false })

    await onSubmit(formValues)
  }

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <FormGroup sx={{ gap: theme => theme.customSpacing.l, paddingBottom: 2 }}>
        <ControlledTextField<TenantFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name', 'Name')}
          fullWidth
        />
        <ControlledSelect<TenantFormProfile>
          name='timezone'
          formState={formState}
          control={control}
          label={t('timeZone', 'Time Zone')}
          options={timezonesStore.timezones.map(tz => ({
            label: tz.name,
            value: tz.id,
          }))}
        />
        <ControlledSelect<TenantFormProfile>
          name='culture'
          formState={formState}
          control={control}
          label={t('culture', 'Culture')}
          options={timezonesStore.cultures.map(c => ({
            label: c.name,
            value: c.id,
          }))}
        />
        <ControlledTextField<TenantFormProfile>
          name='address'
          formState={formState}
          control={control}
          label={t('address', 'Address')}
          fullWidth
        />

        <TenantLocationCodeField
          tenant={tenant}
          formState={formState}
          control={control}
          setValue={setValue}
          getValues={getValues}
          formErrors={formErrors}
        />

        <Stack>
          <Typography variant='subtitle2'>{t('configuration', 'Configuration')}</Typography>
          <ControlledCheckbox<TenantFormProfile>
            name={'hasAutoGeneratedCraneSplit'}
            formState={formState}
            control={control}
            label={t('hasAutoGeneratedCraneSplit', 'Has auto generated crane split')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipCraneSplit'}
            formState={formState}
            control={control}
            label={t('skipCraneSplit', 'Skip crane split')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipVesselTally'}
            formState={formState}
            control={control}
            label={t('skipVesselTally', 'Skip vessel tally')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipRailTally'}
            formState={formState}
            control={control}
            label={t('skipRailTally', 'Skip rail tally')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipRailTallyLoadPreparation'}
            formState={formState}
            control={control}
            label={t('skipRailTallyLoadPreparation', 'Skip rail tally load preparation')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipGateOut'}
            formState={formState}
            control={control}
            label={t('skipGateOut', 'Skip gate out')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipReleaseOrder'}
            formState={formState}
            control={control}
            label={t('skipReleaseOrder', 'Skip release order')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipVesselVisitReadyForOperation'}
            formState={formState}
            control={control}
            label={t('skipVesselVisitReadyForOperation', 'Skip vessel visit ready for operation')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipYardPlanning'}
            formState={formState}
            control={control}
            label={t('skipYardPlanning', 'Skip yard planning')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'skipBerthTimestamp'}
            formState={formState}
            control={control}
            label={t('skipBerthingTimestamp', 'Skip berthing timestamp')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name='bayRowUniqueIdentificationMode'
            formState={formState}
            control={control}
            label={t('bayRowUniqueIdentificationMode', 'Bay row unique identification mode')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'isInlandJobCreationFlow'}
            formState={formState}
            control={control}
            label={t('isInlandJobCreationFlow', 'Is Inland job creation flow')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'showUnifiedOperatorPage'}
            formState={formState}
            control={control}
            label={t('showUnifiedOperatorPage', 'Show unified operator page')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'hasGeneralCargo'}
            formState={formState}
            control={control}
            label={t('hasGeneralCargo', 'Has general cargo')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'hasDamageCatalogue'}
            formState={formState}
            control={control}
            label={t('hasDamageCatalogue', 'Has damage catalogue')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'allowGateInWhenUnitNotOnTerminal'}
            formState={formState}
            control={control}
            label={t('allowGateInWhenUnitNotOnTerminal', 'Allow gate in when unit not on terminal')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'allowDirectDelivery'}
            formState={formState}
            control={control}
            label={t('allowDirectDelivery', 'Allow direct delivery')}
          />
          <ControlledCheckbox<TenantFormProfile>
            name={'allowOperatorToSelectRailcar'}
            formState={formState}
            control={control}
            label={t('allowOperatorToSelectRailcar', 'Allow operator to select railcar')}
          />
          <ControlledSelect<TenantFormProfile>
            name='operatorViewConfigs'
            formState={formState}
            control={control}
            label={t('operatorView', 'Operator view settings')}
            options={[TerminalType.Hig, TerminalType.Inland].map(c => ({
              label: operatorViewSettingsDescription(c),
              value: c,
            }))}
          />
        </Stack>
      </FormGroup>
    </Box>
  )
}
