import { Button, CircularProgress, SxProps, Theme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, MouseEventHandler } from 'react'

interface Props {
  isLoading: boolean
  children: string
  formId?: string
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  sx?: SxProps<Theme>
  fullWidth?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  onClick?: MouseEventHandler<any>
  dataCy?: string
}

export const LoaderButton: FC<Props> = observer(
  ({
    isLoading,
    children,
    size,
    variant,
    type,
    sx,
    disabled,
    fullWidth,
    color,
    formId,
    onClick,
    dataCy,
  }: Props) => {
    return (
      <Button
        form={formId}
        sx={sx}
        startIcon={isLoading && <CircularProgress size='1rem' />}
        variant={variant}
        size={size}
        onClick={onClick}
        type={type}
        fullWidth={fullWidth}
        disabled={isLoading || disabled}
        color={color}
        data-cy={dataCy}
      >
        {children}
      </Button>
    )
  },
)
