import { Grid, Typography } from '@mui/material'
import { NonNumericOrderDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  nnrOrder: NonNumericOrderDto
}

export const NNROrderDetailsToolbar = observer(({ nnrOrder }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  if (!nnrOrder) {
    return <></>
  }

  const headerText = () => {
    const numberUnassigned = Math.max(nnrOrder.amount - nnrOrder.fulfilled, 0)
    return numberUnassigned
      ? t('xContainersToBeAssigned', '{numberUnassigned} containers to be assigned', {
          numberUnassigned: numberUnassigned,
        })
      : t('containerAssignmentFinished', 'Container assignment finished')
  }

  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: 'black', borderRadius: theme.customRadius.medium, p: '0.5rem' }}
      >
        <Grid item xs>
          <Typography variant='h4' color='white'>
            {headerText()}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
})
