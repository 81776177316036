import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt' // TODO: use from minimal theme
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CarrierType,
  CarrierVisitDto,
  ContainerJourneyDto,
  LinkedOrderResponseDto,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import IconFullOrEmptyContainer from '@planning/components/icon/IconFullOrEmptyContainer'
import IconVessel from '@planning/components/icon/IconVessel'
import { ContainerLocation } from '@planning/pages/Container/components/ContainerLocation'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { TrainIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  dropOffOrder: ContainerJourneyDto
  alertMessage?: JSX.Element[]
}

export const DropOffAutoCompleteRenderOption: FC<Props> = observer(
  ({ dropOffOrder, alertMessage }) => {
    const { t } = useTranslate()

    let order: LinkedOrderResponseDto | undefined
    let carrier: CarrierVisitDto | undefined

    if (dropOffOrder.linkedOrder) {
      order = dropOffOrder.linkedOrder
      carrier = dropOffOrder.linkedOrder?.carrierVisit
    } else {
      order = dropOffOrder
      carrier = order?.carrierVisit
    }

    const renderOpenDropOffOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <Stack direction='row' spacing='4px'>
          <IconFullOrEmptyContainer isEmpty={dropOffOrder.isEmpty ?? false} color='secondary' />
          <ArrowRightAltIcon color='secondary' />
          {order?.carrierVisitType === CarrierType.Vessel && <IconVessel color='secondary' />}
          {order?.carrierVisitType === CarrierType.Train && (
            <TrainIcon color='secondary' sx={{ width: 24, height: 24 }} />
          )}
        </Stack>
        {carrier ? (
          <>
            <Typography variant='caption' color='secondary'>
              {carrier.identifier}
            </Typography>
            {(carrier.ata || carrier.eta) && (
              <Typography variant='caption' color='secondary'>
                {formatDateTime(carrier.ata ?? carrier.eta)}
              </Typography>
            )}
          </>
        ) : (
          <ContainerLocation color='secondary' />
        )}
      </Stack>
    )

    const renderFulfilledDropOffOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <IconFullOrEmptyContainer isEmpty={dropOffOrder.isEmpty ?? false} color='secondary' />
        {order?.status === OrderStatus.Fulfilled ? (
          <>
            {order?.carrierVisitType === CarrierType.Vessel && <IconVessel color='secondary' />}
            {order?.carrierVisitType === CarrierType.Train && (
              <TrainIcon color='secondary' sx={{ width: 24, height: 24 }} />
            )}
            {carrier && (
              <Typography variant='caption' color='secondary'>
                {carrier.identifier}
              </Typography>
            )}
          </>
        ) : (
          <ContainerLocation color='secondary' />
        )}
      </Stack>
    )

    return (
      <Stack>
        <Typography
          variant='subtitle2'
          color={dropOffOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
        >
          {dropOffOrder.containerNumber?.toUpperCase()}
        </Typography>
        <Stack>
          {dropOffOrder.status === OrderStatus.Open
            ? renderOpenDropOffOrder()
            : renderFulfilledDropOffOrder()}
          {dropOffOrder.referenceNumber && (
            <Typography variant='caption' color='secondary'>
              {t('ref', 'Ref')}: {dropOffOrder.referenceNumber}
            </Typography>
          )}
          {(dropOffOrder as OrderResponseDto).yardLocation && (
            <Typography variant='caption' color='secondary'>
              {t('location', 'Location')}: {(dropOffOrder as OrderResponseDto).yardLocation}
            </Typography>
          )}
        </Stack>
        <Stack spacing={1}>
          {alertMessage?.map((alert, index) => <Box key={`alert-${index}`}>{alert}</Box>)}
        </Stack>
      </Stack>
    )
  },
)
