import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import { validateContainerNumber } from '@planning/rt-stores/helpers'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ContainerMasterDataFormViewStore } from '../../stores/ContainerMasterDataFormViewStore'
import { ContainerMasterDataFormFields } from './ContainerMasterDataFormFields'

interface Props {
  viewStore: ContainerMasterDataFormViewStore
}

export interface ContainerMasterDataFormData {
  containerNumber: string
  isoCode: string
  tare: string | number
  maxGross: string | number
  operator: string
}

const BoxtechInfoMessage: FC<{
  boxtechError: boolean
}> = ({ boxtechError }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const boxtechInfoText = t(
    'weUseBoxtechToFindContainerDetailsForYou',
    'We use Boxtech to find container details for you.',
  )
  const boxtechErrorText = t(
    'noDetailsFoundForBoxtechContainerServiceAddThemManually',
    'No details found for Boxtech container service. Add them manually.',
  )

  return (
    <Box>
      <Stack
        gap={theme.customSpacing.xxs}
        direction={'row'}
        alignItems={'center'}
        sx={{
          backgroundColor: boxtechError
            ? theme.palette.warning.lighter
            : theme.palette['grey'][200],
          color: boxtechError ? theme.palette.warning.dark : theme.palette['grey'][700],
          padding: `${theme.customSpacing.xs}`,
          borderRadius: theme.customRadius.small,
          margin: `${theme.customSpacing.xs} 0`,
        }}
      >
        <Typography variant='caption'>
          {boxtechError ? boxtechErrorText : boxtechInfoText}
        </Typography>
      </Stack>
    </Box>
  )
}

const ContainerExistsWarningMessage: FC = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const containerAlreadyExistsText = t('containerAlreadyExists', 'Container already exists.')

  return (
    <Box>
      <Stack
        gap={theme.customSpacing.xxs}
        direction={'row'}
        alignItems={'center'}
        sx={{
          backgroundColor: theme.palette.warning.lighter,
          color: theme.palette.warning.dark,
          padding: `${theme.customSpacing.xs}`,
          borderRadius: theme.customRadius.small,
          margin: `${theme.customSpacing.xs} 0`,
        }}
      >
        <Typography variant='caption'>{containerAlreadyExistsText}</Typography>
      </Stack>
    </Box>
  )
}

const BoxtechFindButton: FC<Props> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const form = useFormContext<ContainerMasterDataFormData>()

  const getContainerAttributes = useCallback(async () => {
    const containerNumber = form.getValues('containerNumber')

    if (!validateContainerNumber(containerNumber)) {
      form.setError('containerNumber', {
        type: 'manual',
        message: t(
          'invalidContainerNumber',
          'Invalid container number. Must follow format ABCU1234567.',
        ),
      })
      return
    }

    viewStore.resetBoxtechData()

    await viewStore.fetchExistingContainers(containerNumber)
    if (viewStore.showContainerExistingWarning) return

    try {
      viewStore.setBoxtechLoading(true)

      form.clearErrors('containerNumber')
      form.resetField('isoCode')
      form.resetField('operator')
      form.resetField('tare')
      form.resetField('maxGross')

      const attributes = await containerService.getAttributes(containerNumber)

      viewStore.setBoxtechContainerData(attributes)

      if (attributes.isoCode) form.setValue('isoCode', attributes.isoCode)
      if (attributes.operator) form.setValue('operator', attributes.operator)
      if (attributes.tare) form.setValue('tare', attributes.tare)
      if (attributes.maxGross) form.setValue('maxGross', attributes.maxGross)
    } catch (error) {
      viewStore.setBoxtechError(true)
    } finally {
      viewStore.setBoxtechLoading(false)
    }
  }, [form, viewStore, t])

  if (viewStore.isBoxtechLoading) {
    return <CircularProgress size={20} />
  } else if (!viewStore.isBoxtechLoading && !viewStore.containerId) {
    return (
      <Button
        type='button'
        color='inherit'
        variant='contained'
        sx={{ flex: 'none' }}
        onClick={getContainerAttributes}
      >
        {t('findDetails', 'Find details')}
      </Button>
    )
  }
})

export const ContainerMasterDataForm: FC<Props> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const form = useFormContext<ContainerMasterDataFormData>()
  const { control, formState } = form
  const { errors } = formState

  const showFindButton =
    !viewStore.boxtechContainerData &&
    !viewStore.boxtechError &&
    !viewStore.showContainerExistingWarning

  return (
    <Box
      paddingX={theme.customSpacing.l}
      paddingY={theme.customSpacing.m}
      gap={theme.customSpacing.m}
      display={'flex'}
      flexDirection={'column'}
    >
      <Grid container spacing={1} paddingTop={0.2}>
        <Grid item xs={12}>
          <Controller
            name='containerNumber'
            control={control}
            rules={{
              required: t('fieldIsRequired', 'Field is required.'),
              validate: value =>
                validateContainerNumber(value) ||
                t(
                  'invalidContainerNumber',
                  'Invalid container number. Must follow format ABCU1234567.',
                ),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t('containerNo.', 'Container no.')}`}
                required
                variant='outlined'
                fullWidth
                type='text'
                InputProps={{
                  endAdornment: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {!showFindButton ? (
                        <IconButton
                          onClick={() => {
                            field.onChange('')
                            viewStore.resetBoxtechData()
                            viewStore.resetExistingContainers()
                            form.resetField('containerNumber')
                          }}
                          sx={{ marginRight: 1, color: theme.palette.grey[600] }}
                          size='small'
                          aria-label={t('clear', 'Clear')}
                        >
                          <ClearIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <BoxtechFindButton viewStore={viewStore} />
                      )}
                    </Box>
                  ),
                  readOnly: !showFindButton,
                }}
                inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 11 }}
                error={!!errors.containerNumber}
                helperText={errors.containerNumber?.message}
                onChange={async e => {
                  field.onChange(e.target.value.toUpperCase())
                }}
              />
            )}
          />
          {viewStore.showContainerExistingWarning && <ContainerExistsWarningMessage />}
          {viewStore.showBoxTechInfoMessage && (
            <BoxtechInfoMessage boxtechError={viewStore.boxtechError} />
          )}
        </Grid>

        {(viewStore.boxtechContainerData || viewStore.boxtechError || viewStore.containerId) && (
          <ContainerMasterDataFormFields />
        )}
      </Grid>
    </Box>
  )
})
