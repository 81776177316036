import {
  ContainerAttributesDto,
  ContainerDto,
  SyncStatus,
  UpsertContainerCommand,
} from '@planning/app/api'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { containerService } from '@planning/services'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { ContainerMasterDataFormData } from '../components/Organisms/ContainerMasterDataForm'

export class ContainerMasterDataFormViewStore {
  isLoading = false
  isBoxtechLoading = false
  boxtechError = false
  boxtechContainerData: ContainerAttributesDto | undefined
  containerId: number | undefined
  existingContainers: ContainerDto[] = []

  constructor(public containerItemStore: ContainerItemStore) {
    makeObservable(this, {
      isLoading: observable,
      isBoxtechLoading: observable,
      boxtechError: observable,
      boxtechContainerData: observable,
      containerId: observable,
      existingContainers: observable,

      isEditMode: computed,
      container: computed,
      showContainerExistingWarning: computed,

      setContainerId: action,
      setLoading: action,
      setBoxtechLoading: action,
      setBoxtechError: action,
      setBoxtechContainerData: action,
      resetBoxtechData: action,
      resetExistingContainers: action,
    })
  }

  get container() {
    return this.containerId ? this.containerItemStore.elements[this.containerId] : undefined
  }

  get isEditMode() {
    return !!this.containerId
  }

  get showContainerExistingWarning() {
    return this.existingContainers.length > 0
  }

  get showBoxTechInfoMessage() {
    return !this.showContainerExistingWarning && !this.boxtechContainerData && !this.containerId
  }

  submit = async (data: ContainerMasterDataFormData) => {
    this.setLoading(true)

    if (typeof data.tare === 'string') data.tare = parseFloat(data.tare)
    if (typeof data.maxGross === 'string') data.maxGross = parseFloat(data.maxGross)

    try {
      const cmd: UpsertContainerCommand = {
        number: data.containerNumber,
        isoCode: data.isoCode,
        operator: data.operator,
        tare: data.tare,
        maxGross: data.maxGross,
        syncStatus: SyncStatus.Success,
      }
      await containerService.post(cmd)
    } finally {
      this.setLoading(false)
    }
  }

  fetchExistingContainers = async (containerNumber: string) => {
    const response = await containerService.getContainersByPartialNumber(containerNumber)
    runInAction(() => {
      this.existingContainers = response.filter(container => container.number === containerNumber)
    })
  }

  setContainerId = (containerId: number | undefined) => (this.containerId = containerId)

  setLoading = (loading: boolean) => (this.isLoading = loading)

  setBoxtechLoading = (loading: boolean) => (this.isBoxtechLoading = loading)

  setBoxtechError = (error: boolean) => (this.boxtechError = error)

  setBoxtechContainerData = (container: ContainerAttributesDto | undefined) =>
    (this.boxtechContainerData = container)

  resetExistingContainers = () => {
    this.existingContainers = []
  }

  resetBoxtechData = () => {
    this.setBoxtechContainerData(undefined)
    this.setBoxtechError(false)
  }
}
