import { useLocation, useNavigate } from 'react-router-dom'

export const useHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousPage = location.key !== 'default'

  const goBackHistoryOrDefault = (defaultUrl: string) => {
    if (hasPreviousPage) navigate(-1)
    else navigate(defaultUrl)
  }

  return { goBackHistoryOrDefault }
}
