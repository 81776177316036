import { Box, Button } from '@mui/material'
import { CheckType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { IInspectionRailCar, RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { CustomAlert } from '../CustomAlert'
import { NavigationStackBackButton } from '../HeaderComponents/NavigationStackBackButton'
import { InspectRailcarList } from './InspectRailcarList'

interface IProps {
  store: RailTallyViewStore
  onSelectRailcar: (railcarId?: number) => void
  onDeleteRailcar: (railcar: IInspectionRailCar) => void
  onNewRailcar: () => void
  onReportRailcarIssue: (railcar: IInspectionRailCar) => void
}

export const InspectSearchRailcars: FC<IProps> = observer(
  ({ store, onSelectRailcar, onNewRailcar, onDeleteRailcar, onReportRailcarIssue }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
      <>
        <Header
          filterTestId='search-railcar-autocomplete'
          leftRenderOption={<NavigationStackBackButton />}
          enableSearchField
          onSearchFieldChange={store.setSearchFilter}
          searchFilter={store.searchFilter}
        />

        <Box
          style={{
            backgroundColor: theme.palette.grey[200],
            minHeight: 'calc(100vh - 65px)',
          }}
        >
          <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
            {!store.searchFilter && (
              <CustomAlert
                message={t(
                  'hintTryTheLastFourDigitsOfTheContainerNumber',
                  'Hint, try the last 4 digits of the railcar number',
                )}
                severity='info'
              />
            )}
            {!!store.searchFilter && (
              <InspectRailcarList
                store={store}
                operation={store.selectedOperationType}
                searchHighlight={store.searchFilter}
                railcars={store.filteredRailcars}
                confirmedRailcarIds={store.confirmedRailcarIds}
                onSelectRailcar={onSelectRailcar}
                onDeleteRailcar={onDeleteRailcar}
                onReportRailcarIssue={onReportRailcarIssue}
              />
            )}

            {!!store.searchFilter && !store.filteredRailcars?.length && (
              <CustomAlert
                severity='warning'
                action={
                  store.selectedOperationType !== CheckType.DepartureCheck ? (
                    <Button color='inherit' onClick={onNewRailcar}>
                      {t('create', 'Create')}
                    </Button>
                  ) : (
                    <></>
                  )
                }
                message={t('noMatchingRailcarFoundForInput', 'No matching railcar found for input')}
              />
            )}
          </ContainerMobile>
        </Box>
      </>
    )
  },
)
