import { Box, Button, Stack, Typography } from '@mui/material'
import { ContainerDto, UnitType } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  container: ContainerDto
  onCreateOrder?: (container: ContainerDto) => void
}

export const ContainerCard: FC<Props> = observer(({ container, onCreateOrder }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  let icon

  if (container.unitType === UnitType.Container) {
    const { icon: containerIcon } = getContainerTypeParameters(container.type!, t)
    icon = containerIcon
  } else {
    icon = useUnitTypeIcon(container.unitType)
  }

  const renderContainerAttr = (attribute: string, label: string) => (
    <Box sx={{ mr: '2rem' }}>
      <Typography variant='body2' color={theme.palette.grey[600]}>
        {attribute}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '.25rem' }}>
        <Typography variant='subtitle2'>{label}</Typography>
      </Box>
    </Box>
  )

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        mt: '1rem',
      }}
    >
      {/* Card Header */}
      <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {icon}
          <Typography variant='h4'>{container.number}</Typography>
        </Stack>

        {!!onCreateOrder && (
          <Button color='primary' variant='contained' onClick={() => onCreateOrder(container)}>
            {t('createOrder', 'Create order')}
          </Button>
        )}
      </Box>

      {/* Card Body */}
      <Box
        sx={{
          bgcolor: theme.palette.grey[200],
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          display: 'flex',
          padding: '1rem',
        }}
      >
        {renderContainerAttr(t('type', 'Type'), container.type || emptyIndicator)}
        {renderContainerAttr(
          t('length', 'Length'),
          `${container.length ? container.length + 'ft' : emptyIndicator}`,
        )}
        {renderContainerAttr(
          t('tareWeight', 'Tare weight'),
          `${container.tare ? container.tare + ' kg' : emptyIndicator}`,
        )}
        {renderContainerAttr(
          t('maxGrossWeight', 'Max gross weight'),
          `${container.maxGross ? container.maxGross + ' kg' : emptyIndicator}`,
        )}
      </Box>
    </Box>
  )
})
