import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  CreateRailcarTrackPositionCommand,
  RailVisitResponseDto,
} from '@planning/app/api'
import { LoaderButton } from '@planning/components/LoaderButton'
import { RailcarControlledAutocomplete } from '@planning/components/autocompletes/railcar-autocomplete/RailcarControlledAutocomplete'
import { railVisitService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import {
  notAllowPlusMinusPointSign,
  notAllowPlusMinusSign,
  onlyAllowPositiveInt,
} from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import RailTrackControlledAutocomplete from '../Order/components/RailTrackControlledAutocomplete'

interface Props {
  direction: CarrierVisitDirection
  railVisit: RailVisitResponseDto
}

interface IEmptyRailcarForm {
  railcarId: number
  railTrackId: string
  length: number
  maxPayload: number
  sequenceNumber: number
}

export const AddEmpytRailcarForm: FC<Props> = observer(({ direction, railVisit }) => {
  const { appViewStore, dialogStore, railcarTrackPositionItemStore, railVisitItemStore } =
    usePlanningStore()

  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const getRailTracksByVisit = useCallback(
    (visitId?: number) => {
      const railTracks = railcarTrackPositionItemStore.getRailTracks()

      if (visitId) {
        const railTrackIds = railVisitItemStore.getById(visitId)?.data.assignedRailTrackIds || []
        return railTracks.filter(x => railTrackIds.includes(x.id))
      }

      return railTracks
    },
    [railVisitItemStore, railcarTrackPositionItemStore],
  )

  const railTracks = computed(() => getRailTracksByVisit(railVisit.id)).get()

  const onSubmit = async (data: IEmptyRailcarForm) => {
    try {
      appViewStore.showLoader()

      const cmd: CreateRailcarTrackPositionCommand = {
        railcarId: data.railcarId,
        railTrackId: data.railTrackId,
        railVisitId: railVisit.id,
        railcarLength: data.length,
        railcarMaxPayload: data.maxPayload,
        sequenceNumber: data.sequenceNumber,
        direction: direction,
      }

      await railVisitService.createEmptyRailcar(cmd)

      dialogStore.close()
      appViewStore.setShowAlert(
        'success',
        t('railcarAddedSuccessfully', 'Railcar added successfully'),
      )
    } catch (error: any) {
      if (error.response.data.status === 400 && error.response.data.detail) {
        appViewStore.setShowAlert('error', error.response.data.detail)
      }
    } finally {
      appViewStore.hideLoader()
    }
  }

  const Title = () => (
    <Typography variant='h4' m='1rem 0' align='center'>
      {t('createEmptyRailcar', 'Create empty railcar')}
    </Typography>
  )

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEmptyRailcarForm>()

  return (
    <>
      <Title />
      <DialogContent
        sx={{
          p: '2rem',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <>
          <Typography variant='subtitle1'>
            {t('railcarInformation', 'Railcar information')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <RailTrackControlledAutocomplete
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  name='railTrackId'
                  label={t('railTrack', 'Rail Track')}
                  options={railTracks}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <RailcarControlledAutocomplete
                  control={control}
                  errors={errors}
                  name='railcarId'
                  label={t('railcar', 'Railcar')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  data-cy='railcar-length'
                  fullWidth
                  label={`${t('length', 'Length')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        m
                      </CustomInputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: '0',
                    step: '0.01',
                    pattern: '[0-9]*',
                  }}
                  {...register('length', { valueAsNumber: true })}
                  onKeyDown={notAllowPlusMinusSign}
                  name='length'
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name='maxPayload'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      label={`${t('maxPayload', 'Max Payload')}`}
                      variant='outlined'
                      type='text'
                      error={!!errors.length}
                      value={field.value ?? ''}
                      InputProps={{
                        endAdornment: (
                          <CustomInputAdornment position='end' color='secondary'>
                            kg
                          </CustomInputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  data-cy='railcar-sequence'
                  fullWidth
                  label={t('sequenceNo', 'Sequence no.')}
                  required
                  variant='outlined'
                  type='number'
                  {...register('sequenceNumber', { required: true, valueAsNumber: true, min: 1 })}
                  name='sequenceNumber'
                  onKeyDown={notAllowPlusMinusPointSign}
                  onChange={onlyAllowPositiveInt}
                  error={!!errors.sequenceNumber}
                  helperText={
                    errors.sequenceNumber ? t('fieldIsRequired', 'Field is required.') : undefined
                  }
                />
              </Grid>
            </Grid>
          </form>
        </>
      </DialogContent>

      <DialogActions sx={{ borderTop: '1px solid rgba(145, 158, 171, 0.24)' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <LoaderButton
            variant={'contained'}
            color={'primary'}
            type='submit'
            onClick={handleSubmit(onSubmit)}
          >
            {t('save', 'Save')}
          </LoaderButton>
          <Button variant='text' onClick={() => dialogStore.close()} color='inherit'>
            {t('cancel', 'Cancel')}
          </Button>
        </Box>
      </DialogActions>
    </>
  )
})
