import { createSvgIcon } from '@mui/material'

export const ArrowDownRightIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.6571 6.34314L15.663 6.34314L15.656 14.2415L7.05048 5.63604L5.63627 7.05025L14.2418 15.6557L6.34338 15.6487L6.34338 17.6569L17.6571 17.6569L17.6571 6.34314Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowDownRightIcon',
)
