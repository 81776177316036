import { ListItemText, MenuItem, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { getJobAmountBySize } from '@operations/app/models/operator-pages'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  jobs: JobDto[]
  selectedSize?: number | string
  onSelectSize: (size?: number | string) => void
}
export const JobFilterSizes = observer(({ jobs, selectedSize, onSelectSize }: Props) => {
  const { t } = useTranslate()

  const sizeOptions: SelectOption[] = [
    {
      value: '',
      label: t('all', 'All'),
    },
    {
      value: 20,
      label: '20’',
    },
    {
      value: 40,
      label: '40’',
    },
    {
      value: 45,
      label: '45’',
    },
    {
      value: 'others',
      label: t('others', 'Others'),
    },
  ]

  const sizeOptionsElement = sizeOptions.map(option => (
    <MenuItem
      key={option.value ?? 0}
      selected={option.value === selectedSize}
      value={option.value}
      onClick={() => {
        onSelectSize(option.value)
      }}
    >
      <ListItemText primary={option.label} primaryTypographyProps={{ variant: 'body2' }} />
      <Typography variant='body2' color='secondary'>
        {getJobAmountBySize(jobs, option.value)}
      </Typography>
    </MenuItem>
  ))

  return (
    <ChipWithOptions
      value={sizeOptions.find(x => x.value === selectedSize)?.label}
      label={t('size', 'Size')}
      onReset={() => onSelectSize()}
      options={sizeOptionsElement}
    />
  )
})
