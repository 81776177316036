import { FilterChips } from '@planning/components/molecules/FilterChips'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { IssuesViewStore } from '../../Stores/IssuesViewStore'

interface Props {
  store: IssuesViewStore
  handleFilterRemove?: (key: string) => void
}

export const IssuesFilterChips = observer(({ store, handleFilterRemove }: Props) => {
  const { t } = useTranslate()

  const onFilterRemove = (key: string) => {
    if (key === 'visit') {
      store.setFilterByVisitId()
    } else if (key === 'railCarNo') {
      store.setFilterByRailCarTrackPositionId()
    } else if (key === 'carrierName') {
      store.enableFilters(false)
      store.setCarrierNameFilter('')
    }

    if (handleFilterRemove) handleFilterRemove(key)
  }

  const activeFilters = computed(() => {
    const filters = []
    if (store.filterByVisitId) {
      filters.push({
        text: store.railVisitFilterName,
        key: 'visit',
      })
    }
    if (store.filterByRailcarTrackPositionId) {
      filters.push({
        text: `${t('railCarNo', 'Railcar no.')} ${store.railcarTrackPositionFilterName}`,
        key: 'railCarNo',
      })
    }
    if (store.isFilterEnabled && store.carrierNameFilter) {
      filters.push({
        text: `${t('carrierName', 'Carrier Name')}: ${store.carrierNameFilter}`,
        key: 'carrierName',
      })
    }
    return filters
  }).get()

  return (
    <FilterChips
      activeFilters={activeFilters}
      onFilterRemove={(key: string) => onFilterRemove(key)}
    />
  )
})
