import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Loader, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { ContainerMasterDataList } from './components/Organisms/ContainerMasterDataList'
import { ContainerMasterDataPageHeader } from './components/Organisms/ContainerMasterDataPageHeader'
import { ContainerMasterDataListViewStore } from './stores/ContainerMasterDataListViewStore'

export const ContainerMasterDataPage = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [param, setParam] = useSearchParams()
  const filter = param.get('filter') ?? ''

  const containerMasterDataListViewStore = useMemo(() => new ContainerMasterDataListViewStore(), [])

  useEffect(() => {
    if (filter) containerMasterDataListViewStore.setFilter(filter)
  }, [containerMasterDataListViewStore, filter])

  const { loading } = useAsyncFetch(() => containerMasterDataListViewStore.fetch(1, 20, filter))

  return (
    <Box
      sx={{
        minHeight: '100%',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Helmet>
        <title>{t('containerMasterData', 'Container master data')}</title>
      </Helmet>

      <Loader show={containerMasterDataListViewStore.isLoading || loading} />

      <ContainerMasterDataPageHeader
        onFilter={(filter: string) => {
          setParam({ filter })
          containerMasterDataListViewStore.onFilter(filter)
        }}
        defaultSearchFilter={filter}
      />
      <ContainerMasterDataList viewStore={containerMasterDataListViewStore} />
    </Box>
  )
})
