import { Divider, Stack } from '@mui/material'
import { OperationType, VesselBayDto } from '@operations/app/api'
import { deckSeq, rowSeq, tierSeq } from '../../utils'
import { BayHeader } from './BayHeader'
import { RowHeader } from './RowHeader'
import { TierPanel } from './Tier'

export interface Props {
  bayGeometry: VesselBayDto
  bayName?: string
  equipmentId: number
  zoom: number
  operationType: OperationType
  workStatusPerBay: any[] | undefined
}

const BayView = ({
  bayGeometry,
  bayName,
  equipmentId,
  zoom,
  operationType,
  workStatusPerBay,
}: Props) => {
  return (
    <Stack alignItems='center'>
      <BayHeader
        bayDto={bayGeometry}
        zoom={zoom}
        bayName={bayName}
        operationType={operationType}
        workStatusPerBay={workStatusPerBay}
      />

      {deckSeq(bayGeometry.deckHeight, bayGeometry.deckStartedAt).map(tier => {
        return (
          <TierPanel
            key={'tier' + tier}
            bay={bayGeometry.bay}
            tier={tier}
            maxRow={bayGeometry.maxRow}
            hasZero={bayGeometry.hasZeroRow}
            slots={bayGeometry.deckSlots.filter(ds => ds.tier === tier)}
            currentEquipmentId={equipmentId}
            zoom={zoom}
            operationType={operationType}
          />
        )
      })}

      <Divider
        sx={{
          width: `${rowSeq(bayGeometry.maxRow, bayGeometry.hasZeroRow).length * 7.5 * zoom}rem`,
          borderColor: '#161C24',
          marginTop: `${16 * zoom}px`,
          marginBottom: `${8 * zoom}px`,
          borderBottomWidth: `${4 * zoom < 1 ? 1 : 4 * zoom}px`,
        }}
      />

      {tierSeq(bayGeometry.minTier, bayGeometry.maxTier - bayGeometry.minTier + 1).map(tier => {
        return (
          <TierPanel
            key={'tier' + tier}
            bay={bayGeometry.bay}
            tier={tier}
            maxRow={bayGeometry.maxRow}
            hasZero={bayGeometry.hasZeroRow}
            slots={bayGeometry.holdSlots.filter(hs => hs.tier === tier)}
            currentEquipmentId={equipmentId}
            zoom={zoom}
            operationType={operationType}
          />
        )
      })}
      <RowHeader zoom={zoom} maxRow={bayGeometry.maxRow} hasZero={bayGeometry.hasZeroRow} />
      <br />
    </Stack>
  )
}

export default BayView
