import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material'
import {
  ContainerPositionType,
  ContainerStatus,
  ContainerTurnoverDto,
  YardInternalMovesApiCreateMoveRequest,
} from '@storage/app/api'
import { ConfirmationDialogContainer } from '@storage/components/ConfirmationDialogContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { ManualPlanningTypes } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { moveContainerService } from '@storage/services'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { GeneralIcon, useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo } from '@tom-ui/utils'
import { useState } from 'react'
import { ContainerPositionBox } from './components'
import { ContainerTurnoverAttributes } from './components/ContainerTurnoverAttributes'

interface Props {
  item: ContainerTurnoverDto
  variation?: 'standard' | 'compact'
  clickable?: boolean
  onClick?: () => void
  onPlanOnYard?: (id: string) => void
  onManualPlanning?: (id: string, planningType: ManualPlanningTypes) => void
  onMoveItemManually?: (containers: ContainerMovementInfo[]) => void
  tenantConfigStore?: TenantConfigStore
}

export const ContainerTurnoversListItem = ({
  item,
  variation = 'standard',
  clickable,
  onClick,
  onManualPlanning,
  onMoveItemManually,
  tenantConfigStore,
}: Props) => {
  const {
    containerNumber,
    targetPosition,
    targetAllocation,
    currentPosition,
    status,
    terminalEntry,
    terminalExit,
    positionType,
  } = item

  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const handleManualPlanningClick = () => {
    if (onManualPlanning) {
      onManualPlanning(item.id, status === ContainerStatus.OnTheYard ? 'internalMove' : 'planning')
    }
  }

  const handleManualMoveClick = () => {
    if (onMoveItemManually) {
      onMoveItemManually([
        {
          containerNumber: item.containerNumber,
          size: item.size,
          isEmpty: item.isEmpty ?? false,
          isDangerous: item.isDangerous,
        },
      ])
    }
  }

  const clickableStyle = {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
    },
  }
  const { snackbarStore } = useStores()

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleMoveContainer = async () => {
    const request: YardInternalMovesApiCreateMoveRequest = {
      yardInternalMovesCreateMoveRequest: {
        containerTurnoverId: item.id,
      },
    }
    try {
      await moveContainerService.moveContainer(request)
      setIsButtonDisabled(true)
      snackbarStore.showMessage(
        t('containerSuccessfullyMoved', 'The container move has been triggered'),
        'success',
      )
    } catch (e) {
      snackbarStore.showMessage(
        t('containerMoveFailed', 'The container move was not triggered'),
        'success',
      )
    } finally {
      setIsConfirmationDialogOpen(false)
    }
  }

  return (
    <Box data-cy='container-turnovers-list-item'>
      <Card
        onClick={onClick}
        elevation={5}
        sx={clickable ? clickableStyle : {}}
        data-cy={`container-turnovers-list-item-${containerNumber}`}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {variation === 'standard' ? (
                <>
                  <GeneralIcon />
                  <Typography variant='h4' data-cy='container-number'>
                    {containerNumber}
                  </Typography>
                </>
              ) : (
                <Typography variant='h3'>{containerNumber}</Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {variation === 'standard' ? (
                <Box>
                  <ContainerPositionBox
                    status={status}
                    currentPosition={currentPosition}
                    targetPosition={targetPosition}
                    targetAllocation={targetAllocation}
                    terminalEntry={terminalEntry}
                    terminalExit={terminalExit}
                  />
                </Box>
              ) : (
                <Box>
                  <ContainerPositionBox
                    status={status}
                    currentPosition={currentPosition}
                    targetPosition={targetPosition}
                  />
                </Box>
              )}

              {variation === 'standard' && (
                <Box>
                  {(tenantConfigStore && !tenantConfigStore.skipYardPlanning) || !tenantConfigStore
                    ? (currentPosition.length > 0 && (
                        <Button onClick={handleManualMoveClick}>{t('move', 'Move')}</Button>
                      )) || (
                        <Button data-cy='plan' onClick={handleManualPlanningClick}>
                          {t('plan', 'Plan')}
                        </Button>
                      )
                    : tenantConfigStore.skipYardPlanning &&
                      currentPosition.length > 0 && (
                        <Button
                          onClick={() => setIsConfirmationDialogOpen(true)}
                          disabled={
                            positionType === ContainerPositionType.Handover || isButtonDisabled
                          }
                        >
                          {positionType === ContainerPositionType.Handover || isButtonDisabled
                            ? t('moveTriggered', 'Move triggered')
                            : t('triggerMove', 'Trigger move')}
                        </Button>
                      )}
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
        <Divider variant='fullWidth' />
        <Box sx={{ padding: 2, backgroundColor: palette.secondary.lighter }}>
          <ContainerTurnoverAttributes item={item} variation={variation} />
        </Box>
      </Card>
      <ConfirmationDialogContainer
        open={isConfirmationDialogOpen}
        title={t('confirmMove', 'Confirm Move')}
        content={
          <Typography>
            {t('confirmMoveText', 'Are you sure you want to move this container?')}
          </Typography>
        }
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleMoveContainer}
      />
    </Box>
  )
}
