import { Box, Typography } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { OrderListItem } from '@planning/components/organisms/OrderListItem'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'
import { HeaderWithBreadcrumbs } from './components/Organisms/HeaderWithBreadcrumbs'

export const OrdersList: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { appViewStore, orderListViewStore: store } = usePlanningStore()

  const { navigateBookingOrderPage } = useOrderNavigation()
  const navigate = useNavigate()

  const { bookingNumber, visitId } = useParams()

  const { loading, error } = useAsyncFetch(async () => {
    if (bookingNumber) await store.fetch(bookingNumber, Number(visitId))
  }, [store, bookingNumber, visitId])

  if (error) {
    return <Typography variant='h4'>{t('errorLoadingOrders', 'Error loading orders')}</Typography>
  }

  if (!loading && store.isEmpty) {
    navigate('/bookings')
  }

  const onDeleteOrder = async (order: OrderResponseDto) => {
    const hasServiceOrders = order.hasServiceOrders

    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t('orderWillBeDeletedContinue', 'Order will be deleted. Continue?'),
      t('confirmOrderDelete', 'Confirm order delete'),
      hasServiceOrders
        ? t(
            'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
            'Any associated stripping/stuffing orders will be deleted',
          )
        : undefined,
    )

    if (!isConfirmed) return

    try {
      await orderService.deleteByIds([order.id])

      appViewStore.setShowAlert(
        'success',
        t('successfullyDeletedOrder', 'Successfully deleted order'),
      )
    } catch (e) {
      appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
    }
  }

  if (!bookingNumber) return <></>

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box sx={{ backgroundColor: theme.palette.grey[200], minHeight: '100%' }}>
        <HeaderWithBreadcrumbs bookingNumber={bookingNumber} store={store} />
        {!store.isNoResults && (
          <Box
            m={theme.customSpacing.l}
            bgcolor={'white'}
            border={`1px solid ${theme.palette.grey[300]}`}
          >
            {store.filteredOrders.map(order => {
              return (
                <OrderListItem
                  key={order.id}
                  order={order}
                  filter={store.filter}
                  options={[
                    {
                      label: 'Order Details',
                      onClick: () => navigateBookingOrderPage(order),
                    },
                    {
                      label: 'Delete',
                      color: 'error',
                      onClick: () => onDeleteOrder(order),
                    },
                  ]}
                />
              )
            })}
          </Box>
        )}

        {store.isNoResults && <NoListResult result='No orders found' />}
      </Box>
    </InitializationWrapper>
  )
})
