import {
  CarrierType,
  EquipmentType,
  OperationType,
  OperatorFields,
  TenantConfigDto,
  TenantConfigsApi,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class TenantConfigStore {
  tenantConfig?: TenantConfigDto

  constructor(private messageBus: IMessageBus) {
    makeObservable(this, {
      tenantConfig: observable,
      skipCraneSplit: computed,
      skipVesselTally: computed,
      operatorViewSettings: computed,
      handleTenantUpserted: action,
    })

    this.messageBus.subscribeEvent<TenantConfigDto>(
      EventTypes.TenantUpserted,
      this.handleTenantUpserted,
    )
  }

  handleTenantUpserted = (res: IEvent<TenantConfigDto>) => {
    const upsertedRecord = res.payload

    if (!upsertedRecord) return
    this.tenantConfig = upsertedRecord
  }

  async loadConfigs() {
    const { data } = await createApiClient(TenantConfigsApi).getAll()

    runInAction(() => {
      this.tenantConfig = data
    })
  }

  public get skipCraneSplit(): boolean {
    return this.tenantConfig?.skipCraneSplit ?? false
  }

  public get skipVesselTally(): boolean {
    return this.tenantConfig?.skipVesselTally ?? false
  }

  public get skipRailTally(): boolean {
    return this.tenantConfig?.skipRailTally ?? false
  }

  public get showUnifiedOperatorPage(): boolean {
    return this.tenantConfig?.showUnifiedOperatorPage ?? false
  }

  public get skipYardPlanning(): boolean {
    return this.tenantConfig?.skipYardPlanning ?? false
  }

  public get allowOperatorToSelectRailcar(): boolean {
    return this.tenantConfig?.allowOperatorToSelectRailcar ?? false
  }

  public getOperatorFieldsByEquipmentAndOperationTypeAndCarrierType(
    equipmentType: EquipmentType,
    operationType: OperationType,
    carrierType?: CarrierType | null,
  ): OperatorFields | undefined {
    const operatorFields = this.tenantConfig?.operatorViewSettings[equipmentType]?.operatorFields

    if (!operatorFields?.length) {
      return undefined
    }

    let fields: OperatorFields | undefined

    if (carrierType) {
      fields = operatorFields.find(
        x => x.carrierTypes?.includes(carrierType) && x.operationTypes?.includes(operationType),
      )?.fields
      if (fields) {
        return fields
      }

      fields = operatorFields.find(
        x => x.carrierTypes?.includes(carrierType) && !x.operationTypes.length,
      )?.fields
      if (fields) {
        return fields
      }
    }

    fields = operatorFields.find(
      x => x.operationTypes?.includes(operationType) && !x.carrierTypes.length,
    )?.fields
    if (fields) {
      return fields
    }

    return operatorFields.find(x => !x.carrierTypes.length && !x.operationTypes.length)?.fields
  }

  public get operatorViewSettings() {
    return this.tenantConfig?.operatorViewSettings
  }
}
