import {
  CreateIsoCodeMappingCommand,
  IsoCodeMappingResponseDto,
  IsoCodeMappingsApi,
  UpdateIsoCodeMappingCommand,
} from '@admin/app/api'
import { BaseEntityStore } from './base/BaseEntityStore'

export class IsoCodeMappingStore extends BaseEntityStore<IsoCodeMappingResponseDto> {
  constructor(private readonly api: IsoCodeMappingsApi) {
    super()
  }

  async load() {
    const { data } = await this.api.isoCodeMappingsGet()
    this.updateStoreItems(data)
  }

  async add(createIsoCodeMappingCommand: CreateIsoCodeMappingCommand) {
    const { data: id } = await this.api.isoCodeMappingsPost(createIsoCodeMappingCommand)
    this.updateStoreItem({ ...createIsoCodeMappingCommand, id }, id)
    return id
  }

  async update(updateIsoCodeMappingCommand: UpdateIsoCodeMappingCommand) {
    const id = updateIsoCodeMappingCommand.id
    await this.api.isoCodeMappingsPut(updateIsoCodeMappingCommand)
    this.updateStoreItem({ ...updateIsoCodeMappingCommand, id, length: 0 }, id)
    return id
  }

  async delete(id: number) {
    await this.api.isoCodeMappingsDelete(id)
    this.deleteStoreItem(id)
  }
}
