import { useAuth0 } from '@auth0/auth0-react'
import { PATHS } from '@host/app/paths'
import { RequiredFeatureFlag, routes } from '@host/app/routes'
import { useBooleanFlagValue, useOpenFeatureClient } from '@openfeature/react-sdk'
import * as Sentry from '@sentry/react'
import { useAdminStore } from '@tom-ui/admin'
import { useOperationsStore } from '@tom-ui/operations'
import {
  AppDialog as PlanningDialog,
  DialogStack as PlanningDialogStack,
  DrawerStack as PlanningDrawerStack,
  usePlanningStore,
} from '@tom-ui/planning'
import {
  ConfirmationDialogContainer as StorageConfirmationDialogContainer,
  useStorageStore,
} from '@tom-ui/storage'
import { Loader } from '@tom-ui/ui'
import {
  AppLoader,
  AuthGuard,
  FeatureFlagProvider,
  SnackbarToast,
  authStore,
  useAppConfiguration,
} from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { Layout } from './Layout'
import { ContainerMovementDialog } from './components/container-movement-dialog/ContainerMovementDialog'

const mainAppSentryDns =
  'https://40ceaed00cff4203941b61553223e8db@o4505073926406144.ingest.sentry.io/4505397101658112'
const currentHost = window.location.hostname

export const App = observer(() => {
  const { environment } = useAppConfiguration()

  useEffect(() => {
    if (environment && !currentHost.includes('localhost') && !currentHost.includes('127.0.0.1')) {
      Sentry.init({
        dsn: mainAppSentryDns,
        beforeSend(event, hint) {
          const originalException = hint.originalException as any

          if (
            originalException?.name === 'AxiosError' &&
            originalException?.response?.status < 500
          ) {
            return null
          }

          return event
        },
        environment: environment,
      })
    }
  }, [environment])

  const { isLoading, isAuthenticated, logout, error, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // On Safari, if you are already logged in and then open a new tab with a specific page URL, it won't automatically log you in again.
      // On Chrome, this won't happen, so it won't go into this if block.
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}`,
        },
      })
    }
  }, [isAuthenticated, isLoading, loginWithRedirect])

  useEffect(() => {
    if (error) {
      alert(`${error.message}
        If you have just signed up, we have sent you an email, please verify your email address there.
        Be aware that, the email address must end with omoqo.com, and a tenantId should be set.
      `)
      logout({ logoutParams: { returnTo: window.location.origin } })
    }
  }, [error, logout])

  if (isLoading) {
    return <Loader show />
  }

  return <AuthStoreWrapper />
})

const AuthStoreWrapper = observer(() => {
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0()

  const { tenantConfigStore: operationsTenantConfigStore, equipmentStore } = useOperationsStore()
  const { tenantConfigStore: storageTenantConfigStore } = useStorageStore()
  const { tenantStore: planningTenantStore } = usePlanningStore()
  const { tenantStore } = useAdminStore()

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      authStore.fetchAuth(getAccessTokenSilently)

      tenantStore.loadConfigs()
      operationsTenantConfigStore.loadConfigs()
      storageTenantConfigStore.loadAppConfigs()
      planningTenantStore.fetch()
      equipmentStore.load()
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    tenantStore,
    operationsTenantConfigStore,
    storageTenantConfigStore,
    planningTenantStore,
    equipmentStore,
  ])

  if (!authStore.isLoaded) {
    return <Loader show />
  }

  return (
    <FeatureFlagProvider context={{ user_id: authStore.claims.sub }}>
      <AppContent />
    </FeatureFlagProvider>
  )
})

const AppContent = observer(() => {
  const { confirmationDialogStore: storageConfirmationDialogStore } = useStorageStore()
  const { tenantStore } = useAdminStore()
  const featureFlagClient = useOpenFeatureClient()

  const checkRequiredFeatureFlag = (requiredFeatureFlag?: RequiredFeatureFlag) => {
    return (
      !requiredFeatureFlag ||
      featureFlagClient.getBooleanValue(requiredFeatureFlag.name, false) ===
        requiredFeatureFlag.enable
    )
  }

  const visitDepartedWithUnfinishedJobsIssue = useBooleanFlagValue(
    'visit-departed-with-unfinished-jobs-issue',
    false,
  )

  const bulkAddCustomsToOrders = useBooleanFlagValue('bulk-add-customs-to-orders', false)

  return (
    <Layout>
      <Routes>
        <Route path='/' index element={<Navigate to={PATHS.profile} />} />
        {routes({
          skipCraneSplit: tenantStore.skipCraneSplit,
          showUnifiedOperatorPage: tenantStore.showUnifiedOperatorPage,
          skipVesselTally: tenantStore.skipVesselTally,
          skipRailTally: tenantStore.skipRailTally,
          hasGeneralCargo: tenantStore.hasGeneralCargo,
          hasDamageCatalogue: tenantStore.hasDamageCatalogue,
          skipYardPlanning: tenantStore.skipYardPlanning,
          issuePageV2: visitDepartedWithUnfinishedJobsIssue,
          bookingOrderListV2: bulkAddCustomsToOrders,
        })
          .filter(x => !x.hidden)
          .map(route => {
            return (
              route.component &&
              checkRequiredFeatureFlag(route.requiredFeatureFlag) && (
                <Route
                  key={`route-${route.path}`}
                  path={route.path}
                  element={
                    route.requiredPermission ? (
                      <AuthGuard
                        component={route.component}
                        requiredPermission={route.requiredPermission}
                      />
                    ) : (
                      <route.component />
                    )
                  }
                />
              )
            )
          })}
      </Routes>

      <PlanningDrawerStack />
      <PlanningDialogStack />
      <PlanningDialog />

      <StorageConfirmationDialogContainer
        open={storageConfirmationDialogStore.isOpen}
        title={storageConfirmationDialogStore.title ?? ''}
        content={<>{storageConfirmationDialogStore.message}</>}
        onClose={() => storageConfirmationDialogStore.answer(false)}
        onConfirm={() => storageConfirmationDialogStore.answer(true)}
      />

      <ContainerMovementDialog />

      <AppLoader />
      <SnackbarToast />
    </Layout>
  )
})
