import { Box } from '@mui/system'
import { CarrierVisitDirection, OrderResponseDto, UnitType } from '@planning/app/api'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const IncompleteJobsWarningMessageOrder: FC<{
  order: OrderResponseDto
}> = observer(({ order }) => {
  const { t } = useTranslate()

  const isDropOff = order.direction === CarrierVisitDirection.Inbound

  const isContainer = !!order.containerId

  const containerMessage = isDropOff
    ? t('containerDropOff', 'Container drop off')
    : t('containerPickUp', 'Container pick up')
  const nnOrderMessage = isDropOff
    ? t('nnOrderDropOff', 'NN order drop off ref')
    : t('nnOrderPickUp', 'NN order pick up ref')
  const cargoMessage = isDropOff
    ? t('cargoDropOff', 'Cargo drop off ref')
    : t('cargoPickUp', 'Cargo pick up ref')

  let message = cargoMessage
  let ref = order.referenceNumber

  if (isContainer) {
    message = order.containerNumber ? containerMessage : nnOrderMessage
    ref = order.containerNumber ? order.containerNumber : order.referenceNumber
  }

  return (
    <Box>
      {message}: <b>{ref}</b>
    </Box>
  )
})

export const IncompleteJobsWarningMessageBody: FC<{
  orders: OrderResponseDto[]
}> = observer(({ orders }) => {
  const { t } = useTranslate()

  return (
    <>
      {t(
        'truckVisitHasIncompleteJobsWarning',
        'The following orders have incomplete jobs during operations. Check & confirm the following:',
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0',
          gap: '.5rem',
        }}
      >
        {orders.map(order => (
          <IncompleteJobsWarningMessageOrder order={order} key={order.id} />
        ))}
      </Box>
    </>
  )
})

export const ManualGateOutWarningDialog: FC<{
  viewStore: GateInViewStore
}> = observer(({ viewStore }) => {
  const { t } = useTranslate()

  const openOrders = viewStore.selectedVisitWithOrders
    .filter(o => !!o && !o.order?.isJobFinished)
    .map(o => o.order!)

  const investigationOpenOrders = openOrders
    .filter(o => o.unitType === UnitType.Container && o.direction === CarrierVisitDirection.Inbound)
    .map(o => (o.containerNumber ? o.containerNumber : o.referenceNumber))

  const fulfilledOpenOrders = openOrders
    .filter(
      o => o.unitType !== UnitType.Container || o.direction === CarrierVisitDirection.Outbound,
    )
    .map(o => (o.containerNumber ? o.containerNumber : o.referenceNumber))

  const getWarningMessage = () => {
    if (fulfilledOpenOrders.length > 0 && fulfilledOpenOrders.length === openOrders.length) {
      return t(
        'confirmGateOutMarkOrdersAsFulfilled',
        'Confirming gate out will mark these orders as fulfilled',
      )
    }

    if (
      investigationOpenOrders.length > 0 &&
      investigationOpenOrders.length === openOrders.length
    ) {
      return t(
        'confirmGateOutMarkOrdersWithIssues',
        'Confirming gate out will mark these orders with issues',
      )
    }

    if (investigationOpenOrders.length > 0 && fulfilledOpenOrders.length > 0) {
      return `${t(
        'confirmGateOutMarkOrdersWithIssues',
        'Confirming gate out will mark these orders with issues',
      )}:
      ${investigationOpenOrders.join(', ')}
      ${t(
        'confirmGateOutMarkOrdersAsFulfilled',
        'Confirming gate out will mark these orders as fulfilled',
      )}:
      ${fulfilledOpenOrders.join(', ')}`
    }
  }
  return (
    <ConfirmationDialog
      title={t('incompleteJobsForGateOut', 'Incomplete jobs for gate out')}
      warning={getWarningMessage()}
      warningPosition='bottom'
      open={viewStore.isGateOutWithPendenciesConfirmationDialogOpen}
      onClose={() => viewStore.closeGateOutWithPendenciesConfirmationDialog()}
      onConfirm={() => viewStore.gateOut()}
      closeLabel={t('cancel', 'Cancel')}
      primaryActionText={t('confirm', 'Confirm')}
      maxWidth='xs'
    >
      <IncompleteJobsWarningMessageBody orders={openOrders} />
    </ConfirmationDialog>
  )
})
