import { FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import { JobDto, RailcarTrackPositionDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useCallback, useEffect, useState } from 'react'
import { DoorDirectionInfo } from '../JobInformation/DoorDirectionInfo'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { StepperContentWrapper } from './StepperContentWrapper'
import { RailcarAutocompleteProps } from './edit-destination.interface'

interface Props {
  job: JobDto
  railcarTrackPositions: RailcarTrackPositionDto[]
  containerOrigin?: string
  currentWeight: number
  currentStep: number
  stepsTotal: number
  hasError?: boolean
  showDoorDirectIfExists?: boolean
  handleRailcar: (id?: number) => void
  RailcarAutocompleteComponent?: React.ComponentType<RailcarAutocompleteProps>
}

export const ConfirmWagonNumber = ({
  job,
  railcarTrackPositions,
  containerOrigin,
  currentWeight,
  currentStep,
  stepsTotal,
  hasError,
  showDoorDirectIfExists,
  handleRailcar,
  RailcarAutocompleteComponent,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [railcarId, setRailcarId] = useState<number | undefined>()

  const handleChangeRailcar = useCallback(
    (newValue?: number) => {
      setRailcarId(newValue)
      handleRailcar(newValue)
    },
    [handleRailcar],
  )

  useEffect(() => {
    handleChangeRailcar(job.railcarId ?? undefined)
  }, [job.railcarId])

  const getHelperMessageContent = () => {
    if (railcarId) {
      const railCar = railcarTrackPositions.find(x => x.railcarId === railcarId)

      if (!railCar) return

      return (
        <Stack flexDirection='row' gap={1}>
          <Typography variant='body2'>
            {t(
              'wagonAlreadyCarryOnATotalGrossWeightOf',
              'Wagon already carry on a total gross weight of',
            )}{' '}
            {(railCar.totalGrossWeight ?? 0) + currentWeight} kg
          </Typography>
        </Stack>
      )
    }
  }

  return (
    <StepperContentWrapper
      title={t('enterWagon', 'Enter wagon')}
      steps={`${currentStep} ${t('of', 'of')} ${stepsTotal}`}
    >
      <JobInformationJourney job={job} origin={containerOrigin}>
        <FormControl fullWidth error={hasError} sx={{ marginTop: theme.customSpacing.m }}>
          {RailcarAutocompleteComponent && (
            <RailcarAutocompleteComponent
              hasError={hasError ?? false}
              label={t('wagonNumber', 'Wagon no.')}
              onChange={handleChangeRailcar}
              value={railcarId}
            />
          )}

          <FormHelperText component='span'>{getHelperMessageContent()}</FormHelperText>
        </FormControl>
      </JobInformationJourney>

      {showDoorDirectIfExists && <DoorDirectionInfo job={job} />}
    </StepperContentWrapper>
  )
}
