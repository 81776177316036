import { Button, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'

interface IProps {
  onFilter: (filter: string) => void
  defaultSearchFilter?: string
}

export const ContainerMasterDataPageHeader: FC<IProps> = observer(
  ({ onFilter, defaultSearchFilter }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const navigate = useNavigate()

    return (
      <Header
        title={t('containerMasterData', 'Container master data')}
        searchInputLabel={t('searchForContainerOrIsoCode', 'Search for container or ISO code')}
        onSearchFieldChange={onFilter}
        defaultSearchFilter={defaultSearchFilter}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Stack flexDirection='row' gap={theme.customSpacing.xs}>
            <Button
              color='inherit'
              variant='contained'
              sx={{ flex: 'none' }}
              startIcon={<PlusIcon />}
              onClick={() => {
                navigate('/registers/container-master-data/new')
              }}
            >
              {t('addNew', 'Add new')}
            </Button>
          </Stack>
        }
      />
    )
  },
)
