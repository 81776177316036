import { IFormStore, ISubFormStore } from './interfaces'
import { extractSubFormData, validateSubForm } from './subFormHelper'

export class FormStore<T> implements IFormStore<T> {
  private stores: Record<keyof T, ISubFormStore<any>> = {} as Record<keyof T, ISubFormStore<any>>

  register = <U extends T[keyof T]>(name: keyof T, store: ISubFormStore<U>) => {
    this.stores[name] = store
  }

  unregister = (name: keyof T) => {
    delete this.stores[name]
  }

  validate = () => {
    return validateSubForm(this.stores)
  }

  get data() {
    return extractSubFormData(this.stores) as T
  }
}
