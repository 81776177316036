import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PATHS } from '@host/app/paths'
import { Grid } from '@mui/material'
import { CraneSplitSummary, EquipmentPlanningSummary, useOperationsStore } from '@tom-ui/operations'
import {
  BerthPlanningCard,
  IssueDashboardCard,
  OrderMovementEditableCard,
  VisitBreak,
  vesselVisitDetailPages,
} from '@tom-ui/planning'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { IssueStatus } from 'modules/operations/src/app/api'
import { CarrierType } from 'modules/planning/src/app/api'
import { FeatureFlag } from 'modules/utils/src/components/FeatureFlags'
import { FC } from 'react'
import { CarrierVisitDirection } from './VesselVisitDetails'

export const VesselVisitDashboard: FC<{
  visitId: number
  vesselVisitItem: any
  handlePageChange: (page: string) => void
}> = withAuthenticationRequired(
  observer(({ vesselVisitItem, handlePageChange, visitId }) => {
    const theme = useMinimalsTheme()
    const { tenantConfigStore: operationsTenantConfigStore, issueStore } = useOperationsStore()

    if (!vesselVisitItem) return <></>

    return (
      <Grid
        sx={{ paddingLeft: theme.spacing(3) }}
        container
        justifyContent='flex-start'
        spacing={{ xs: 1, md: 3 }}
      >
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            direction={CarrierVisitDirection.Inbound}
            onViewClick={() => handlePageChange(vesselVisitDetailPages.discharge)}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            direction={CarrierVisitDirection.Outbound}
            onViewClick={() => handlePageChange(vesselVisitDetailPages.load)}
          />
        </Grid>

        <FeatureFlag name='enable-restows'>
          <Grid item sm={12} md={6} lg={4}>
            <OrderMovementEditableCard
              direction={CarrierVisitDirection.Outbound}
              visitType={CarrierType.Vessel}
              vesselVisitItem={vesselVisitItem}
              isRestow
              onViewClick={() => handlePageChange(vesselVisitDetailPages.restow)}
            />
          </Grid>
        </FeatureFlag>

        <Grid item sm={12} md={6} lg={4}>
          <IssueDashboardCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            unresolvedOperationsIssueCount={
              issueStore.items.filter(
                i =>
                  i.carrierVisitIds.includes(visitId) && i.issueStatus === IssueStatus.Unresolved,
              ).length
            }
            onViewClick={() => handlePageChange(`${PATHS.issues}`)}
          />
        </Grid>

        <FeatureFlag name='visit-break-info-display'>
          <Grid item sm={12} md={6} lg={4}>
            <VisitBreak vesselVisitItem={vesselVisitItem} />
          </Grid>
        </FeatureFlag>

        <FeatureFlag name='vessel-visit-berth-planning'>
          <Grid item sm={12} md={6} lg={4}>
            <BerthPlanningCard vesselVisitItem={vesselVisitItem} />
          </Grid>
        </FeatureFlag>

        {!operationsTenantConfigStore.skipCraneSplit && (
          <>
            <Grid item sm={12} md={4} lg={4}>
              <CraneSplitSummary />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <EquipmentPlanningSummary
                navigateToUrl={`/EquipmentPlanning/${vesselVisitItem?.id}/equipments`}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  }),
)
