import { Button, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ContainerMasterDataListViewStore } from '../../stores/ContainerMasterDataListViewStore'
import { ContainerMasterDataCard } from './ContainerMasterDataCard'

interface IProps {
  viewStore: ContainerMasterDataListViewStore
}

const ContainerMasterDataListEmptyMessage: FC = observer(() => {
  const { t } = useTranslate()

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'70vh'}>
      <Box textAlign={'center'}>
        <Typography variant='subtitle1' mb={'1rem'}>
          {t('noResultFound', 'No result found')}
        </Typography>
        <Typography variant='body1'>{t('', ' Try adjusting your search')}</Typography>
        <Typography variant='body1' mb={'1rem'}>
          {t('orCreateNewContainer', 'or create a new container.')}
        </Typography>
      </Box>
    </Box>
  )
})

export const ContainerMasterDataList: FC<IProps> = observer(({ viewStore }) => {
  const theme = useMinimalsTheme()
  const navigate = useNavigate()
  const { t } = useTranslate()

  const isNoMatches = viewStore.filter && viewStore.data.length === 0

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      {isNoMatches && <ContainerMasterDataListEmptyMessage />}
      {!isNoMatches && (
        <>
          <Box textAlign={'right'} mb={theme.customSpacing.xs}>
            <Typography variant='subtitle1' m={theme.customSpacing.xxs}>
              {viewStore.totalCount} {t('containersFound', 'containers found')}
            </Typography>
          </Box>
          <Box mt={theme.customSpacing.xs}>
            {viewStore.data.map(item => (
              <ContainerMasterDataCard
                key={item.id}
                {...item}
                filter={viewStore.filter}
                onClickEdit={() => {
                  navigate(`/registers/container-master-data/${item.id}`)
                }}
              />
            ))}
          </Box>
          {viewStore.hasNextPage && (
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              mt={theme.customSpacing.xs}
            >
              <Button onClick={() => viewStore.showMore()}>{t('showMore', 'Show more')}</Button>
            </Box>
          )}
        </>
      )}
    </Container>
  )
})
