import { Box, DialogActions } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { UpsertOrderListCommandV2, UpsertRestowListCommand } from '@planning/app/api'
import { LoaderButton } from '@planning/components/LoaderButton'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { carrierVisitService } from '@planning/services'
import restowService from '@planning/services/restowService'
import { useTranslate } from '@tolgee/react'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { UseFormReset } from 'react-hook-form'

export interface IProps {
  store: OrderListUploadViewStoreV2
  resetForm: UseFormReset<any>
  isRestow?: boolean
}

const handleRestowUnitNotFoundError = (error: any): string | null => {
  const errorType: string = error.response?.data?.title

  if (errorType.includes('The specified resource was not found')) {
    const detailMessage: string = error.response?.data?.detail
    const match = detailMessage?.match(/\(([^)]{1,12})\) was not found/)

    return match ? match[0] : null
  }
  return null
}

export const OrderListUploadDialogFooter: FC<IProps> = observer(
  ({ store, isRestow, resetForm }) => {
    const { appViewStore, orderListUploadViewStoreV2, drawerStore } = usePlanningStore()

    const { t } = useTranslate()

    const validateInput = (): boolean => {
      if (!store.orderUpdates) return false

      if (!store.vesselVisitId && !store.railVisitId) {
        appViewStore.setShowAlert('error', t('missingVisitId', 'Missing visit id'))
        return false
      }

      if (!store.customer) {
        appViewStore.setShowAlert('error', t('missingCustomer', 'Missing customer'))
        return false
      }

      return true
    }

    const upsertRestowList = async () => {
      try {
        appViewStore.isLoading = true

        const restowCmd: UpsertRestowListCommand = {
          vesselVisitId: store.vesselVisitId ?? 0,
          customerId: store.customer?.id ?? 0,
          restows: store.dataStore.restowUnits ?? [],
        }

        await restowService.upsertRestowList(restowCmd)

        appViewStore.setShowAlert(
          'success',
          t('fileUploadedSuccessfully', 'File uploaded successfully'),
        )
        finalizeProcess()
      } catch (error) {
        if (isRestow && error instanceof AxiosError) {
          const unitMessage = handleRestowUnitNotFoundError(error)

          if (unitMessage) {
            orderListUploadViewStoreV2.setActiveStep(0)
            orderListUploadViewStoreV2.setUploadFailedReason(
              `${t('notRemainOnBoardEntry', `Unit cannot be restowed: ${unitMessage}`)}`,
            )
            return
          } else {
            appViewStore.setShowAlert('error', t('failToUploadFile', 'Fail to upload file'))
          }
        } else {
          appViewStore.setShowAlert('error', t('failToUploadFile', 'Fail to upload file'))
        }
      } finally {
        appViewStore.isLoading = false
      }
    }

    const upsertOrderList = async () => {
      try {
        appViewStore.isLoading = true

        const cmd: UpsertOrderListCommandV2 = {
          listName: store.listName,
          carrierVisitId: store.vesselVisitId ?? store.railVisitId ?? 0,
          direction: store.direction,
          customerId: store.customer?.id ?? 0,
          addOrders: store.orderUpdates?.created.filter(
            o => store.acceptUpdates[o.containerNumber],
          ),
          addEmptyRailcars: store.orderUpdates?.created.filter(
            o => !store.acceptUpdates[o.containerNumber],
          ),
          addRemainOnBoard: store.dataStore.remainOnBoard,
          addNonNumeric: store.dataStore.nonNumeric,
          updateOrders: store.orderUpdates?.amended
            .filter(o => store.acceptUpdates[o.containerNumber ?? ''])
            .map(amendment => amendment.update),
          deleteOrders: store.orderUpdates?.deleted
            .filter(o => store.acceptUpdates[o.containerNumber ?? ''])
            .map(o => o.id),
        }

        await carrierVisitService.upsertOrderListV2(cmd)

        appViewStore.setShowAlert(
          'success',
          t('fileUploadedSuccessfully', 'File uploaded successfully'),
        )
        finalizeProcess()
      } catch (error) {
        console.log(error)
        appViewStore.setShowAlert('error', t('failToUploadFile', 'Fail to upload file'))
      } finally {
        appViewStore.isLoading = false
      }
    }

    const finalizeProcess = () => {
      store.reset()
      resetForm()
      drawerStore.close()
    }

    const upsertOrderOrRestowList = async () => {
      if (!validateInput()) return

      if (isRestow) {
        await upsertRestowList()
      } else {
        await upsertOrderList()
      }
    }

    const actions = () => {
      if (store.activeStep === 1)
        return (
          <Box data-cy='upload-documents-confirm-updates-btn'>
            <LoaderButton
              onClick={async () => await upsertOrderOrRestowList()}
              color='primary'
              variant='contained'
            >
              {t('confirmUpdates', 'Confirm updates')}
            </LoaderButton>
          </Box>
        )

      return (
        <Box data-cy='upload-documents-next-btn'>
          <LoaderButton color='primary' variant='contained' type='submit'>
            {t('next', 'Next')}
          </LoaderButton>
        </Box>
      )
    }

    return (
      <DialogActions
        sx={{
          width: '100%',
          p: '1rem 2rem!important',
          borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
          {actions()}
        </Box>
      </DialogActions>
    )
  },
)
